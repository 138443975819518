import axios from 'axios';
import bcrypt from 'bcryptjs';
const API_URL = process.env.REACT_APP_ENV !== 'dev' ? 'https://server.tourmehomes.com/api/' : 'http://localhost:3001/api/';
// const API_URL = 'https://server.tourmehomes.com/api/'
const instance = axios.create({
	withCredentials: true,
});
//TOUR
const getTourView = (id) => {
	return instance.get(API_URL + `client/find/${id}`).then(res => {
		return res.data;
	})
		.catch(err => {
			throw err;
		});
};
const getSectionTranslation = (id, voice, userId) => {
	return instance.get(API_URL + `client/audio/${userId}/${id}/${voice}`).then(res => {
		return res;
	})
		.catch(err => {
			throw err;
		});
};
const getLanguageList = (gender, language, id) => {
	return instance.get(API_URL + 'voice/language/list', {params:{gender, language, id}}).then(res => {
		return res.data;
	})
		.catch(err => {
			throw err;
		});
};
const countShare = (id) => {
	return instance.post(API_URL + 'client/share', { tourId: id }).then(res => {
		return res;
	})
		.catch(err => {
			throw err;
		});
};
const countPlay = (id, data) => {
	return instance.post(API_URL + 'client/play', { tourId: id, ...data }).then(res => {
		return res;
	})
		.catch(err => {
			throw err;
		});
};
const rateTour = (id, value) => {
	return instance.post(API_URL + 'client/rate', { tourId: id, value }).then(res => {
		return res;
	})
		.catch(err => {
			throw err;
		});
};
const checkTourActive = (id) => {
	return instance.get(API_URL + `client/check/${id}`).then(res => {
		return res.data;
	})
		.catch(err => {
			throw err;
		});
};
const ClientService = {
	getTourView,
	countShare,
	countPlay,
	getLanguageList,
	rateTour,
	getSectionTranslation,
	checkTourActive,
};
export default ClientService;

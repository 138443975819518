import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import UserService from './../service/user.service';
const { getSectionList, getSectionAudio } = UserService;
import { useSelector } from 'react-redux';
import { TourDataContext } from '../context/TourDataContext';
import WaveSurfer from 'wavesurfer.js';
import { toast } from 'sonner';

export const useAudio = () => {
	const { singleTour } = useContext(TourDataContext);
	const user = useSelector((state) => state.user);

	const [audioSections, setAudioSections] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [isAudioReady, setIsAudioReady] = useState(false);
	const [currentSection, setCurrentSection] = useState(0);
	const [progress, setProgress] = useState(0);
	const [isPlayingAudio, setIsPlayingAudio] = useState({ id: undefined, state: false, play: false });
	const [fullTime, setTime] = useState(null);
	const [process, setProcess] = useState(0);
	const showSectionOrder =
		audioSections[currentSection]?.order < 10
			? `Section 0${audioSections[currentSection]?.order}`
			: `Section ${audioSections[currentSection]?.order}`;
	const currentIndex = audioSections.findIndex((section) => section.id === audioSections[currentSection]?.id);

	const containerRef = useRef();
	const textRef = useRef();
	const waveSurferRef = useRef();

	const getAudioSections = useCallback(() => {
		if (user?.userData?.voiceId) {
			setIsLoading(true);
			getSectionList(singleTour.randomId, user?.userData?.voiceId)
				.then(async (response) => {
					for (let s of response.data.section) {
						if (s.Audios.length === 0) {
							let resp = await getSectionAudio(s.id, user?.userData?.voiceId);
							s.Audios.push({ src: resp.data.src });
						}
					}
					setAudioSections(response?.data?.section);
					setCurrentSection(0);
					setIsPlayingAudio({
						id: response?.data?.section[0]?.id,
						state: waveSurferRef.current?.isPlaying(),
						play: false,
					});
				})
				.catch((error) => {
					setIsLoading(false);
					setError(error);
				})
				.finally(() => setIsLoading(false));
		}
	}, [setAudioSections, isLoading, singleTour, user]);

	useEffect(() => {
		if (user?.userData?.voiceId && singleTour.randomId) getAudioSections();

	}, [user, singleTour]);

	const skipBack = (id) => {
		waveSurferRef.current?.isPlaying() && waveSurferRef.current?.pause();
		setCurrentSection(currentIndex === 0 ? audioSections.length - 1 : currentIndex - 1);
		setIsPlayingAudio({ id, state: waveSurferRef.current?.isPlaying() });
	};

	const skipForward = () => {
		waveSurferRef.current?.isPlaying() && waveSurferRef.current?.pause();
		setCurrentSection(currentIndex === audioSections.length - 1 ? 0 : currentIndex + 1);
		setIsPlayingAudio({ id: currentSection, state: waveSurferRef.current?.isPlaying() });
	};

	const handlePlayPause = (id, play = false) => {
		if (!play || id === isPlayingAudio.id) waveSurferRef.current?.playPause();
		setIsPlayingAudio({ id, state: waveSurferRef.current?.isPlaying(), play });
	};

	const handlePlayPauseInSmallPlayer = (order, id) => {
		handlePlayPause(id, true);
		setCurrentSection(order);
	};
	const skip15 = (direction) => {
		if (waveSurferRef.current) {
			let time = waveSurferRef.current.getCurrentTime();
			let maxTime = waveSurferRef.current.getDuration();
			let newTime = direction === 'forward' ? time + 15 : time - 15;
			newTime = newTime > maxTime ? maxTime : newTime < 0 ? 0 : newTime;
			waveSurferRef.current.seekTo(newTime / maxTime);
			setProcess(newTime);
			setProgress(Math.round((newTime / fullTime) * 100));
		}
	};
	const handleClickTime = (e) => {
		if (audioSections[currentSection]?.Audios[0].src) {
			const { left, width } = e.currentTarget.getBoundingClientRect();
			const clickX = e.clientX - left;
			const duration = waveSurferRef.current.getDuration();
			const time = (clickX / width) * duration;
			waveSurferRef.current.seekTo(time / duration);
			setProgress(Math.round((time / fullTime) * 100));
			setProcess(time);
		}
	};
	useEffect(() => {
		if (
			!isLoading &&
			audioSections.length > 0 &&
			audioSections[currentSection]?.Audios[0]?.src &&
			containerRef.current
		) {
			console.log('AUDIO LOADING');
			waveSurferRef.current?.destroy();
			const waveSurfer = WaveSurfer.create({
				container: containerRef.current,
				backend: 'MediaElement',
				waveColor: 'transparent',
				progressColor: 'transparent',
				cursorWidth: 0,
				barWidth: 0,
				barMinHeight: 2,
				barHeight: 0,
				height: 0,
				responsive: true,
				skipLength: 15,
				barGap: 0,
				normalize: false,
			});
			waveSurfer.load(audioSections[currentSection]?.Audios[0].src);
			waveSurfer.on('ready', () => {
				setProgress(0);
				console.log('AUDIO READY');
				waveSurferRef.current = waveSurfer;
				setTime(waveSurfer.getDuration());
				setIsAudioReady(true);
				if (isPlayingAudio.play) {
					waveSurfer.play();
					setIsPlayingAudio((prev) => {
						return { ...prev, state: waveSurferRef.current?.isPlaying(), play: false };
					});
				}
			});
			waveSurfer.on('audioprocess', function () {
				if (waveSurfer.isPlaying()) {
					setProcess(waveSurfer.getCurrentTime());
					//let currentTime = waveSurfer.getCurrentTime();
					//setProcess(currentTime);
					let prg = Math.round((waveSurfer.getCurrentTime() / waveSurfer.getDuration()) * 100);
					setProgress(prg);
					if (textRef.current) {
						let textWidth = textRef.current.offsetWidth;
						let move = (prg / 100) * textWidth;
						const containerWidth = containerRef.current.offsetWidth;
						textRef.current.style.marginLeft = -move + containerWidth / 2 + 'px';
					}
				}
			});
			waveSurfer.on('finish', () => {
				setIsPlayingAudio((prev) => {
					return { ...prev, state: waveSurferRef.current?.isPlaying(), play: false };
				});
				currentIndex < audioSections.length - 1 && skipForward();
			});
			return () => {
				waveSurfer.destroy();
				//setIsAudioReady(false);
			};
		}
	}, [currentSection, audioSections, isLoading]);

	const handleDelete = (id) => {
		let prom = UserService.deleteSection(id);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (res) => {
				let activeSection = audioSections[currentSection]?.id;
				setAudioSections(audioSections.filter((s) => s.id !== id));
				if(activeSection === id) setCurrentSection(currentSection === 0 ? 0 : currentSection - 1);
				return res.data.message;
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	const handleEditSection = (id, title, speech, duration, order) => {
		setIsLoading(true);
		let prom = UserService.updateSection({
			id,
			title,
			speech,
			duration: Math.round(duration * 100) / 100,
			order,
			voiceId: user.userData.voiceId,
		});
		toast.promise(prom, {
			loading: 'Loading...',
			success: (res) => {
				setAudioSections(
					audioSections.map((s) =>{
						if(s.id === id)
						{
							let result ={
								...s,
								title: title,
								speech: speech,
								duration: Math.round(duration * 100) / 100,
							};
							if(res.data.data.audio) result.Audios= [{ src: res.data.data.audio }];
							return result;
						}
						else return s;
					}),
				);
				setIsLoading(false);
				return res.data.message;
			},
			error: (err) => {
				console.error(err);
				setIsLoading(false);
				return 'Error updating section';
			},
		});
	};
	const handleAddSection = (_, title, speech, duration, order) => {
		let prom = UserService.createSection({
			title,
			speech,
			duration,
			order,
			id: singleTour.id,
			voiceId: user.userData.voiceId,
		});
		toast.promise(prom, {
			loading: 'Loading...',
			success: (res) => {
				setAudioSections([...audioSections, res.data.data]);
				return res.data.message;
			},
			error: (err) => {
				console.error(err);
				return 'Error creating section';
			},
		});
	};

	const handleOrder = (list) => {
		let body = list.map((s) => {
			return { id: s.id, order: s.order };
		});
		let prom = UserService.updateSectionsOrder(body);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				setAudioSections(list);
				return 'Sections order updated';
			},
			error: (err) => {
				console.error(err);
				setAudioSections(audioSections);
				return err.response.data.message;
			},
		});
	};
	const cleanAudio = () => {
		setAudioSections([]);
		setProgress(0);
		setIsPlayingAudio({ id: undefined, state: false, play: false });
		setProcess(0);
		setIsLoading(true);
	};
	return {
		cleanAudio,
		audioSections,
		setAudioSections,
		isLoading,
		setIsLoading,
		error,
		currentSection,
		skipBack,
		skipForward,
		handlePlayPauseInSmallPlayer,
		handlePlayPause,
		handleClickTime,
		isAudioReady,
		skip15,
		process,
		progress,
		showSectionOrder,
		fullTime,
		textRef,
		containerRef,
		waveSurferRef,
		handleDelete,
		handleOrder,
		handleEditSection,
		handleAddSection,
		isPlayingAudio,
	};
};

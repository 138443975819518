import React, { useEffect, useState } from 'react';
import PriceCard from '../../components/Cards/PriceCard/PriceCard';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from './Checkout.module.scss';
import { toast } from 'sonner';
import InvitationButton from '../../components/InvitationButton/InvitationButton';
import { setUser } from '../../store/actions/userAction';
import UserService from '../../service/user.service';
import AuthService from '../../service/auth.service';

export default function Pricing(props) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	const [cards, setCards] = useState(null);
	const [freeCard, setFreeCard] = useState({
		title: '',
		price: 0,
		billing: '',
		plan: '',
		features: [],
	});
	const [active, setActive] = useState(-1);
	const [state, setState] = useState('Monthly');

	const handleChange = (event, period) => {
		setState(event.target.checked && period);
	};
	const logOut = () => {
		AuthService.logout()
			.then((res) => {
				dispatch(setUser(null));
				navigate('/login');
			})
			.catch((err) => {
				console.error(err);
			});
	};
	useEffect(() => {
		UserService.getPriceList()
			.then((res) => {
				setFreeCard(res.data.filter((c) => c.billing === 'Free')[0]);
				setCards(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	return (
		<section className={style.checkout}>
			<Container maxWidth="xlg">
				<Box py={5} textAlign="center">
					<Box mb={3} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
						<h2 className={style.checkout__header}>
							Pricing and <span>Plans</span>.
						</h2>
						<p>Start your 14-day trial, <span className={style.checkout__subtitle}>no credit card necessary.</span></p>
						<div className={style.checkout__switch}>
							<label
								className={`${style.checkout__switch__label} ${state === 'Monthly' && style.active}`}
								htmlFor="month"
							>
								Monthly
							</label>
							<label
								className={`${style.checkout__switch__label} ${state === 'Yearly' && style.active}`}
								htmlFor="year"
							>
								Yearly
								<div className={style.checkout__switch__label__discount}>
									<span>-15%</span>
								</div>
							</label>
							<input
								type="radio"
								name="paymentPeriod"
								id="month"
								checked={state === 'Monthly'}
								onChange={(e) => handleChange(e, 'Monthly')}
							/>
							<input
								type="radio"
								name="paymentPeriod"
								id="year"
								checked={state === 'Yearly'}
								onChange={(e) => handleChange(e, 'Yearly')}
							/>
						</div>
						<InvitationButton />
					</Box>
					<Grid container spacing={1}>
						{
							!cards ? (
								<>
									<Grid item xs={12} lg={3} md={6}>
										<Skeleton variant="rectangular" height="28.125rem" sx={{ borderRadius: '10px', width: '100%',}} />
									</Grid>
									<Grid item xs={12} lg={3} md={6}>
										<Skeleton variant="rectangular" height="28.125rem" sx={{ borderRadius: '10px', width: '100%'}} />
									</Grid>
									<Grid item xs={12} lg={3} md={6}>
										<Skeleton variant="rectangular" height="28.125rem" sx={{ borderRadius: '10px', width: '100%'}} />
									</Grid>
									<Grid item xs={12} lg={3} md={6}>
										<Skeleton variant="rectangular" height="28.125rem" sx={{ borderRadius: '10px', width: '100%'}} />
									</Grid>
								</>
							)
								:
								<>
									<Grid item xs={12} lg={3} md={6}>
										<PriceCard
											handleClick={() => setActive(-1)}
											active={active === -1}
											title={freeCard.title}
											price="0"
											period={`/${state}`}
											plan={freeCard.plan}
											features={freeCard.features}
											multi_agent={freeCard.multi_agent}
											description={freeCard.description}
										></PriceCard>
									</Grid>
									{cards
										.filter((c) => c.billing === state)
										.map((card, index) => (
											<Grid item xs={12}  lg={3} md={6} key={index}>
												<PriceCard
													handleClick={() => setActive(index)}
													active={active === index}
													title={card.title}
													price={card.price}
													period={`/${card.billing}`}
													plan={card.plan}
													features={card.features}
													multi_agent={card.multi_agent}
													description={card.description}
												></PriceCard>
											</Grid>
										))}
								</>
						}
						
					</Grid>
				</Box>
			</Container>
		</section>
	);
}

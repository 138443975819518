import style from '../EditTour.module.scss';

const EditMenu = ({ steps, activeStep }) => {
	return (
		<div className={`${style.editModal} ${activeStep === 'menu' ? style.editModal_menu_active : style.editModal_menu_inactive}`}>
			<h3 className={`${style.editModal_title} ${style.editModal_menu_title}`}>Edit Tour</h3>
			<p className={style.editModal_description}>Customize your tour and impress your clients!</p>
			<ul className={style.editModal_list}>
				{
					steps.map((step, index) => (
						<li className={`${style.editModal_list_header} ${step.short_label === activeStep && style.editModal_list_header_active}`} key={index}>
							<button className={style.editModal_list_header_button} onClick={step.next}>
								<img className={style.editModal_list_header_button_image} src={step.icon} alt="" />
								<p className={style.editModal_list_header_button_text}>{step.short_label}</p>
							</button>
						</li>
					))
				}
				{
					steps.map((step, index) => (
						<li className={style.editModal_list_item} key={index}>
							<button className={style.editModal_list_item_button} onClick={step.next}>
								<img className={style.editModal_list_item_button_image} src={step.icon} alt="" />
								<p className={style.editModal_list_item_button_text}>{step.label}</p>
							</button>
						</li>
					))
				}
			</ul>
		</div>
	);
};

export default EditMenu;
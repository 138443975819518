import Button from '../../Button/Button';
const ActionButtons = ({ handleSave, handleCancel, disabled, marginTop=42, actionText='Save' }) => {
	const styleContainer = {
		marginTop: `${marginTop}px`,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		gap: '.5rem',
	};
	return (
		<div style={styleContainer}>
			<Button style="text" onClick={handleCancel}></Button>
			<Button
				disabled={disabled}
				onClick={handleSave}
				style="filled"
				color="secondary"
			>{actionText}</Button>
		</div>
	);
};

export default ActionButtons;

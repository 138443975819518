import React, { useCallback, useEffect, useState } from 'react';
import FormWrapperLayout from '../../FormWrapperLayout/FormWrapperLayout';
import { useCreateForm } from '../../../../context/CreateTourContext';
import { useNavigate } from 'react-router-dom';
import InputImage from '../../../InputImage/InputImage';
import { schema, validateData } from '../../../../helpers/useSchema';

const AddPhotoFiles = () => {
	const [isGalleryReady, setIsGalleryReady] = useState([]);
	const navigate = useNavigate();
	const { formData, setFormValues } = useCreateForm();

	const onSubmit = () => {
		setFormValues({ ...formData, tourImages: isGalleryReady });
		navigate('/create/sections');
	};

	useEffect(() => {
		if (formData.tourImages.length > 0) {
			setIsGalleryReady(formData.tourImages);
		}
	}, [formData.tourImages]);

	useEffect(() => {
		validateData(schema.propertyAddress, formData)
			.then((res) => {
				if(!res.success) return navigate('/create/propertyAddress');
				validateData(schema.propertyInformation, formData)
					.then((res) => {
						if(!res.success) return navigate('/create/propertyInfo');
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	}, [formData]);

	return (
		<>
			<FormWrapperLayout formTitle="Add Photos" next={onSubmit} back={() => navigate('/create/propertyInfo')}>
				<InputImage images={isGalleryReady} setImages={setIsGalleryReady}></InputImage>
			</FormWrapperLayout>
		</>
	);
};

export default AddPhotoFiles;

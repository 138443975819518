import { createContext, useState } from 'react';
import UserService from '../service/user.service';
import { useSelector } from 'react-redux';

const { getTourList, getSampleTour, getTourData, getClientPortal } = UserService;

export const TourDataContext = createContext(null);
const { Provider } = TourDataContext;

export const useTourData = () => {
	const [warning, setWarning] = useState({ display: false, message: '', button: '', callback: () => { } });
	const [closed, setClosed] = useState(false);
	const user = useSelector((state) => state.user);
	const [sampleTour, setSampleTour] = useState({});
	const [tourCards, setTourCards] = useState([]);
	const [singleTour, setSingleTour] = useState({
		adStreet: '',
		amountView: [],
		shares: [],
		baths: 0,
		beds: 0,
		city: '',
		createdDate: '',
		feets: 0,
		id: 0,
		mlsCode: 0,
		price: '',
		randomId: '',
		ranking: '',
		state: '',
		apartment: '',
		yearBuilt: '',
		zipCode: '',
		status: '',
		tourImages: [],
		rate: [],
	});
	const [clientTour, setClientTour] = useState({});
	const [loadingTour, setLoadingTour] = useState(true);
	const [loadingCards, setLoadingCards] = useState(true); // [1

	const setSingleTourInfo = (tourId, voiceId) => {
		setLoadingTour(true);
		console.log(tourId, voiceId);
		getTourData(tourId, voiceId)
			.then((response) => {
				const {
					adStreet,
					plays,
					shares,
					baths,
					beds,
					city,
					createdDate,
					feets,
					id,
					mlsCode,
					price,
					randomId,
					ranking,
					state,
					apartment,
					yearBuilt,
					zipCode,
					status,
					tourImages,
					Qualifications,
				} = response;
				console.log({response});
				setSingleTour((prevState) => ({
					...singleTour,
					adStreet,
					amountView: plays.length,
					shares: shares.length,
					baths,
					beds,
					city,
					createdDate,
					feets,
					id,
					mlsCode,
					price,
					randomId,
					ranking,
					state,
					apartment,
					yearBuilt,
					zipCode,
					status,
					tourImages,
					rate: Math.round((Qualifications.reduce((a, b) => a + b.value, 0) ?? 0) / Qualifications.length),
				}));
				setLoadingTour(false);
			})
			.catch((error) => {
				setLoadingTour(false);
				console.error(error);
			});
		return { singleTour };
	};
	const setTourState = (newState) => {
		setSingleTour((prevState) => ({
			...singleTour,
			status: newState,
		}));
		return { singleTour };
	};
	const loadToursList = () => {
		setTourCards([]);
		setLoadingCards(true);
		user?.auth &&
			getTourList()
				.then((tourList) => {
					tourList.data.sort(function (a, b) {
						let dateA = new Date(a.createdDate);
						let dateB = new Date(b.createdDate);
						return dateB - dateA;
					});
					setTourCards(tourList.data);
					window.scrollTo({
						top: 0,
						behavior: 'smooth'
					});
					if (user.plan.permissions.tours <= tourList.length && !closed && user.plan.permissions.tours !== -1) {
						displayWarning(
							'You have reached the limit of tours for your plan. Please ',
							'upgrade your plan to create more tours.',
							() => {
								getClientPortal()
									.then((res) => {
										window.location.href = res.url;
									})
									.catch((err) => {
										console.error(err);
									});
							},
						);
					}
				})
				.then(() => setLoadingCards(false))
				.catch((error) => console.error(error));
		user?.auth &&
			getSampleTour()
				.then((sample) => setSampleTour(sample))
				.catch((error) => console.error( error));
	};
	const setSampleTourInfo = () => {
		setLoadingTour(true);
		setSingleTour(sampleTour);
		setLoadingTour(false);
		return { singleTour };
	};

	const displayWarning = (message, button, callBack) => {
		setWarning({ display: true, message, button, callback: callBack });
	};

	const removeWarning = () => {
		setWarning({ display: false, message: '', button: '', callback: () => { } });
		setClosed(true);
	};


	return {
		setLoadingCards,
		loadingCards,
		setSampleTourInfo,
		clientTour,
		setClientTour,
		loadToursList,
		removeWarning,
		warning,
		setTourState,
		sampleTour,
		setSampleTour,
		tourCards,
		setTourCards,
		setSingleTourInfo,
		singleTour,
		loadingTour,
	};
};

const TourDataProvider = ({ children }) => {
	const {
		setLoadingCards,
		loadingCards,
		clientTour,
		setSampleTourInfo,
		setLoadingSections,
		setClientTour,
		loadToursList,
		removeWarning,
		warning,
		setTourState,
		tourCards,
		setTourCards,
		sampleTour,
		setSampleTour,
		setSingleTourInfo,
		singleTour,
		loadingTour,
	} = useTourData();
	return (
		<Provider
			value={{
				setLoadingCards,
				loadingCards,
				setSampleTourInfo,
				setLoadingSections,
				clientTour,
				setClientTour,
				loadToursList,
				removeWarning,
				warning,
				setTourState,
				tourCards,
				setTourCards,
				sampleTour,
				setSampleTour,
				setSingleTourInfo,
				singleTour,
				loadingTour,
			}}
		>
			{children}
		</Provider>
	);
};

export default TourDataProvider;

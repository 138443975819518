import React, { useContext } from 'react';
import styles from './WhatsNext.module.scss';
import { TourDataContext } from '../../../../context/TourDataContext';
import { useNavigate } from 'react-router-dom';
import Button from '../../../Button/Button';
const WhatsNext = () => {
	const { singleTour } = useContext(TourDataContext);
	const navigate = useNavigate();
	
	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.headlineWrapper}>
					<h1>Your tour is ready, now it’s time to share it.</h1>
					<div className={styles.headlineWrapper_button}>
						<Button style="filled" color="primary" onClick={() => navigate(`/tourhome/${singleTour.randomId}`)}>Go to Tour</Button>
					</div>
				</div>
				<div className={styles.stepDetailsWrapper}>
					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>01.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Share Link</h3>
							<p className={styles.stepDetailsWrapper__description}>
								Send link via email for people to have direct access to your tour.
							</p>
						</div>
					</div>

					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>02.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Download QR Code</h3>
							<p className={styles.stepDetailsWrapper__description}>
								Download a QR for people to scan and access your tour.
							</p>
						</div>
					</div>

					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>03.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Print Flyer</h3>
							<p className={styles.stepDetailsWrapper__description}>
								Print a flyer with the tour information to display at the property for visitors to see.
							</p>
						</div>
					</div>
				</div>
				<div className={styles.buttonWrapper}>
					<Button style="filled" color="primary" onClick={() => navigate(`/tourhome/${singleTour.randomId}`)}>Go to Tour</Button>
				</div>
			</div>
		</>
	);
};

export default WhatsNext;

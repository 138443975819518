import { useContext, useEffect, useState } from 'react';
import { TourDataContext } from '../../context/TourDataContext';
import TabButtons from '../../components/TabButtons/TabButtons';
import TourDetails from '../../components/Cards/TourDetails/TourDetails';
import style from './TourHome.module.scss';
import { useParams, useNavigate } from 'react-router-dom';
import Player from '../../components/Player/Player';
import SectionList from '../../components/SectionList/SectionList';
import { useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import LoadingPage from '../../components/LoadingPage/LoadingPage';

const TourHome = ({ sample = false }) => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const { tourId } = useParams();
	const [tab, setTab] = useState('details');
	const {
		loadingTour,
		setSingleTourInfo,
		setSampleTourInfo,
		singleTour,
	} = useContext(TourDataContext);

	const goHome = () => {
		navigate('/dashboard');
	};
	useEffect(() => {
		if (sample) setSampleTourInfo();
		else {
			user && setSingleTourInfo(tourId);
		}
	}, [user]);

	return (
		<div className={style.tourhome}>
			{loadingTour? <LoadingPage/>
				:
				<>
					<div className={style.tourhome_header}>
						<TabButtons tab={tab} setTab={setTab}></TabButtons>
					</div>
					<div className={style.tourhome_phone}>
						{tab === 'details' && (
							<div className={style.tourhome_details}>
								<TourDetails singleTour={singleTour} setSingleTourInfo={setSingleTourInfo} stateChip={true} edit={true}  share={true}></TourDetails>
							</div>
						)}
						{
							tab === 'tour' && (
								<div className={style.tourhome_tour}>
									<Player></Player>
									<SectionList></SectionList>
								</div>
							)
						}
					</div>
					<div className={style.tourhome_pc}>
						<div className={style.tourhome_details}>
							<h2 className={`${style.tourhome_title} ${style.tourhome_title_button}`}>
								<div className={style.tourhome_title_back}>
									<Button size='sm' style="text" iconName='left_line_arrow' onClick={goHome}></Button>
								</div>Property Details
							</h2>
							<TourDetails singleTour={singleTour} setSingleTourInfo={setSingleTourInfo} stateChip={true} edit={true} share={true}></TourDetails>
						</div>
						<div className={style.tourhome_tour}>
							<h2 className={style.tourhome_title}>Your Tour</h2>
							<Player></Player>
							<SectionList></SectionList>
						</div>
					</div>
				</>
			}
		</div>
	);
};

export default TourHome;

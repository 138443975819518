import { useContext, useEffect } from 'react';
import { AudioContext } from '../../context/AudioContext';
import IconButton from '@mui/material/IconButton';
import style from './Player.module.scss';
import { Divider, Skeleton, Typography } from '@mui/material';
import { convertTime } from '../../helpers/audioFormatter';
import {ReactComponent as PlayerPrev} from '../../assets/icons/player-prev.svg';
import {ReactComponent as PlayerSkip} from '../../assets/icons/player-skip.svg';
import {ReactComponent as PlayerNext} from '../../assets/icons/player-next.svg';
import {ReactComponent as PlayerBack} from '../../assets/icons/player-back.svg';
import {ReactComponent as Logo} from '../../assets/images/logo.svg';

const Player = () => {
	const {
		audioSections,
		isLoading,
		currentSection,
		skipBack,
		skipForward,
		handlePlayPause,
		handleClickTime,
		isAudioReady,
		process,
		progress,
		showSectionOrder,
		fullTime,
		textRef,
		containerRef,
		waveSurferRef,
		isPlayingAudio,
		skip15
	} = useContext(AudioContext);

	return (
		<>
			{isLoading ? (
				<div className={style.skeleton}>
					<Typography variant="h3" width="40%">
						<Skeleton />
					</Typography>
					<div className={style.skeleton_player}>
						<Skeleton variant="circular" width="20px" height="20px"></Skeleton>
						<Skeleton variant="circular" width="35px" height="30px"></Skeleton>
						<Skeleton variant="circular" width="20px" height="20px"></Skeleton>
						<Skeleton variant="rectangular" height="10px" width="100%"></Skeleton>
					</div>
					<Typography variant="p" width="100%">
						<Skeleton />
					</Typography>
				</div>
			) : (
				<section className={style.player}>
					<h2 className={style.player_title}>
						{audioSections[currentSection]?.title
							? audioSections[currentSection]?.title
							: 'No sections available'}
					</h2>
					<p className={style.player_section}>{audioSections[currentSection]?.order ? showSectionOrder : '---'}</p>

					<div className={style.player_controls} ref={containerRef}>
						<div className={style.player_controls_line} onClick={handleClickTime}>
							{process > 0 && (
								<p className={style.player_controls_line_time} style={{ left: progress + '%' }}>
									{convertTime(process)}
								</p>
							)}
							<div className={style.player_controls_line_bar} style={{ width: `${progress}%` }}></div>
							<p className={style.player_controls_line_full_time}>{convertTime(fullTime)}</p>
						</div>
						<hr className={`${style.player_divider} ${style.player_controls_divider}`} />
						<div className={style.player_controls_buttons}>
							<button
								onClick={() => skipBack(audioSections[currentSection]?.id)}
								className={style.player_controls_buttons_button}
								disabled={!isAudioReady}
							>
								<PlayerPrev />
							</button>
							<button
								onClick={() => skip15('back')}
								className={`${style.player_controls_buttons_button} ${style.player_controls_buttons_button_round}`}
								disabled={!isAudioReady}
							>
								<PlayerBack />
							</button>
							<button
								onClick={() => {
									handlePlayPause(audioSections[currentSection]?.id);
								}}
								disabled={!isAudioReady}
								className={`${style.player_controls_buttons_button} ${style.player_controls_buttons_button_filled}`}
							>
								<i
									className={`fa-solid fa-${isPlayingAudio.id === audioSections[currentSection]?.id && isPlayingAudio.state ? 'pause' : 'play'}`}
								/>
							</button>
							<button
								disabled={!isAudioReady}
								onClick={() => skip15('forward')}
								className={`${style.player_controls_buttons_button} ${style.player_controls_buttons_button_round}`}
							>
								<PlayerNext />
							</button>
							<button
								disabled={!isAudioReady}
								onClick={() => skipForward(audioSections[currentSection]?.id)}
								className={style.player_controls_buttons_button}
							>
								<PlayerSkip />
							</button>
						</div>
					</div>
					{/* <div className={style.player_transcript} ref={containerRef}>
						<p ref={textRef}>{audioSections[currentSection]?.speech}</p>
						<div className={style.player_transcript_filter}></div>
					</div> */}
					<Logo className={style.player_logo} />
				</section>
			)}
		</>
	);
};

export default Player;

import style from '../EditTour.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from '../../Form/InputField/InputField';
import Select from '../../Select/Select';
import states from '../../../helpers/usStates';
import * as yup from 'yup';
import Button from '../../Button/Button';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';


const propertyAddressSchema = yup.object().shape({
	adStreet: yup
		.string()
		.matches(/^[^-!¡$%^&*()_+|~=`°¨#¬{}[\]:";<>¿?]+$/, 'This field is required')
		.test('trimSpaces', 'This field is required', (value) => value?.trim().length > 0)
		.required('This field is required'),
	apartment: yup.string().optional(),
	city: yup.string().required('This field is required'),
	state: yup.string().required('This field is required'),
	zipCode: yup.string().required('This field is required'),
	mlsCode: yup.number().typeError('Please enter a valid MLS number.').required('Please enter a valid MLS number.'),
});

const EditAddress = ({ tour, updateTour, setStep }) => {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			adStreet: tour.adStreet,
			apartment: tour.apartment,
			city: tour.city,
			state: tour.state,
			zipCode: tour.zipCode,
			mlsCode: tour.mlsCode,
		},
		resolver: yupResolver(propertyAddressSchema),
		mode: 'all',
	});
	const onSubmit = (data) => {
		let prom = UserService.updateTourProperties(tour.id, data);
		toast.promise(prom, {
			loading: 'Updating tour...',
			success: (res) => {
				updateTour();
				return 'Tour updated';
			},
			error: (err) => {
				console.log(err);
				return 'Error updating tour';
			},
		});
	};
	return (
		<div className={style.editModal}>
			<h3 className={style.editModal_title}>
				<div className={style.editModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Address
			</h3>
			<form className={style.editModal_form} onSubmit={handleSubmit(onSubmit)}>
				<InputField
					width='100'
					name="mlsCode"
					placeholder="MLS #"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>

				<InputField
					width='100'
					name="adStreet"
					placeholder="Street"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<div className={style.editModal_form_row}>
					<InputField
						width='100'
						name="apartment"
						placeholder="Apt / Fl / Ste"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
					<InputField
						width='100'
						name="city"
						placeholder="City"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
				</div>
				<div className={style.editModal_form_row}>
					<Select name="state" register={register} error={errors} width='100'>
						<option value="" disabled>
								State
						</option>
						{states.map((state) => (
							<option key={state.value} value={state.value}>
								{state.label}
							</option>
						))}</Select>
					<InputField
						width='100'
						name="zipCode"
						placeholder="Zip Code"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
				</div>
			</form>
			<Button iconName="save" style="text" onClick={handleSubmit(onSubmit)}>Save</Button>
		</div>
	);
};

export default EditAddress;
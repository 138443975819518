import style from './VoiceOption.module.scss';
import {ReactComponent as Play} from '../../assets/icons/play.svg';
import {ReactComponent as CheckOn} from '../../assets/icons/check_on.svg';
import {ReactComponent as CheckOff} from '../../assets/icons/check_off.svg';
import { Avatar } from '@mui/material';

const VoiceOption = ({ voice, setVoice, selected, playVoice, playing }) => {
	return (
		<div className={style.voice}>
			<div className={style.voice_info}>
				<Avatar src={voice?.image}></Avatar>
				<p className={style.voice_name}>
					<b className={style.voice_name_gender}>{voice?.voice} -</b>{voice?.gender}
				</p>
			</div>
			<div className={style.voice_buttons}>
				<button className={style.voice_buttons_button} onClick={playVoice} type="button">
					<Play/>
				</button>
				<button className={style.voice_buttons_button}  type="button" onClick={() => setVoice(voice?.id)}>
					{
						selected?
							<CheckOn/>
							:
							<CheckOff/>
					}
				</button>
			</div>
		</div>
	);
};

export default VoiceOption;

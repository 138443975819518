import style from './VoiceSettings.module.scss';
import VoiceOption from '../../VoiceOption/VoiceOption';
import { ReactComponent as Play } from '../../../assets/icons/play.svg';
import { ReactComponent as Circle } from '../../../assets/icons/circle.svg';
import Avatar from '../../Avatar/Avatar';
import Button from '../../Button/Button';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';
import AuthService from '../../../service/auth.service';
import { setUser } from '../../../store/actions/userAction';

const VoiceSettings = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [voices, setVoices] = useState([]);
	const [activeVoice, setActiveVoice] = useState(null);
	const [selectedVoice, setSelectedVoice] = useState(null);
	const user = useSelector((state) => state.user);
	const [playing, setPlaying] = useState(false);
	const audioRef = useRef(null);
	const [filter, setFilter] = useState('All');
	const playAudio = (id, audio) => {
		if (playing === id) {
			audioRef.current.pause();
			setPlaying(false);
		} else {
			setPlaying(id);
			audioRef.current.src = audio;
			audioRef.current.play();
		}
	};
	const handleChangeVoice = () => {
		let prom = UserService.changeVoice(selectedVoice);
		setOpen(false);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				setActiveVoice(voices.filter((item) => item.id === selectedVoice)[0]);
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
						setSelectedVoice(null);
					})
					.catch((err) => {
						console.error(err);
					});
				return 'Voice updated successfully';
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	const closeModal = () => {
		setSelectedVoice(null);
		setOpen(false);
	};
	useEffect(() => {
		UserService.getVoiceList()
			.then((res) => {
				setVoices(res);
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);
	useEffect(() => {
		setActiveVoice(voices.filter((item) => item.id === user?.userData.voiceId)[0]);
	}, [user, voices]);
	return (
		<div className={style.voice}>
			<div className={style.voice_header}>
				<Avatar 
					image={activeVoice?.image}
					name={activeVoice?.voice}
					size={{width:'3.75rem', height:'3.75rem'}}
				/>
				<div className={style.voice_header_info}>
					<h4 className={style.voice_header_info_title}>{activeVoice?.voice}</h4>
					<p className={style.voice_header_info_desc}>{activeVoice?.gender}</p>
				</div>
				<button className={style.voice_header_button} onClick={() => playAudio(activeVoice?.id, activeVoice?.audio)} ><Play/></button>
			</div>
			<h2 className={style.voice_subtitle}>Change Voice</h2>
			<div className={style.voice_filters}>
				<button className={`${style.voice_filters_button} ${filter === 'All' && style.voice_filters_button_active}`} onClick={() => setFilter('All')}><Circle style={{width:'8px'}}/> <p>All</p></button>
				<button className={`${style.voice_filters_button} ${filter === 'Male' && style.voice_filters_button_active}`}  onClick={() => setFilter('Male')}><Circle style={{width:'8px'}}/> <p>Male</p></button>
				<button className={`${style.voice_filters_button} ${filter === 'Female' && style.voice_filters_button_active}`}  onClick={() => setFilter('Female')}><Circle style={{width:'8px'}}/> <p>Female</p></button>
			</div>
			<div className={style.voice_list}>
				<audio src="" ref={audioRef} onEnded={() => setPlaying(false)}></audio>
				{voices && voices.filter((item) => item.id !== activeVoice?.id && (item.gender === filter || filter === 'All')).map((voice, index) => {
					return (
						<VoiceOption
							key={index}
							voice={voice}
							setVoice={(id) => setSelectedVoice(id)}
							selected={selectedVoice === voice.id}
							playVoice={() => playAudio(voice.id, voice.audio)}
							playing={playing === voice.id}
						></VoiceOption>
					);
				})}
				
			</div>
			<div className={style.voice_action}>
				<Button style="text" iconName="save" onClick={handleChangeVoice}>Save</Button>
			</div>
		</div>
	);
};

export default VoiceSettings;



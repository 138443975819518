import React from 'react';
import style from './EditTour.module.scss';
import { useState, useEffect} from 'react';
import ModalBox from '../ModalBox/ModalBox';
import dots_left from '../../assets/icons/dots_left.svg';
import dots_right from '../../assets/icons/dots_right.svg';
import status_icon from '../../assets/icons/status.svg';
import location_icon from '../../assets/icons/location.svg';
import details from '../../assets/icons/details.svg';
import image from '../../assets/icons/image.svg';
import EditStatus from './EditStatus/EditStatus';
import EditMenu from './EditMenu/EditMenu';
import EditAddress from './EditAddress/EditAdress';
import EditDetails from './EditDetails/EditDetails';
import EditPhoto from './EditPhoto/EditPhoto';

const EditTour = ({ tourDetails, updateTour, open,  close, startTab }) => {
	const [step, setStep] = useState(null);

	const steps = [
		{
			next: () => setStep('Status'),
			label: 'Status',
			short_label: 'Status',
			icon: status_icon
		},
		{
			next: () => setStep('Address'),
			label: 'Address',
			short_label: 'Address',
			icon: location_icon
		},
		{
			next: () => setStep('Details'),
			label: 'Property Details',
			short_label: 'Details',
			icon: details
		},
		{
			next: () => setStep('Gallery'),
			label: 'Photo Gallery',
			short_label: 'Gallery',
			icon: image
		}
	];
	useEffect(() => {
		if(!startTab) {
			if (window.innerWidth > 800) {
				setStep('Address');
			} else {
				setStep('menu');
			}
		}	
		else{
			setStep(startTab);
		}
	}, [open]);
	return (
		<ModalBox isOpen={open} close={close}>
			<div className={style.editTour}>
				<EditMenu steps={steps} activeStep={step}/>
				{
					step === 'Status' && (
						<EditStatus updateTour={updateTour} tourId={tourDetails.id} setStep={setStep} tourState={tourDetails.status}/>
					)
				}
				{
					step === 'Address' && (
						<EditAddress updateTour={updateTour} tour={tourDetails} setStep={setStep}/>
					)
				}
				{
					step === 'Details' && (
						<EditDetails updateTour={updateTour} tour={tourDetails} setStep={setStep}/>
					)
				}
				{
					step === 'Gallery' && (
						<EditPhoto updateTour={updateTour} tourId={tourDetails.id} tourImages={tourDetails.tourImages} setStep={setStep}></EditPhoto>
					)
				}
				<div className={style.dots}>
					<img src={step === 'menu'? dots_left : dots_right} alt="" />
				</div>
			</div>
		</ModalBox>
	);
};

export default EditTour;

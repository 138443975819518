import style from '../ShareModal.module.scss';
import Button from '../../Button/Button';
import success from '../../../assets/images/success.svg';

const ShareLink = ({setStep, handleClose}) => {
	return (
		<div className={`${style.shareModal} ${style.shareModal_link}`}>
			<h3 className={style.shareModal_title}>
				<div className={style.shareModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Share Tour</h3>
			<div className={style.shareModal_link_body}>
				<img src={success} alt="" />
				<p className={style.shareModal_description}>Make your tour accessible to potential buyers!</p>
			</div>
			<Button style='text' iconPosition='end' iconName="line_arrow_right" onClick={handleClose}>Continue</Button>
		</div>
	);
};

export default ShareLink;
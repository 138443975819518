import React from 'react';
import styles from './FormWrapperLayout.module.scss';
import Button from '../../Button/Button';
const FormWrapperLayout = ({ children, formTitle, className, next, back, button='Next'}) => {
	return (
		<div className={styles.layoutContainer}>
			<div className={styles.layoutContainer_header}>
				<div className={styles.layoutContainer_header_button}>
					<Button iconName="left_line_arrow" style="text" size="sm" onClick={back}></Button>
				</div>
				<h3>{formTitle}</h3>
			</div>
			<div
				className={`${styles.formWrapper} ${className ? styles[className] : styles.defaultFormWrapper}`}
			>
				{children}
			</div>
			<div className={styles.layoutContainer_button}>
				<Button style="filled" color="primary" onClick={next}>{button}</Button>
			</div>
		</div>
	);
};

export default FormWrapperLayout;

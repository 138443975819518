import SignInForm from '../../components/SignInForm/SignInForm';
import createAccountImage from '../../assets/formsImages/createAccount.png';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { AccountFormsLayout } from '../../components/AccountFormsLayout/AccountFormsLayout';

const SignIn = ({ sendMessage }) => {
	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleSetUser = (user) => dispatch(setUser(user));
	useEffect(() => {
		if (user) {
			if (user.userData?.Subscription?.length > 0) navigate('/dashboard', { replace: true });
			else navigate('/checkout', { replace: true });
		}
	}, [user, navigate]);

	const handleNavigation = () => navigate('/login');

	return (
		<>
			<AccountFormsLayout
				formTitle="Create an Account"
				formText="Let's get started with your account."
				btnText="Already have an account?"
				btnboldText="Log in"
				image={createAccountImage}
				onClick={handleNavigation}
			>
				<SignInForm sendMessage={sendMessage} setUser={handleSetUser} />
			</AccountFormsLayout>
		</>
	);
};

export default SignIn;

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import style from './ImagesSlider.module.scss';

export const ImagesSlider = ({ images, status = 'Active' }) => {
	return (
		<>
			<Swiper
				modules={[Pagination]}
				breakpoints={{
					375: { slidesPerView: 1, slidesPerGroup: 1 },
				}}
				className={style.cardSwiper}
			>
				{images.map(({ imageUrl }, index) => (
					<SwiperSlide key={index}>
						<img
							src={imageUrl}
							alt="home tour"
							className={`${style.carrouselImg} ${status === 'Inactive' && style.disabledView}`}
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
};

import style from './OptionsButton.module.scss';
import { useEffect, useState } from 'react';
import {ReactComponent as OptionDots} from '../../assets/icons/option_dots.svg';

const OptionsButton = ({ options }) => {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (open) {
			const closeMenu = (e) => {
				if (!e.target.closest(`.${style.optionButton}`)) {
					setOpen(false);
				}
			};

			document.addEventListener('click', closeMenu);
			return () => document.removeEventListener('click', closeMenu);
		}
	}, [open]);
	return (
		<div className={style.optionButton}>
			<button className={style.optionButton_button} onClick={() => setOpen(true)}><OptionDots/></button>
			<div className={`${style.optionButton_dropdown} ${open && style.optionButton_dropdown_open}`}>
				{
					options.map((option, index) => (
						<button key={index} className={style.optionButton_dropdown_item} onClick={option.action}>
							{option.icon}<p>{option.label}</p>
						</button>
					))
				}
			</div>
		</div>
	);
};

export default OptionsButton;
import React from 'react';
import styles from './GetStarted.module.scss';
import { useNavigate } from 'react-router-dom';
import Button from '../../../Button/Button';
import { useSelector } from 'react-redux';
const GetStarted = () => {

	const user = useSelector((state) => state.user);
	const navigate = useNavigate();
	const handleStartTour = () => {
		if (user?.userData?.brokerage && user?.userData?.license && user?.userData?.phone)
			navigate('/create/propertyAddress');
		else
			navigate('/create/agentInfo');
	};

	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.headlineWrapper}>
					<h1>It&apos;s easy to get started!</h1>
					<div className={styles.headlineWrapper_button}>
						<Button onClick={handleStartTour} style="filled" color="primary" size="md">Get Started</Button>
					</div>
				</div>
				<div className={styles.stepDetailsWrapper}>
					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>01.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Property information</h3>
							<p className={styles.stepDetailsWrapper__description}>Fill out basic information about the property.
							</p>
						</div>
					</div>
					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>02.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Create a tour</h3>
							<p className={styles.stepDetailsWrapper__description}>Easily create your custom tour using our intuitive step-by-step process.
							</p>
						</div>
					</div>
					<div className={styles.stepDetailsWrapper__step}>
						<p className={styles.stepDetailsWrapper__order}>03.</p>
						<div className={styles.stepsDetailsWrapper__info}>
							<h3 className={styles.stepDetailsWrapper__title}>Share your tour</h3>
							<p className={styles.stepDetailsWrapper__description}>Share your finalized tour by sending a link or downloading a QR code.
							</p>
						</div>
					</div>
				</div>
				<div className={styles.buttonWrapper}>
					<Button onClick={handleStartTour} style="filled" color="primary" size="md">Get Started</Button>
				</div>
			</div>
		</>
	);
};

export default GetStarted;

import React, { useState } from 'react';
import {ReactComponent as CheckOn} from '../../../assets/icons/check_on.svg';
import {ReactComponent as CheckOff} from '../../../assets/icons/check_off.svg';
import Button from '../../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../store/actions/userAction';
import AuthService from '../../../service/auth.service';
import {toast} from 'sonner';
import ModalBox from '../../ModalBox/ModalBox';
import InputField from '../../Form/InputField/InputField';
import question from '../../../assets/images/question.svg';
import { useForm } from 'react-hook-form';
import style from './PrivacySettings.module.scss';
function PrivacySettings() {
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [step, setStep] = useState(1);
	const { register, handleSubmit, formState: { errors } } = useForm();
	const deleteAccount = (data) => {
		let prom = AuthService.deleteAccount(data.password);
		toast.promise(prom, {
			loading: 'Deleting account...',
			success: (res) => {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
					})
					.catch((err) => {
						console.error(err);
					});
				setOpen(false);
				return res.data.message;
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			}
		});
	};
	const deactivateAccount = (data) => {
		let prom = AuthService.deactivate(data.password);
		toast.promise(prom, {
			loading: 'Deactivating account...',
			success: (res) => {
				dispatch(setUser(res.data.data));
				setOpen(false);
				setStep(1);
			},
			error: (err) => {
				console.error(err);
			}
		});
	};
	const cancelDeletion = () => {
		let prom = AuthService.cancelDeletion();
		toast.promise(prom, {
			loading: 'Cancelling deletion...',
			success: (res) => {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
					})
					.catch((err) => {
						console.error(err);
					});
				return res.data.message;
			},
			error: (err) => {
				console.error(err);
				return err.data.message;
			}
		});
	};
	const [selectedOption, setSelectedOption] = useState({name:'Deactivate', action:deactivateAccount });


	return (
		<div className={style.privacy_tab}>
			<div className={style.privacy_tab_content}>
				{user?.userData.role === 'Admin' && 
				<div className={style.privacy_tab_content_box} onClick={() => setSelectedOption({name:'Deactivate', action:deactivateAccount })}>
					<div className={style.privacy_tab_content_box_header}>
						<h4 className={style.privacy_tab_content_box_header_title}>Deactivate account</h4>
						{selectedOption.name == 'Deactivate' ? <CheckOn /> : <CheckOff />}
					</div>
					<p className={style.privacy_tab_content_box_description}>Deactivating your account is temporary. Your account and main profile will be deactivated. All your data will be saved but tours wont’s be accessible.</p>
				</div>
				}
				<div className={style.privacy_tab_content_box} onClick={() => setSelectedOption({name:'Delete', action:deleteAccount })}>
					<div className={style.privacy_tab_content_box_header}>
						<h4 className={style.privacy_tab_content_box_header_title}>Delete account</h4>
						{selectedOption.name == 'Delete' ? <CheckOn /> : <CheckOff />}
					</div>
					<p className={style.privacy_tab_content_box_description}>Deleting your account is permanent. After deleting your TourMe account, you won’t be able to recover the content and information that you have stored in the platform.</p>
				</div>
			</div>
			<div className={style.privacy_tab_action}>
				<Button onClick={() => setOpen(true)} iconName='line_arrow_right' style="text" iconPosition='end'>Continue</Button>
			</div>
			<ModalBox isOpen={open} close={() => {setOpen(false); setStep(1);}} size='sx'>
				{
					step === 1 && 
						<div className={style.privacy_tab_modal}>
							<h3 className={style.privacy_tab_modal_title}>
								Privacy
							</h3>
							<div className={style.privacy_tab_modal_content}>
								<img src={question} alt="" />
								<p>Are you sure you want to <span>{selectedOption.name}</span>  your account?</p>
							</div>
							<Button onClick={() => setStep(2)} iconName='line_arrow_right' style="text" iconPosition='end'>Continue</Button>
						</div>
				}
				{
					step === 2 && 
						<form className={style.privacy_tab_modal} onSubmit={handleSubmit(selectedOption.action)}>
							<h3 className={style.privacy_tab_modal_title}>
									Privacy
							</h3>
							<div className={style.privacy_tab_modal_content}>
								<p>Enter your password to continue.</p>
								<InputField
									name='password'
									inputType="password"
									placeholder='Enter your password'
									register={register}
									error={errors}
								/>
							</div>
							<Button type='submit' iconName='line_arrow_right' style="text" iconPosition='end'>Continue</Button>
						</form>
				}
			</ModalBox>
		</div>
	);
}

export default PrivacySettings;

import { Avatar } from '@mui/material';

const AvatarImage = ({ name, image, size }) => {
	function stringAvatar(name) {
		let fullName = name?.split(' ');
		let initials = fullName?.length > 1 ? fullName[0][0] + fullName[1][0] : fullName[0][0];
		return {
			children: initials,
		};
	}
	return (
		<Avatar
			sx={size}
			style={{ backgroundColor: '#444444' }}
			src={image}
			{...stringAvatar(name ?? 'User Name')}
		/>
	);
};

export default AvatarImage;

import React from 'react';
import { Container } from '@mui/material';
import Button from '../Button/Button';
import styles from './EmptyStateDashboard.module.scss';
import { useNavigate } from 'react-router-dom';
import device_1 from '../../assets/emptyStateDashboard/device.png';
import device_2 from '../../assets/emptyStateDashboard/device01.png';
import device_3 from '../../assets/emptyStateDashboard/device02.png';
export const EmptyStateDashboard = () => {
	const navigate = useNavigate();
	return (
		<>
			<Container maxWidth="xl">
				<div className={styles.container}>
					<h1>Welcome to <span>TourMe.</span></h1>
					<p className={styles.container__text}>
						The ultimate service designed to accelerate property sales by showcasing their unique features
						and generating heightened interest. Get ready to create your very first tour with ease, as we
						walk you through a seamless step-by-step process.
					</p>
					<div className={styles.heroContainer}>
						<Button
							style='filled'
							color='primary'
							onClick={() => navigate('/create/getStarted')}
							iconName="plus"
						>
							Create your first tour
						</Button>
					</div>
					<div className={styles.container_images}>
						<img className={styles.container_images_1} src={device_1} alt="" />
						<img className={styles.container_images_2} src={device_2} alt="" />
						<img className={styles.container_images_3} src={device_3} alt="" />
					</div>
				</div>
			</Container>
		</>
	);
};

import style from './CheckBox.module.scss';

const CheckBox = ({ children, name, register, onChange, error, validation, ...rest }) => {
	const handleChange = async (e) => {
		onChange(e.target.checked);
	};
	return (
		<div className={style.checkbox}>
			<input
				type="checkbox"
				name={name}
				id={name}
				{...register(name, validation)}
				{...rest}
				className={error[name] ? style.checkbox_error : style.checkbox_valid}
				defaultChecked
			/>
			{children}
		</div>
	);
};

export default CheckBox;

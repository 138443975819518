import React from 'react';
import style from './ModalBox.module.scss';
import cross from '../../assets//icons/cross.svg';
import circleCheck from '../../assets/icons/circle-check.svg';
const ModalBox = ({ children, isOpen=false, close, size='md' }) => {
	return (
		<>
			{
				isOpen ? 
					<div className={style.ModalBox}
					>
						<div
							className={`${style.ModalBox__content} ${style[`ModalBox__content__${size}`]}`}
						>
							<button
								className={style.ModalBox__content__close}
								onClick={close}
							>
								<img src={cross} alt="" />
							</button>
							{children}
						</div>
					</div>
            
					:
					<></>
            
			}
		</>
	);
};

export default ModalBox;

import resetPassImg from '../../assets/formsImages/resetPass.png';
import InputField from '../../components/Form/InputField/InputField';
import ButtonLoading from '../../components/Form/LoadingButton/LoadingButton';
import UserService from '../../service/user.service';
import { toast } from 'sonner';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountFormsLayout } from '../../components/AccountFormsLayout/AccountFormsLayout';

const ResetPass = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { register, formState, handleSubmit, setValue } = useForm();
	const { errors } = formState;
	const onSubmit = (data) => {
		setLoading(true);
		let prom = UserService.resetPassword(data.email);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				setLoading(false);
				navigate('/login', { replace: true });
				return data.message;
			},
			error: (err) => {
				setLoading(false);
				return err.response.data.message;
			},
		});
	};

	const handleNavigation = () => navigate('/login');

	return (
		<AccountFormsLayout
			formTitle="Forgot Password?"
			formText="Enter the email address you used when you joined and we’ll send you instructions to reset your password."
			btnText="Return to"
			btnboldText="Login"
			image={resetPassImg}
			onClick={handleNavigation}
		>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width:'100%', display: 'flex', 'flexDirection': 'column' }}>
				<InputField
					name="email"
					type="text"
					width='100'
					placeholder="Email Address"
					disabled={loading}
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<ButtonLoading
					label="Send Reset Instructions"
					type="submit"
					loading={loading}
					disabled={loading}
				></ButtonLoading>
			</form>
		</AccountFormsLayout>
	);
};

export default ResetPass;

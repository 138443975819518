import style from './ClientViewTour.module.scss';
import { useEffect, useState, useRef, useContext } from 'react';
import { TourDataContext } from '../../../context/TourDataContext';
import { useParams } from 'react-router-dom';
import IconText from '../../Form/IconText/IconText';
import ClientPlayer from '../ClientPlayer/ClientPlayer';
import RateModal from '../RateModal/RateModal';
import Divider from '@mui/material/Divider';
import Avatar from '../../Avatar/Avatar';
import WaveSurfer from 'wavesurfer.js';
import ClientNavBar from '../ClientNavBar/ClientNavBar';
import ClientService from '../../../service/client.service';
import Flex from '../../Flex/Flex';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ImagesSlider } from '../ImagesSlider/ImagesSlider';
import Skeleton from '@mui/material/Skeleton';
import Modal from '@mui/material/Modal';
import { useDrag } from '@use-gesture/react';
import { useSpring, useSpringRef, animated } from '@react-spring/web';
import { toast } from 'sonner';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const ClientViewTour = () => {
	const { setClientTour } = useContext(TourDataContext);
	const [isplaying, setisplaying] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [currentSection, setCurrentSection] = useState(null);
	const [sections, setSections] = useState([]);
	const [lastSection, setLastSection] = useState(null);
	const [tour, setTour] = useState({});
	const audioElem = useRef();
	const [rate, setRate] = useState(false);
	const [activeTab, setActiveTab] = useState('01');
	const { id } = useParams();
	const [openRateModal, setOpenRateModal] = useState(null);
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
	const [currentLanguage, setCurrentLanguage] = useState('');
	
	const [duracionAudios, setDuracionAudios] = useState({});
	useEffect(() => {
		ClientService.getTourView(id)
			.then((res) => {
				setTour(res);
				setClientTour(res);
				setCurrentLanguage(res.user.Voice);
				if (!res.sections[0].Audios) {
					setIsLoading(true);
					const promises = res.sections.map((section) => {
						return ClientService.getSectionTranslation(section.id, res.user.Voice.id, res.user.id)
							.then((res) => {
								return { id: section.id, src: res.data.src };
							})
							.catch((err) => {
								console.error(err);
								throw err;
							});
					});

					Promise.all(promises)
						.then((response) => {
							setSections(
								res.sections.map((section) => {
									const translation = response.find((t) => t.id === section.id);
									if (translation) {
										return { ...section, Audios: translation.src };
									}
									return section;
								}),
							);

							setIsLoading(false);
							toast.success('Sections translated');
						})
						.catch((err) => {
							setIsLoading(false);
							toast.error('Error getting section translations');
						});
				} else {
					setSections(res.sections);
					res.sections.map((section) => {
						getAudioDuration(section.id, section.Audios.src);
					});
					setCurrentSection(res.sections[0]);
					setLastSection(res.sections[res.sections.length - 1]);
					setIsLoading(false);
				}
				
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);
	const handleTouchMove = (e) => {
		e.preventDefault();
	};
	const handleLanguage = async (lang) => {
		setIsLoading(true);
		setCurrentLanguage(lang);
		try {
			const promises = sections.map((section) => {
				return ClientService.getSectionTranslation(section.id, lang.id, tour.user.id)
					.then((res) => {
						return { id: section.id, src: res.data.src };
					})
					.catch((err) => {
						console.error(err);
						throw err;
					});
			});

			const translations = await Promise.all(promises);

			setSections((prevSections) =>
				prevSections.map((section) => {
					const translation = translations.find((t) => t.id === section.id);
					if (translation) {
						if (currentSection.id === section.id)
							setCurrentSection({ ...section, Audios: { src: translation.src } });
						return { ...section, Audios: { src: translation.src } };
					}
					return section;
				}),
			);
			setIsLoading(false);
			toast.success('Sections translated');
		} catch (error) {
			setIsLoading(false);
			toast.error('Error getting section translations');
		}
	};
	const getAudioDuration = (id, url) => {
		const wavesurfer = WaveSurfer.create({
			container: audioElem.current,
			backend: 'MediaElement',
			waveColor: 'violet',
			progressColor: 'purple',
		});

		wavesurfer.load(url);

		wavesurfer.once('ready', () => {
			let duration = wavesurfer.getDuration();
			setSections((prevSections) =>
				prevSections.map((section) => {
					if (section.id === id) {
						return { ...section, duration: duration };
					}
					return section;
				}),
			);
			wavesurfer.destroy();
		});
	};
	const handleRate = (value) => {
		setRate(false);
		ClientService.rateTour(tour.id, value)
			.then((res) => {
			})
			.catch((err) => {
				console.error(err);
			});
	};
	useEffect(() => {
		if (isplaying) {
			audioElem.current.play();
		} else {
			audioElem.current.pause();
		}
	}, [isplaying]);

	const copyEmail = () => {
		navigator.clipboard.writeText(tour?.user?.email);
		/*         sendMessage({ severity: "success", message: "Email copied to clipboard" }) */
	};
	const copyPhone = () => {
		navigator.clipboard.writeText(tour?.user?.phone);
		/*         sendMessage({ severity: "success", message: "Phone copied to clipboard" }) */
	};
	const fmtMSS = (s) => {
		return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s.toFixed(0);
	};

	const ClientTab = ({ id, title, children, activeTab, setActiveTab }) => {
		return (
			<li className={activeTab === id ? style.activeTab : ''} onClick={setActiveTab}>
				{children && <span>{children}</span>}
				<p>{title}</p>
			</li>
		);
	};

	const TabContent = ({ id, activeTab, children }) => {
		return activeTab === id ? <div>{children}</div> : null;
	};

	const handleClick = (id) => {
		if((open && id !== activeTab) || !open) {
			setHeight(maxHeights[id] ? maxHeights[id] : height);
			api.start({
				height: maxHeights[id] ? maxHeights[id] : height,
			});
			setActiveTab(id);
			if(!open) setOpen(!open);
		} else {
			setHeight(74);
			api.start({
				height: 74,
			});
			if(open) setOpen(!open);
		}
	};

	const handleClickAway = () => {
		setOpen(false);
		// setHeight(74);
	};
	const maxHeights = {
		'01': 400,
		'02': 500,
		'03': 300,
	};

	const api = useSpringRef();
	const springs = useSpring({
		ref: api,
		from: { height: 74 },
	});
	const [open, setOpen] = useState(false);
	const [height, setHeight] = useState(74);
	const handleDrag = useDrag(({ active, last, movement: [mx, my], tap  }) => {
		if(tap || Math.abs(my) < 10) return;
		if(last){
			if(open && my > 0){
				setHeight(74);
				api.start({
					height: 74,
				});
				setOpen(false);
			}else{
				setHeight(maxHeights[activeTab] ? maxHeights[activeTab] : height);
				api.start({
					height: maxHeights[activeTab] ? maxHeights[activeTab] : height,
				});
				setOpen(true);
			}
			return;
		}
		let newHeight = height - my;
		api.start({
			height: newHeight,
		});
	});

	const handleCloseRateModal = () => {
		setOpenRateModal(false);
	};

	useEffect(() => {
		if (openRateModal === null && currentSection && lastSection) {
			if (currentSection.id === lastSection.id) {
				setOpenRateModal(true);
			}
		}
	}, [openRateModal, currentSection]);
	return (
		<div className={style.client}>
			<ClientNavBar selectedLang={currentLanguage} handleChange={handleLanguage} gender={tour?.user?.Voice?.gender}/>
			<div className={style.childrenContainer}>
				<div className={style.dataWrapper}>
					{tour.tourImages ? (
						<div className={style.dataWrapper__slider}>
							<ImagesSlider images={tour.tourImages} status={tour.status} />
						</div>
					) : (
						<Skeleton
							variant="rectangular"
							width={330}
							height={219}
							sx={{ borderRadius: '20px', marginLeft: 'auto', marginRight: 'auto' }}
						/>
					)}
					{tour.adStreet ? (
						<p className={style.dataWrapper__address}>
							{tour?.adStreet},
							<span>
								{tour?.city} {tour?.state} {tour?.zipCode}
							</span>
						</p>
					) : (
						<>
							<Skeleton
								variant="rectangular"
								width={180}
								height={16}
								sx={{
									marginTop: '0.5rem',
									marginBottom: '0.5rem',
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
							/>
							<Skeleton
								variant="rectangular"
								width={220}
								height={16}
								sx={{
									marginTop: '0.5rem',
									marginBottom: '0.5rem',
									marginLeft: 'auto',
									marginRight: 'auto',
								}}
							></Skeleton>
						</>
					)}
					<Divider style={{ 'borderColor': '#D9DEE0' }} />
				</div>
				<div className={style.playerWrapper}>
					<Divider style={{ 'borderColor': '#D9DEE0', 'margin-bottom': '1rem' }} />
					<audio ref={audioElem} src={currentSection?.Audios?.src} />
					<ClientPlayer
						handleRate={() => setRate(true)}
						sections={sections}
						currentSection={currentSection}
						setCurrentSection={setCurrentSection}
					/>
				</div>
			</div>
			<animated.div className={style.tabMenu} {...handleDrag()} style={{ 
				...springs,
				maxHeight: 500,
				minHeight: '74px',
			}}>
				<div className={style.client_view_action_bar_drag}></div>
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box sx={{ 
						position: 'relative',
						height: '100%',
						display: 'grid',
						gridTemplateRows: '74px calc(100% - 74px)' 
					}}>
						<ul className={style.tabMenuContainer}>
							<ClientTab
								activeTab={activeTab}
								setActiveTab={() => handleClick('01')}
								title="Info"
								id="01"
							/>
							<ClientTab
								activeTab={activeTab}
								setActiveTab={() => handleClick('02')}
								title="Sections"
								id="02"
							/>
							<ClientTab
								activeTab={activeTab}
								setActiveTab={() => handleClick('03')}
								title="Contact"
								id="03"
							/>
						</ul>
						<div className={style.outlet}>
							{/* INFO */}
							<TabContent id="01" activeTab={activeTab}>
								<div className={`${style.tabContentParentContainer}`}>
									<div className={style.sectionInfoWrapper}>
										<p>
											<b>{tour?.adStreet},</b>
											<br />
											{tour?.city} {tour?.state} {tour?.zipCode}
										</p>
										<Flex direction="row" gap="1rem">
											<IconText
												icon="fa-solid fa-bed"
												text={`${tour?.beds} beds`}
												icon_color="#fff"
												text_color="#fff"
												icon_size="1.2rem"
												font_size="1rem"
											/>
											<IconText
												icon="fa-solid fa-bath"
												text={`${tour?.baths} baths`}
												icon_color="#fff"
												text_color="#fff"
												icon_size="1.2rem"
												font_size="1rem"
											/>
										</Flex>
									</div>
									<div className={style.sectionInfoDetails}>
										<Flex direction="row" justify="space-between">
											<p>MLS Number:</p>
											<p>{tour?.mlsCode}</p>
										</Flex>
										<Flex direction="row" justify="space-between">
											<p>Year Built:</p>
											<p>{tour?.yearBuilt}</p>
										</Flex>
										<Flex direction="row" justify="space-between">
											<p>Size:</p>
											<p>
												{tour?.feets?.toLocaleString()}
												<span> sqft</span>
											</p>
										</Flex>
										<Flex direction="row" justify="space-between">
											<p>Price:</p>
											<p>${tour?.price?.toLocaleString()}</p>
										</Flex>
									</div>
								</div>
							</TabContent>

							{/* SECTIONS */}
							<TabContent id="02" activeTab={activeTab}>
								<div className={`${style.tabContentParentContainer}`}>
									<ul className={style.audioSectionsWrapper}>
										{sections.map((song, i) => (
											<li
												key={i}
												onClick={() => setCurrentSection(song)}
												className={`${
													song.id === currentSection.id ? style.activeAudio : ''
												} ${style.client_view_action_bar_content_section_list_item}`}
											>
												<p>
													<span>{i > 8 ? i + 1 : `0${i + 1}`}.</span> {song.title}
												</p>
												<span className={style.audioTime}>{fmtMSS(song?.duration)}</span>
											</li>
										))}
									</ul>
								</div>
							</TabContent>
							{/* CONTACT */}
							<TabContent id="03" activeTab={activeTab}>
								<div className={`${style.tabContentParentContainer}`}>
									<div className={style.contactInfoAgent}>
										<div className={style.contactInfoAgent__avatar}>
											<Avatar image={tour.user?.userImage?.imageUrl} name={tour.user?.name} />
										</div>
										<div className={style.contactInfoAgent__name}>
											<p>{tour?.user?.name}</p>
											<p>{tour?.user?.brokerage}</p>
										</div>
									</div>
									<div className={style.contactInfoWrapper}>
										<div className={style.contactInfoWrapper__content}>
											<p className={style.contactInfoWrapper__label}>Phone:</p>
											<p
												className={style.contactInfoWrapper__value}
												onClick={() => copyPhone()}
											>
												{tour?.user?.phone}
											</p>
										</div>
										<div className={style.contactInfoWrapper__content}>
											<p className={style.contactInfoWrapper__label}>Email:</p>
											<p
												className={style.contactInfoWrapper__value}
												onClick={() => copyEmail()}
											>
												{tour?.user?.email}
											</p>
										</div>
									</div>
									<div className={style.contactInfoButtons}>
										<a href={`tel:${tour?.user?.phone}`}>Call</a>
										<a
											href={`mailto:${tour?.user?.email}?Subject=I%20have%20a%20question%20about%20this%20house`}
										>
											Send Email
										</a>
									</div>
								</div>
							</TabContent>
						</div>
					</Box>
				</ClickAwayListener>
			</animated.div>
			<RateModal onRate={handleRate} onClose={handleCloseRateModal} open={openRateModal}/>
			<div className={style.loading} style={{ display: isLoading ? 'flex' : 'none' }}>
				<CircularProgress color="inherit" />
			</div>
		</div>
	);
};

export default ClientViewTour;

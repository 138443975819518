import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm} from 'react-hook-form';
import style from './CustomerSupport.module.scss';
import Button from '../../Button/Button';
import Select from '../../Select/Select';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../service/user.service';
import TextArea from '../../Form/TextArea/TextArea';
import { toast } from 'sonner';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../Form/InputField/InputField';

const supportResolver = yup.object().shape({
	email: yup.string().email().required('Please enter a valid email address.'),
	name: yup
		.string()
		.matches(/^([^0-9-!$%^]*)$/, 'Name should not contain numbers')
		.required('Please enter your full name.')
		.test('trimSpaces', 'Please enter your full name.', (value) => value?.trim().length > 0),
	phone: yup.string().matches(/^\+[0-9]{0,3}[-\s.]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, 'This field should match the phone international format').required('Please enter a valid phone number'),
	reason: yup.string().required('This field is required'),
	message: yup.string().test('trimSpaces', 'Please enter your message.', (value) => value?.trim().length > 0)
});

const CustomerSupport = () => {
	const navigate = useNavigate();
	const formRef = useRef();
	const user = useSelector((state) => state.user);
	const { handleSubmit, register, formState, reset, setValue } = useForm({
		defaultValues:{
			name: user?.userData?.name,
			email: user?.userData?.email,
			phone: user?.userData?.phone,
		},
		resolver: yupResolver(supportResolver),
		mode: 'onBlur',
	});
	const { errors } = formState;

	// useEffect(() => {
	// 	if (user?.userData) {
	// 		reset({
	// 			name: user?.userData?.name,
	// 			email: user?.userData?.email,
	// 			phone: user?.userData?.phone,
	// 			license: user?.userData?.license,
	// 			brokerage: user?.userData?.brokerage,
	// 		});
	// 		setImage(user?.userData?.userImage);
	// 	}
	// }, [user, reset]);

	const onSubmit = (d) => {
		const prom = UserService.sendSupportRequest(d);
		reset();
		formRef.current.reset();
		toast.promise(prom, {
			loading: 'Sending...',
			success: () => {
				return 'Support request sent';
			},
			error: 'Error sending support request',
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} ref={formRef} className={style.support}>
			<InputField
				width='100'
				name="name"
				placeholder="Full Name"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="phone"
				inputType="phone"
				placeholder="Phone Number"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="email"
				placeholder="Email"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<Select name="reason" register={register} error={errors} width='100'>
				<option value="General" selected>General</option>
				<option value="Technical Support">Technical Support</option>
				<option value="Billing">Billing</option>
				<option value="Other">Other</option>
			</Select>
			<TextArea
				name="message"
				register={register}
				error={errors}
				placeholder="Type your message ..."
			></TextArea>
			<div className={style.support_action}>
				<Button style="text" iconName="send" type='submit'>Send</Button>
			</div>
		</form>
	);
};
export default CustomerSupport;

import React, { useEffect, useState } from 'react';
import MainLayout from './containers/Layout/MainLayout';
import Dashboard from './containers/Dashboard/Dashboard';
import Checkout from './containers/Checkout/Checkout';
import TourHome from './containers/TourHome/TourHome';
import InactiveAccount from './containers/InactiveAccount/InactiveAccount';
import LogIn from './containers/LogIn/LogIn';
import { useNavigate } from 'react-router-dom';
import SignIn from './containers/SignIn/SignIn';
import Settings from './containers/Settings/Settings';
import ResetPass from './containers/ResetPass/ResetPass';
import UpdateBilling from './containers/UpdateBilling/UpdateBilling';
import ChangePass from './containers/ChangePass/ChangePass';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import store from './store/store';
import { AudioProvider } from './context/AudioContext';
import { Toaster } from 'sonner';
import TourDataProvider from './context/TourDataContext';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import './App.css';
import { Routes, Route, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import EmailVerification from './containers/EmailVerification/EmailVerification';
import WelcomeView from './components/Client/WelcomeView/WelcomeView';
import ClientViewTour from './components/Client/ClientViewTour/ClientViewTour';
import CreateTourV2 from './components/CreateTourV2/CreateTourV2';
import { CreateTourProvider } from './context/CreateTourContext';
import GetStarted from './components/CreateTourV2/Steps/GetStarted/GetStarted';
import PropertyAddressForm from './components/CreateTourV2/Steps/StepOne/PropertyAddressForm';
import PropertyInformationForm from './components/CreateTourV2/Steps/StepTwo/PropertyInformationForm';
import AddPhotoFiles from './components/CreateTourV2/Steps/StepThree/AddPhotoFiles';
import EditSection from './components/CreateTourV2/Steps/StepSix/EditSection';
import WhatsNext from './components/CreateTourV2/Steps/FinalStep/WhatsNext';
import AgentInfo from './components/CreateTourV2/AgentInfo/AgentInfo';
import CheckoutSuccess from './containers/CheckoutSuccess/CheckoutSuccess';
import Sections from './components/CreateTourV2/Steps/StepFive/Sections';
import CustomerSupport from './containers/CustomerSupport/CustomerSupport';
import { createTheme, ThemeProvider } from '@mui/material/styles';

Sentry.init({
	dsn: 'https://12c7cf24893349a99a218d5cb26a2d0e@o4505125918277632.ingest.sentry.io/4505125973262336',

	replaysSessionSampleRate: 0.25, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 0.25, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	environment: process.env.REACT_APP_SENTRY_ENV,
	release: process.env.REACT_APP_RELEASE_VERSION,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
			tracingOrigins: ['*'],
		}),
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
	// Session Replay
});
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);


const theme = createTheme({
	palette: {
		primary: {
			main: '#c3c3c3',
		},
		secondary: {
			main: '#fff',
		},
	},
});


function App() {
	const navigate = useNavigate();
	const [alert, setAlert] = useState(null);

	useEffect(() => {
		if (alert)
			setTimeout(() => {
				setAlert(null);
			}, 3000);
	}, [alert]);
	useEffect(() => {
		if (window.location.pathname === '/') navigate('/login');
	}, [navigate]);
	return (
		<ThemeProvider theme={theme}>
			<Provider store={store}>
				<TourDataProvider>
					<AudioProvider>
						<StyledEngineProvider injectFirst>
							<CreateTourProvider>
								<div className="App">
									<Toaster richColors />
									<MainLayout>
										<SentryRoutes>
											<Route exact path="/dashboard" element={<Dashboard />} />
											<Route exact path="/tourhome/:tourId" element={<TourHome />} />
											<Route
												exact
												path="/resources/tourhome/:tourId"
												element={<TourHome sample={true} />}
											/>
											<Route exact path="/inactive" element={<InactiveAccount />} />
											<Route exact path="/login" element={<LogIn />} />
											<Route exact path="/signin" element={<SignIn />} />
											<Route exact path="/support" element={<CustomerSupport />} />
											<Route exact path="/reset" element={<ResetPass />} />
											<Route exact path="/settings/:setting" element={<Settings />} />
											<Route exact path="/reset/:id" element={<ChangePass />} />
											{/*        <Route exact path="/create" element={<CreateTour />} /> */}
											<Route exact path="/checkout" element={<Checkout />} />
											<Route exact path="/tour/:id" element={<ClientViewTour />} />
											<Route exact path="/welcome/:id" element={<WelcomeView />} />
											<Route exact path="/verification/:token" element={<EmailVerification />} />
											<Route path="/checkout/success" element={<CheckoutSuccess />} />
											<Route path="/billing/update" element={<UpdateBilling />} />
											<Route exact path="/create" element={<CreateTourV2 />}>
												<Route path="getStarted" element={<GetStarted />} />
												<Route path="agentInfo" element={<AgentInfo />} />
												<Route path="propertyAddress" element={<PropertyAddressForm />} />
												<Route path="propertyInfo" element={<PropertyInformationForm />} />
												<Route path="gallery" element={<AddPhotoFiles />} />
												<Route exact path="sections" element={<Sections />}/>
												<Route path="sections/edit" element={<EditSection />} />
												<Route path="sections/new" element={<EditSection />} />
												<Route path="nextSteps" element={<WhatsNext />} />
											</Route>
										</SentryRoutes>
									</MainLayout>
								</div>
							</CreateTourProvider>
						</StyledEngineProvider>
					</AudioProvider>
				</TourDataProvider>
			</Provider>
		</ThemeProvider>
	);
}

export default App;

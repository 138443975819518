import InputField from '../Form/InputField/InputField';
import Form from '../../components/Form/Form/Form';
import { useEffect, useState } from 'react';
import ButtonLoading from '../Form/LoadingButton/LoadingButton';
import AuthService from '../../service/auth.service';
import style from './LogInForm.module.scss';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'sonner';
import CheckBox from '../Form/CheckBox/CheckBox';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const loginResolver = yup.object().shape({
	email: yup
		.string()
		.email('Please enter a valid email address.').optional('Please enter your email.'),
	password: yup.string().optional('Please enter your password.'),
});

const LogInForm = ({ setUser }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [defaultValues, setDefaultValues] = useState({ email: '', password: '' });
	const { register, formState, handleSubmit, setValue } = useForm({
		resolver: yupResolver(loginResolver),
		defaultValues,
		mode: 'onBlur',
	});
	const { errors } = formState;
	const onSubmit = (data) => {
		setLoading(true);
		if (data.remember) {
			const encodedUsername = btoa(data.email);
			const encodedPassword = btoa(data.password);

			localStorage.setItem('dXNlcm5hbWU=', encodedUsername);
			localStorage.setItem('cGFzc3dvcmQ=', encodedPassword);
		} else {
			localStorage.removeItem('dXNlcm5hbWU=');
			localStorage.removeItem('cGFzc3dvcmQ=');
		}
		let prom = AuthService.login(data.email, data.password);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				const result = data.data.data;
				setUser(result);
				setLoading(false);
				if (result.auth) navigate('/dashboard', { replace: true });
				else navigate('/checkout', { replace: true });
				return 'Welcome back ' + result.userData?.name;
			},
			error: (err) => {
				setLoading(false);
				console.error(err);
				return err.response.data? err.response.data.message : err.message;
			},
		});
	};
	useEffect(() => {
		const username = localStorage.getItem('dXNlcm5hbWU=');
		const password = localStorage.getItem('cGFzc3dvcmQ=');
		if (username && password) {
			const decodedUsername = atob(username);
			const decodedPassword = atob(password);
			setDefaultValues({ email: decodedUsername, password: decodedPassword });
		}
	}, []);
	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			style={{ width:'100%', display: 'flex', 'flexDirection': 'column', gap: '0.25rem' }}
		>
			<InputField
				name="email"
				placeholder="Email Address"
				disabled={loading}
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField 
				name="password" 
				inputType="password" 
				disabled={loading} 
				placeholder="Password" 
				register = {register}
				setValue={setValue}
				error= {errors}
			/>
			<div className={style.row}>
				<CheckBox 
					name="remember" 
					register = {register}
					error= {errors}>
					<label htmlFor="remember" >Remember me</label></CheckBox>
				<Link className={style.forgot_password} to="/reset">
					Forgot password?
				</Link>
			</div>
			<ButtonLoading label="Log In" loading={loading} type="submit"></ButtonLoading>
		</form>
	);
};

export default LogInForm;

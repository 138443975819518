import { useState, useRef, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import style from './ClientPlayer.module.scss';
import { Divider, Skeleton } from '@mui/material';
import WaveSurfer from 'wavesurfer.js';
const ClientPlayer = ({ changeLanguage, selectedLang, currentSection, setCurrentSection, sections, language }) => {
	const containerRef = useRef();
	const waveSurferRef = useRef();
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const [fullTime, setTime] = useState(null);
	const [process, setProcess] = useState(0);
	const showSectionOrder = currentSection?.order < 10 ? `Section 0${currentSection?.order}` : `Section ${currentSection?.order}`;
	const [ready, setReady] = useState(false);
	const handleClikcTime = (e) => {
		if (currentSection) {
			const { left, width } = e.currentTarget.getBoundingClientRect();
			const clickX = e.clientX - left;
			const duration = waveSurferRef.current.getDuration();
			const time = (clickX / width) * duration;
			waveSurferRef.current.seekTo(time / duration);
			setProgress(Math.round((time / fullTime) * 100));
			setProcess(time);
		}
	};
	const back_15 = () => {
		if (waveSurferRef.current) {
			waveSurferRef.current.skipBackward(15);
			setIsPlaying(true);
			waveSurferRef.current.play();
		}
	};
	const forward_15 = () => {
		if (waveSurferRef.current) {
			waveSurferRef.current.skipForward(15);
			setIsPlaying(true);
			waveSurferRef.current.play();
		}
	};
	const skipBack = () => {
		setIsPlaying(false);
		if (waveSurferRef.current) waveSurferRef.current.destroy();
		const currentIndex = sections.findIndex((speech) => speech.id === currentSection.id);
		if (currentIndex === 0) {
			setCurrentSection(sections[sections.length - 1]);
		} else {
			setCurrentSection(sections[currentIndex - 1]);
		}
	};
	const skipForward = () => {
		setIsPlaying(false);
		if (waveSurferRef.current) waveSurferRef.current.destroy();
		const currentIndex = sections.findIndex((speech) => speech.id === currentSection.id);

		if (currentIndex === sections.length - 1) {
			setCurrentSection(sections[0]);
		} else {
			setCurrentSection(sections[currentIndex + 1]);
		}
	};
	const handlePlayPause = () => {
		if (!waveSurferRef.current && !ready) return;
		if (waveSurferRef.current.isPlaying()) {
			setIsPlaying(false);
			waveSurferRef.current.pause();
		} else {
			setIsPlaying(true);
			waveSurferRef.current.play();
		}
	};
	const convertTime = (seconds) => {
		const minutes = Math.floor(seconds / 60); // Obtiene los minutos
		const secondsRemainder = Math.floor(seconds % 60); // Obtiene los segundos restantes

		// Agrega un cero a la izquierda si los segundos restantes son menores a 10
		const formattedSeconds = secondsRemainder < 10 ? `0${secondsRemainder}` : secondsRemainder;

		return `${minutes}:${formattedSeconds}`; // Devuelve el formato mm:ss
	};
	useEffect(() => {
		if (currentSection?.Audios && containerRef.current) {
			setProgress(0);
			setProcess(0);
			waveSurferRef.current?.destroy();
			setReady(false);
			const waveSurfer = WaveSurfer.create({
				container: containerRef.current,
				backend: 'MediaElement',
				waveColor: 'transparent',
				progressColor: 'transparent',
				cursorWidth: 0,
				barWidth: 0,
				barMinHeight: 2,
				barHeight: 0,
				height: 0,
				responsive: true,
				skipLength: 15,
				barGap: 0,
				normalize: false,
			});
			waveSurfer.load(currentSection.Audios.src);
			waveSurfer.on('ready', () => {
				setProgress(0);
				setReady(true);
				waveSurferRef.current = waveSurfer;
				setTime(waveSurfer.getDuration());
			});
			waveSurfer.on('audioprocess', function () {
				if (waveSurfer.isPlaying()) {
					let currentTime = waveSurfer.getCurrentTime();
					setProcess(currentTime);
					let prg = Math.round((currentTime / waveSurfer.getDuration()) * 100);
					setProgress(prg);
				}
			});
			waveSurfer.on('finish', () => {
				setIsPlaying(false);
				setProcess(0);
				setProgress(0);
				const currentIndex = sections.findIndex((speech) => speech.id === currentSection.id);
				if (currentIndex < sections.length - 1) skipForward();
			});

			return () => {
				waveSurfer.destroy();
			};
		}
	}, [currentSection]);

	return (
		<div className={style.player}>
			<div className={style.player_header}>
				{currentSection?.title ? <h1>{currentSection?.title}</h1> : <Skeleton variant="rectangular" width={120} height={16} />}
				{currentSection?.order ? <p>{showSectionOrder}</p> : <Skeleton variant="rectangular" width={120} height={16} />}
			</div>
			<div className={style.player_controls}>
				<div className={style.player_navigation} ref={containerRef}>
					<div className={style.player_navigation_wrapper} onClick={handleClikcTime}>
						{process > 0 ? (
							<p className={style.player_navigation_time} style={{ left: progress + '%' }}>
								{convertTime(process)}
							</p>
						) : null}
						<div className={style.player_navigation_bar} style={{ width: `${progress}%` }}></div>
						<p className={style.player_navigation_full_time}>{convertTime(fullTime)}</p>
					</div>
				</div>
			</div>
			<Divider style={{ 'borderColor': '#D9DEE0', 'margin-bottom': '1.31rem' }} />
			<div className={style.player_buttons}>
				<IconButton onClick={skipBack} className={style.player_buttons_button} disabled={!ready}>
					<i className="fa-solid fa-backward-step" />
				</IconButton>
				<IconButton onClick={back_15} className={style.player_buttons_button} disabled={!ready}>
					<i className="fa-solid fa-undo-alt" />
				</IconButton>
				<IconButton onClick={handlePlayPause} className={style.player_buttons_button} disabled={!ready}>
					{isPlaying ? <i className="fa-solid fa-pause" /> : <i className="fa-solid fa-play" />}
				</IconButton>
				<IconButton onClick={forward_15} className={style.player_buttons_button} disabled={!ready}>
					<i className="fa-solid fa-redo-alt" />
				</IconButton>
				<IconButton onClick={skipForward} className={style.player_buttons_button} disabled={!ready}>
					<i className="fa-solid fa-forward-step" />
				</IconButton>
			</div>
		</div>
	);
};

export default ClientPlayer;

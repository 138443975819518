import React, { useEffect, useState } from 'react';
import AvatarInput from '../../Form/AvatarInput/AvatarInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm} from 'react-hook-form';
import style from './EditProfile.module.scss';
import Button from '../../Button/Button';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';
import AuthService from '../../../service/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import InputField from '../../Form/InputField/InputField';
import { setUser } from '../../../store/actions/userAction';

const editProfileSchema = yup.object().shape({
	email: yup.string().email('Please enter a valid email address.').required('Please enter a valid email address.'),
	name: yup
		.string()
		.matches(/^([^0-9-!$%^]*)$/, 'Name should not contain numbers')
		.required('Please enter your full name.')
		.test('trimSpaces', 'Please enter your full name.', (value) => value?.trim().length > 0),
	phone: yup.string().matches(/^\+[0-9]{0,3}[-\s.]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, 'Please enter a valid phone number').required('Please enter a valid phone number'),
	license: yup.string().required('Please enter a valid Real Estate license.'),
	brokerage: yup.string().test('trimSpaces', 'Please enter your brokerage name.', (value) => value?.trim().length > 0)
});

const EditProfile = () => {
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const [image, setImage] = useState(null);
	const [loading, setLoading] = useState(false);
	const { handleSubmit, register, formState, reset, setValue } = useForm({
		defaultValues: user?.userData,
		resolver: yupResolver(editProfileSchema),
		mode: 'onBlur',
	});
	const { errors } = formState;

	const handleCancel = () => {
		reset({
			name: user?.userData?.name,
			email: user?.userData?.email,
			phone: user?.userData?.phone,
			license: user?.userData?.license,
			brokerage: user?.userData?.brokerage,
		});
		setImage(user?.userData?.userImage);
	};
	useEffect(() => {
		if (user?.userData) {
			reset({
				name: user?.userData?.name,
				email: user?.userData?.email,
				phone: user?.userData?.phone,
				license: user?.userData?.license,
				brokerage: user?.userData?.brokerage,
			});
			setImage(user?.userData?.userImage);
		}
	}, [user, reset]);

	const editProfile = (data) => {
		let body = {
			name: data.name,
			email: data.email,
			phone: data.phone,
			license: data.license,
			brokerage: data.brokerage,
		};
		if(user?.userData?.role!=='Admin') delete body.brokerage;
		setLoading(true);
		let prom = UserService.editProfile(body, image);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
						setImage(null);
					})
					.catch((err) => {
						console.error(err);
					});
				setLoading(false);
				return 'Profile updated successfully';
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	return (
		<form onSubmit={handleSubmit(editProfile)} disabled={loading} className={style.profile}>
			<div className={style.profile_mb}>
				<AvatarInput
					imageUrl={image}
					onImageUpload={setImage}
					onDeleteImage={() => setImage(null)}
					name={user ? user.userData.name : 'Tourme Homes'}
				></AvatarInput>
			</div>
			<InputField
				width='100'
				name="name"
				placeholder="Full Name"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="phone"
				inputType="phone"
				placeholder="Phone Number"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="email"
				placeholder="Email"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="brokerage"
				placeholder="Brokerage"
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				width='100'
				name="license"
				placeholder="License"
				register = {register}
				error= {errors}
				setValue={setValue}
				prefix={<p className={style.profile_prefix}>Real Estate License:</p>}
			/>
			<div className={style.profile_action}>
				<Button style="text" iconName="save" type='submit'>Save</Button>
			</div>
		</form>
	);
};
export default EditProfile;

import React, { createContext } from 'react';
import { useAudio } from '../Hook/useAudio';

const AudioContext = createContext();

const AudioProvider = ({ children }) => {
	const {
		cleanAudio,
		audioSections,
		isLoading,
		currentSection,
		skipBack,
		skipForward,
		handlePlayPause,
		handleClickTime,
		isAudioReady,
		process,
		progress,
		handlePlayPauseInSmallPlayer,
		showSectionOrder,
		fullTime,
		textRef,
		containerRef,
		waveSurferRef,
		handleAddSection,
		handleEditSection,
		handleDelete,
		handleOrder,
		setAudioSections,
		isPlayingAudio,
		skip15
	} = useAudio();

	return (
		<AudioContext.Provider
			value={{
				cleanAudio,
				audioSections,
				isLoading,
				currentSection,
				skipBack,
				skipForward,
				handlePlayPause,
				handleClickTime,
				setAudioSections,
				isAudioReady,
				handlePlayPauseInSmallPlayer,
				process,
				progress,
				showSectionOrder,
				fullTime,
				textRef,
				containerRef,
				waveSurferRef,
				handleAddSection,
				handleEditSection,
				handleDelete,
				handleOrder,
				isPlayingAudio,
				skip15
			}}
		>
			{children}
		</AudioContext.Provider>
	);
};

export { AudioContext, AudioProvider };

import React, { createContext, useState, useContext } from 'react';
import UserService from '../service/user.service';

const CreateTourContext = createContext();

export const CreateTourProvider = ({ children }) => {
	const [agentData, setAgentData] = useState({
		license: '',
		brokerage: '',
		phone: null
	});
	const [tourId, setTourId] = useState(null);
	const [sections, setSections] = useState([]);
	const [formData, setFormData] = useState({
		adStreet: '',
		apartment: '',
		city: '',
		state: '',
		zipCode: '',
		feets: '',
		yearBuilt: '',
		price: null,
		beds: null,
		baths: null,
		tourImages: [],
	});
	const setFormValues = (values) => {
		setFormData((prev) => ({
			...prev,
			...values,
		}));
	};

	const setAgentValues = (values) => {
		setAgentData((prev) => ({
			...prev,
			...values,
		}));
	};
	const createFullTour = () => {
		return UserService.createFullTour(formData, sections).then((res) => {
			setFormData({
				mlsCode: '',
				adStreet: '',
				apartment: '',
				city: '',
				state: '',
				zipCode: '',
				feets: '',
				yearBuilt: '',
				price: null,
				beds: 0,
				baths: 0,
				tourImages: [],
			});
			setSections([]);
			return res;
		})
			.catch((err) => {
				console.error(err);
				throw err;
			});
	};
	const createNewTour = () => {
		return UserService.createTour(formData).then((res) => {
			setFormData({
				mlsCode: '',
				adStreet: '',
				apartment: '',
				city: '',
				state: '',
				zipCode: '',
				feets: '',
				yearBuilt: '',
				price: null,
				beds: 0,
				baths: 0,
				tourImages: [],
			});
			setSections([]);
			return res;
		})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<CreateTourContext.Provider
			value={{
				tourId,
				createFullTour,
				createNewTour,
				formData,
				setFormValues,
				agentData,
				setAgentValues,
				sections,
				setSections,
			}}
		>
			{children}
		</CreateTourContext.Provider>
	);
};

export const useCreateForm = () => useContext(CreateTourContext);

import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import trash from '../../assets/icons/trash.svg';
import image from '../../assets/icons/image.svg';
import 'swiper/swiper.min.css';
import style from './InputImage.module.scss';
const InputImage = ({images, setImages}) => {
	const fileInputRef = useRef(null);
	const handleImageUpload = (event) => {
		const files = Array.from(event.target.files);
		const imageData = [];
		files.forEach(element => {
			const reader = new FileReader();
    
			reader.onload = () => {
				const imageData = reader.result;
				setImages((prevState) => [...prevState, imageData]);
			};
    
			if (element) {
				reader.readAsDataURL(element);
			}
		});
	};
	const deleteImage = (index) => {
		if(images[index].imageUrl) {
			const newImages = [...images];
			newImages[index].deleted = true;
			setImages(newImages);
			return;
		}
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
	};
	const handleImageClick = () => {
		fileInputRef.current.click();
	};
	return (
		<div className={style.inputImage}>
			<Swiper 
				className={style.inputImage_swiper}
				slidesPerView={1}
			>
				{images.filter((i) => !i.deleted).map((image, index) => (
					<SwiperSlide key={index} className={style.inputImage_swiper_slide}>
						<button className={style.inputImage_swiper_slide_delete} onClick={() => deleteImage(index)}><img src={trash} alt="" /></button>
						<img className={style.inputImage_swiper_slide_image} src={image.imageUrl? image.imageUrl : image} alt={`Image ${index}`} />
					</SwiperSlide>
				))}
				<SwiperSlide>
					<div className={style.inputImage_swiper_input} onClick={handleImageClick}>
						<input ref={fileInputRef} className={style.inputImage_swiper_input_field} type="file" accept=".jpg,.jpeg,.png" onChange={handleImageUpload} />
						<img src={image} alt="Add Photo Icon" />
						<span>Add Photo</span>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};

export default InputImage;

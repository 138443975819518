import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import InputField from '../../../Form/InputField/InputField';

import { yupResolver } from '@hookform/resolvers/yup';
import FormWrapperLayout from '../../FormWrapperLayout/FormWrapperLayout';
import { useCreateForm } from '../../../../context/CreateTourContext';
import Select from '../../../Select/Select';
import {ReactComponent as Bed} from '../../../../assets/icons/bed.svg';
import {ReactComponent as Bath} from '../../../../assets/icons/bath.svg';
import { useNavigate } from 'react-router-dom';
import style from '../../CreateTourV2.module.scss';
import { schema, validateData } from '../../../../helpers/useSchema';


const PropertyInformationForm = () => {
	const navigate = useNavigate();
	const { formData, setFormValues } = useCreateForm();
	const { register, formState, handleSubmit, setValue } = useForm({
		defaultValues: {
			feets: formData.feets,
			yearBuilt: formData.yearBuilt,
			price: formData.price,
			beds: formData.beds,
			baths: formData.baths,
		},
		resolver: yupResolver(schema.propertyInformation),
		mode: 'onBlur',
	});

	const { errors } = formState;

	const currentYear = new Date().getFullYear();
	const startYear = 1800;
	const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {let y = currentYear - index; return { value: y, label: y};});

	const onSubmit = (data) => {
		setFormValues({ ...data, price: parseInt(data.price.replace(/[$,]*/g, '')), feets:parseInt(data.feets.replace(/[$,]*/g, ''))});
		navigate('/create/gallery');
	};
	useEffect(() => {
		validateData(schema.propertyAddress, formData)
			.then((res) => {
				if(!res.success) navigate('/create/propertyAddress');
			})
			.catch((err) => {
				console.error(err);
			});
	}, [formData]);
	
	return (
		<> 
			<FormWrapperLayout formTitle="Property Information" next={handleSubmit(onSubmit)} back={() => navigate('/create/propertyAddress')} >
				<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
					<InputField
						name="price"
						placeholder="Price"
						register = {register}
						error= {errors}
						setValue={setValue}
						inputType='price'
					/>
					<div className={style.form_row}>
						<InputField
							name="feets"
							placeholder="Sq Ft"
							register = {register}
							error= {errors}
							setValue={setValue}
							inputType='number'
						/>
						<Select name="yearBuilt" register={register} error={errors}>
							<option value="" disabled>
							Year Built
							</option>
							{years.map((year) => (
								<option key={year.value} value={year.value}>
									{year.label}
								</option>
							))}</Select>
					</div>
					<div className={style.form_row}>
						<InputField
							name="beds"
							placeholder="-"
							register = {register}
							error= {errors}
							setValue={setValue}
							inputType='decimal'

							prefix={<Bed/>}
						/>
						<InputField
							name="baths"
							placeholder="-"
							register = {register}
							error= {errors}
							setValue={setValue}
							inputType='decimal'
							prefix={<Bath/>}
						/>
					</div>
				</form>
			</FormWrapperLayout>
			{/* <DevTool control={control} /> */}
		</>
	);
};

export default PropertyInformationForm;

import style from '../ShareModal.module.scss';
import Button from '../../Button/Button';
import flyer_image from '../../../assets/images/flyer.png';
import Flayer from '../../Flayer/Flayer';
import html2canvas from 'html2canvas';
import { useRef } from 'react';

const ShareFlyer = ({setStep, header=true}) => {
	const flyerRef = useRef(null);
	const downloadFlyer = () => {
		const element = flyerRef.current;
		html2canvas(element, { scale: 4 }).then((canvas) => {
			const link = document.createElement('a');
			link.href = canvas.toDataURL('image/png');
			link.download = 'flyer.png';
			link.click();
		});
	};
    
	return (
		<div className={style.shareModal}>
			<div>
				{header && <h3 className={style.shareModal_title}>
					<div className={style.shareModal_title_back}>
						<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
					</div>Create Flyer</h3>}
				<p className={style.shareModal_description}>Please this flyer at your property for visitors to access the tour.</p>
			</div>
			<img src={flyer_image} alt="" />
			<div className={style.shareModal_flyer}>
				<Flayer flyerRef={flyerRef} />
			</div>
			<Button style='text' iconName="download" onClick={downloadFlyer}>Download</Button>
		</div>
	);
};

export default ShareFlyer;
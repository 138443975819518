import style from './StateChip.module.scss';
const StateChip = ({ state, onClick }) => {
	return (
		<div className={style.chip} onClick={onClick}>
			<p className={state === 'Active' ? style.active : style.disabled}>{state}</p>
		</div>
	);
};

export default StateChip;

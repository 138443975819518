import { useState } from 'react';
import { toast } from 'sonner';
import InputField from '../Form/InputField/InputField';
import ModalBox from '../ModalBox/ModalBox';
import Button from '../Button/Button';
import UserService from '../../service/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/actions/userAction';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import style from './InvitationButton.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const invitationResolver = yup.object().shape({
	invite: yup.string().required('This field is required')
});

const InvitationButton = () => {
	const { register, formState, handleSubmit, setValue } = useForm({
		resolver: yupResolver(invitationResolver),
		mode: 'onBlur',
	});
	const { errors } = formState;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);

	const onSubmit = (data) => {
		setOpen(false);
		let prom = UserService.assignInvite(data.invite);
		toast.promise(prom, {
			loading: 'Validating invitation...',
			success: (data) => {
				setOpen(false);
				dispatch(setUser(data.data));
				navigate('/dashboard');
				return 'Invitation validated successfully';
			},
			error: (err) => {
				return err.response.data.message ?? err;
			},
		});
	};
	// 
	return (
		<>
			<div className={style.invitationButton}>
				<Button
					size='sm'
					style="text"
					iconName="joinTeam"
					type="button"
					onClick={handleOpen}
				>Join Team</Button>
			</div>
			<ModalBox isOpen={open} close={() => setOpen(false)} size='sm'>
				<form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', 'flexDirection': 'column', justifyContent:'space-between', height:'100%' }}>
					<div className={style.invitationButton_modal_content}>
						<div className="">
							<h3 className={style.invitationButton_modal_content_title}>Activate License</h3>
							<p className={style.invitationButton_modal_content_subtitle}>Input the invitation code provide by the administrator to join a brokerage account.</p>
						</div>
						<InputField
							name="invite"
							placeholder="Invitation Code"
							register = {register}
							error= {errors}
							setValue={setValue}
						/>
						<Button
							style="text"
							iconName="check"
							type="submit"
						>Activate</Button>
					</div>
				</form>
			</ModalBox>
		</>
	);    
};

export default InvitationButton;
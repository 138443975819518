import createAccountImage from '../../assets/formsImages/createAccount.png';
import LogInForm from '../../components/LogInForm/LogInForm';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AuthService from '../../service/auth.service';
import { AccountFormsLayout } from '../../components/AccountFormsLayout/AccountFormsLayout';

const LogIn = () => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const handleSetUser = (data) => {
		dispatch(setUser(data));
		if (data.auth) navigate('/dashboard', { replace: true });
		else navigate('/checkout', { replace: true });
	};
	useEffect(() => {
		if (user?.auth) {
			navigate('/dashboard', { replace: true });
		} else if (user?.auth === false) {
			AuthService.getCurrentUser()
				.then((res) => {
					handleSetUser(res.data.data);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [user, navigate]);

	const handleNavigatiion = () => navigate('/signin');

	return (
		<>
			<AccountFormsLayout
				formTitle="Welcome Back!"
				formText="Please enter your details."
				btnText="Don't have an account?"
				btnboldText="Sign Up"
				image={createAccountImage}
				onClick={handleNavigatiion}
			>
				<LogInForm setUser={handleSetUser} />
			</AccountFormsLayout>
		</>
	);
};

export default LogIn;

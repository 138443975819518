import * as yup from 'yup';

export const validateData = async (schema, data) => {
	try {
		await schema.validate(data, { abortEarly: false });
		return { success: true, errors: {} };
	} catch (error) {
		const validationErrors = {};
		console.log(error);
		error.inner.forEach((err) => {
			validationErrors[err.path] = err.message;
		});
		return { success: false, errors: validationErrors };
	}
};

export const schema = {
	propertyAddress: yup.object().shape({
		adStreet: yup
			.string()
			.matches(/^[^-!¡$%^&*()_+|~=`°¨#¬{}[\]:";<>¿?]+$/, 'This field is required.')
			.test('trimSpaces', 'This field is required.', (value) => value?.trim().length > 0)
			.required('This field is required'),
		apartment: yup.string().optional(),
		city: yup.string().required('This field is required'),
		state: yup.string().required('This field is required'),
		zipCode: yup.string().required('This field is required'),
		mlsCode: yup.number().typeError('Please enter a valid MLS number.').required('Please enter a valid MLS number.'),
	}),
	propertyInformation: yup.object().shape({
		feets: yup.string().required('This field is required'),
		yearBuilt: yup.string().required('This field is required').max(4, '4 characters are allowed'),
		price: yup.string().required('This field is required'),
		beds: yup.number().moreThan(0, 'This field is required').required('This field is required'),
		baths: yup.number().moreThan(0, 'This field is required').required('This field is required'),
	}),
	sections: yup.object().shape({
		title: yup.string().test('trimSpaces', 'Must enter a valid text', (value) => value?.trim().length > 0).required('Section title is required'),
		script: yup.string().test('trimSpaces', 'Must enter a valid text', (value) => value?.trim().length > 0).required('Section script is required'),
	})
};
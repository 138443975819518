export const tourReducer = (state = null, action) => {
	switch (action.type) {
	case 'SET_TOUR':
		return action.payload;
	case 'SET_SECTIONS':
		return {
			...state,
			sections: action.payload,
		};
	default:
		return state;
	}
};

import InputField from '../Form/InputField/InputField';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import ButtonLoading from '../Form/LoadingButton/LoadingButton';
import CheckBox from '../Form/CheckBox/CheckBox';
import AuthService from '../../service/auth.service';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const signInResolver = yup.object().shape({
	name: yup.string().required('Please enter your full name.'),
	email: yup.string().required('Please enter your email.').email('Please enter a valid email address.'),
	password: yup
		.string()
		.required('Please enter valid password.')
		.min(6, 'Password must be at least 6 characters'),
	confirmPassword: yup
		.string()
		.required('This field is required')
		.oneOf([yup.ref('password'), null], 'Please make sure passwords match.'),
	terms: yup.boolean().oneOf([true], 'Terms must be accepted'),
});
const SignInForm = ({ setUser }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { register, formState, handleSubmit, setValue } = useForm({
		resolver: yupResolver(signInResolver),
		mode: 'onBlur',
	});
	const { errors } = formState;
	const onSubmit = (data) => {
		setLoading(true);
		let prom = AuthService.register(data);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (res) => {
				const userData = res.data.data;
				setUser(userData);
				setLoading(false);
				navigate('/checkout', { replace: true });
				return 'Welcome ' + userData.userData.name;
			},
			error: (err) => {
				setLoading(false);
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{width:'100%',display: 'flex', 'flexDirection': 'column', gap: '0.25rem' }}>
			<InputField
				name="name"
				placeholder="Full Name"
				disabled={loading}
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				name="email"
				placeholder="Email Address"
				disabled={loading}
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				name="password"
				inputType="password"
				placeholder="Password"
				disabled={loading}
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<InputField
				name="confirmPassword"
				inputType="password"
				placeholder="Confirm Password"
				disabled={loading}
				register = {register}
				error= {errors}
				setValue={setValue}
			/>
			<CheckBox
				name="terms"
				validation={{ required: 'Terms is required' }}
				register = {register}
				error= {errors}
				setValue={setValue}
			>
				<label htmlFor="">
					{
						<>
						I agree to the{' '}
							<a
								href="https://www.tourmehomes.com/policies/terms-of-service"
								target="_blank"
								rel="noreferrer"
							>
							terms and conditions
							</a>{' '}
						</>
					}
				</label>
			</CheckBox>
			<ButtonLoading label="Create Account" type="submit" loading={loading}></ButtonLoading>
		</form>
	);
};

export default SignInForm;

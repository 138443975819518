import axios from 'axios';
import bcrypt from 'bcryptjs';
const API_URL =
	process.env.REACT_APP_ENV !== 'dev'
		? 'https://server.tourmehomes.com/api/auth/'
		: 'http://localhost:3001/api/auth/';
// const API_URL = 'https://server.tourmehomes.com/api/auth/'
const instance = axios.create({
	withCredentials: true,
});
const register = ({ name, password, email }) => {
	const pass = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'signup', {
			name,
			password: pass,
			email,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const login = (email, password) => {
	const pass = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'signin', {
			email,
			password: pass,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			console.error(error);
			throw error;
		});
};
const logout = () => {
	return instance
		.post(API_URL + 'signout')
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			throw error;
		});
};

const getCurrentUser = () => {
	return instance
		.get(API_URL + 'whoiam')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};

const checkout = (data, userId, email, quantity, multi_agent) => {
	return instance
		.post(API_URL + 'create-checkout-session', { plan_id: data, userId: userId, email, quantity, multi_agent })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const confirmUser = () => {
	return instance
		.get(API_URL + 'confirm-user')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const deactivate = (password) => {
	const pass = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'deactivate', { password:pass })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const activate = () => {
	return instance
		.post(API_URL + 'activate')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const deleteAccount = (password) => {
	const pass = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'delete', { password:pass})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const cancelDeletion = () => {
	return instance
		.post(API_URL + 'delete/cancel')
		.then((response) => {
			return response;
		})
		.catch((error) => {
			throw error;
		});
};
const AuthService = {
	confirmUser,
	register,
	login,
	logout,
	getCurrentUser,
	checkout,
	deactivate,
	activate,
	deleteAccount,
	cancelDeletion
};
export default AuthService;

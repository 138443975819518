import style from './LoadingButton.module.scss';
import LoadingButton from '@mui/lab/LoadingButton';
const ButtonLoading = ({ label, loading, type, ...rest }) => {
	return (
		<LoadingButton
			loading={loading}
			variant="contained"
			className={style.loadingButton}
			type={type}
			{...rest}
			sx={{ fontWeight: 500 }}
		>
			<span>{label}</span>
		</LoadingButton>
	);
};

export default ButtonLoading;

import style from './NavBar.module.scss';
import logo from '../../assets/images/Full Black_Yellow.png';
import { useEffect, useState } from 'react';
import AuthService from '../../service/auth.service';
import { setUser } from '../../store/actions/userAction';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import Avatar from '../Avatar/Avatar';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const NavBar = ({settings}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const tour = useSelector((state) => state.tour);
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleLogOut = () => {
		AuthService.logout()
			.then((res) => {
				dispatch(setUser(null));
				navigate('/login');
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
		navigate('/settings/profile');
	};
	return (
	//Make this navbar component to converto to mobile view
		<div className={style.navbar}>
			<Link to="/dashboard" className={style.navBar_logo}>
				<img src={logo} alt="" className={style.navbar_logo_img} />
			</Link>
			<div className={style.navbar_actions}>
				<Tooltip title="Open Profile">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						<Avatar image={user?.userData?.userImage?.imageUrl} name={user?.userData?.name} size={{width:'37px', height:'37px'}}/>
					</IconButton>
				</Tooltip>
				{
					settings ? 
						<Tooltip title="Open Settings">
							<IconButton onClick={() => navigate('/settings/menu')} sx={{ p: 0 }}>
								<SettingsIcon style={{width:'24px'}}></SettingsIcon>
							</IconButton>
						</Tooltip>
						:
						<Tooltip title="Log Out">
							<IconButton onClick={handleLogOut} sx={{ p: 0 }}>
								<LogOutIcon  style={{width:'24px'}}></LogOutIcon>
							</IconButton>
						</Tooltip>
				}
			</div>
		</div>
	);
};

export default NavBar;

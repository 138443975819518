import React, { useState } from 'react';
import style from '../EditTour.module.scss';
import Button from '../../Button/Button';
import check_on from '../../../assets/icons/check_on.svg';
import check_off from '../../../assets/icons/check_off.svg';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import question from '../../../assets/images/question.svg';

const EditStatus = ({ tourState, tourId, updateTour, setStep }) => {
	const navigate = useNavigate();
	const updateState = (state) =>{
		const prom = UserService.changeTourState(tourId, state);
		toast.promise(prom, {
			loading: 'Updating tour...',
			success: (res) => {
				updateTour();
				setConfirm(false);
				return 'Tour updated';
			},
			error: (err) => {
				return 'Error updating tour';
			},
		});
	};

	const states = [
		{
			name:'active',
			label: 'Active',
			description: 'Your tour is accessible to agents and potential buyers.',
			action: () => updateState('Active')
		},
		{
			name:'inactive',
			label: 'Inactive',
			description: 'The tour won’t be accessible. No data will be deleted.',
			action: () => updateState('Inactive')
		},
		{
			name:'delete',
			label: 'Delete',
			description: 'The data will be deleted and you won’t be able to recover it.',
			action: () =>{
				const prom = UserService.deleteTour(tourId);
				toast.promise(prom, {
					loading: 'Deleting tour...',
					success: (res) => {
						navigate('/dashboard');
						return 'Tour deleted';
					},
					error: (err) => {
						return 'Error deleting tour';
					},
				});
			}
		}
	];
	const [state, setState] = useState(states.find(s => s.label == tourState));
	const [confirm, setConfirm] = useState(false);
	const isActive = (s) => {
		return state.name == s.name;
	};
	return (
		<div className={`${style.editModal} ${style.editModal_link}`}>
			<h3 className={style.editModal_title}>
				<div className={style.editModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Status</h3>
			{
				confirm ? (
					<>
						<div className={style.editModal_confirm}>
							<img src={question} alt="" />
							<p>Are you sure you want to <span>{state.label}</span> this tour?</p>
						</div>
						<Button style='text' iconPosition='end' iconName="line_arrow_right" onClick={state.action}>Continue</Button>
					</>
				)
					:(
						<>
							
							<div className={style.editModal_status}>
								{
									states.map(s => (
										<button key={s.name} className={`${style.editModal_status_item} ${isActive(s) && style.editModal_status_item_active}`} onClick={() => setState(s)}>
											<div className={style.editModal_status_item_header}>
												<h3 className={style.editModal_status_item_header_title}>{s.label}</h3>
												<img src={isActive(s)?check_on : check_off} alt="" />
											</div>
											<p className={style.editModal_status_item_description}>{s.description}</p>
										</button>
									))
								}
							</div>
							<Button style='text' iconPosition='end' iconName="line_arrow_right" onClick={() => setConfirm(true)}>Continue</Button>
						</>
					)
			}
		</div>
	);
};

export default EditStatus;
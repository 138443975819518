import React, { useState, useEffect } from 'react';
import style from './WelcomeView.module.scss';
import CheckBox from '../../Form/CheckBox/CheckBox';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import { useParams, useNavigate } from 'react-router-dom';
import ClientService from '../../../service/client.service';
import Button from '../../Button/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../Form/InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'sonner';
import NavBar from '../ClientNavBar/ClientNavBar';



const welcomeFormSchema = yup.object().shape({
	email: yup.string().email().required('This field is required'),
	name: yup
		.string()
		.matches(/^([^0-9-!$%^]*)$/, 'Name should not contain numbers')
		.required('This field is required')
		.test('trimSpaces', 'Must be a valid text', (value) => value?.trim().length > 0),
	phone: yup.string().required('This field is required'),
	terms: yup.boolean().oneOf([true], 'You need to accept the terms and conditions'),
});

const WelcomeView = () => {
	const [notFound, setNotFound] = useState(true);
	const [loading, setLoading] = useState(true);
	const [client, setClient] = useState('Agent');
	const { id } = useParams();
	const navigate = useNavigate();
	const { handleSubmit, register, formState, setValue } = useForm({
		defaultValues: {
			name: '',
			email: '',
			phone: '',
		},
		resolver: yupResolver(welcomeFormSchema),
		mode: 'onChange',
	});
	const { errors } = formState;

	const onSubmit = (data) => {
		let prom = ClientService.countPlay(id, {
			phone: data.phone,
			email: data.email,
			name: data.name,
			role: client,
		});
		toast.promise(prom, {
			loading: 'Loading',
			success: (res) => {
				navigate(`/tour/${id}`);
				return 'Success';
			},
			error: (err) => {
				console.error(err);
				return 'Error while saving tour data';
			},
		});
	};

	const handleResetAndContinue = () => {
		setClient('Anonymous');
		onSubmit({
			name: 'Anonymous',
			email: '-',
			phone: '-'
		});
	};

	useEffect(() => {
		ClientService.checkTourActive(id)
			.then((res) => {
				if (res.data.active) {
					setLoading(false);
					return setNotFound(false);
				}
				setLoading(false);
				setNotFound(true);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id, notFound]);

	return (
		<div className={style.welcomeView}>
			<NavBar actions={false}></NavBar>
			<div className={style.welcomeView_form}>
				<h2 className={style.welcomeView_form_header}>Welcome to <span>TourMe.</span></h2>
				{notFound ? (
					<div>
						<p className={style.welcomeView_form_text}>Sorry! This tour is not available</p>
					</div>
				) : (
					<>
						<p className={style.welcomeView_form_text}>Please fill out information to get started</p>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={style.welcomeView_form_fields}>

								<div className={style.welcomeView_form_client}>
									<button type="button" className={`${style.welcomeView_form_client_button} ${client==='Agent' && style.welcomeView_form_client_button_active}`} onClick={() => setClient('Agent')}>Agent</button>
									<button type="button" className={`${style.welcomeView_form_client_button} ${client==='Buyer' && style.welcomeView_form_client_button_active}`} onClick={() => setClient('Buyer')}>Buyer</button>
								</div>
								<InputField
									width='100'
									name="name"
									placeholder="Full Name"
									register = {register}
									error= {errors}
									setValue={setValue}
								/>
								<InputField
									width='100'
									name="phone"
									inputType='phone'
									placeholder="Phone Number"
									register = {register}
									error= {errors}
									setValue={setValue}
								/>
								<InputField
									width='100'
									name="email"
									placeholder="Email Address"
									register = {register}
									error= {errors}
									setValue={setValue}
								/>
								<CheckBox id='acceptTerms' register={register} error={errors} name='terms'>
									<label htmlFor="acceptTerms">
										I agree to the <a href="/terms" target="_blank">terms and conditions.</a>
									</label>
								</CheckBox>
							</div>
							<div className={style.welcomeView_form_buttons}>
								<Button size='md' width="100" style="filled" color="primary" onClick={handleSubmit(onSubmit)}>Start Tour</Button>
								<Button size="sm" style="underline" type="button" onClick={handleResetAndContinue}>Skip</Button>
							</div>
						</form>
					</>
				)}
			</div>
			{loading && (
				<div className={style.welcomeView_loading}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

export default WelcomeView;

import React from 'react';
import {ReactComponent as CircleCheck} from '../../assets/icons/circle-check.svg';
import {ReactComponent as Cross} from '../../assets/icons/cross.svg';
import {ReactComponent as JoinTeam} from '../../assets/icons/joinTeam.svg';
import {ReactComponent as Send} from '../../assets/icons/send.svg';
import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import {ReactComponent as Left_line_arrow} from '../../assets/icons/left-line-arrow.svg';
import {ReactComponent as Headset} from '../../assets/icons/headset.svg';
import {ReactComponent as ShareArrow} from '../../assets/icons/share_arrow.svg';
import {ReactComponent as Save} from '../../assets/icons/save.svg';
import {ReactComponent as Qr} from '../../assets/icons/qr.svg';
import {ReactComponent as Flyer} from '../../assets/icons/flyer.svg';
import {ReactComponent as LogOut} from '../../assets/icons/logout.svg';
import {ReactComponent as Email} from '../../assets/icons/email.svg';
import {ReactComponent as Card} from '../../assets/icons/card.svg';
import {ReactComponent as Link} from '../../assets/icons/link.svg';
import {ReactComponent as Line_arrow_right} from '../../assets/icons/right-line-arrow.svg';
import {ReactComponent as Download} from '../../assets/icons/download.svg';
import {ReactComponent as Open} from '../../assets/icons/open.svg';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';



const Button = ({ width, iconPosition='start', color, style, iconName,size='md', onClick, children, type, ...props }) => {
	const iconComponent = () => {
		if (iconName) {
			if (iconName === 'check') {
				return <CircleCheck/>;
			} else if (iconName === 'cross') {
				return <Cross/>;
			} else if (iconName === 'joinTeam') {
				return <JoinTeam/>;
			} else if (iconName === 'send') {
				return <Send/>;
			} else if (iconName === 'plus') {
				return <Plus/>;
			} else if (iconName === 'left_line_arrow') {
				return <Left_line_arrow/>;
			} else if (iconName === 'headset') {
				return <Headset/>;
			} else if (iconName === 'shareArrow') {
				return <ShareArrow/>;
			} else if (iconName === 'save') {
				return <Save/>;
			} else if (iconName === 'qr') {
				return <Qr/>;
			} else if (iconName === 'flyer') {
				return <Flyer/>;
			} else if (iconName === 'email') {
				return <Email/>;
			} else if (iconName === 'link') {
				return <Link/>;
			} else if (iconName === 'line_arrow_right') {
				return <Line_arrow_right/>;
			} else if (iconName === 'download') {
				return <Download/>;
			} else if (iconName === 'open') {
				return <Open/>;
			} else if (iconName === 'logout') {
				return <LogOut/>;
			} else if(iconName === 'card') {
				return <Card/>;
			}
		}
	};

	return (
		<button {...props} type={type} className={`${styles.button} ${styles[size]} ${styles[style]} ${color && styles[`${style}_${color}`]} ${width && styles[`button_width_${width}`]} ${styles[`button_icon_${iconPosition}`]}`} onClick={onClick}>
			{
				iconName && 
				iconComponent()
			}
			{children}
		</button>
	);
};

Button.propTypes = {
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning']),
	style: PropTypes.oneOf(['filled', 'dashed', 'bordered', 'text']).isRequired,
	iconName: PropTypes.string,
	type: PropTypes.string,
	onClick: PropTypes.func,
	children: PropTypes.node.isRequired,
};

export default Button;

import React, { useContext } from 'react';
import styles from './Sections.module.scss';
import { useCreateForm } from '../../../../context/CreateTourContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormWrapperLayout from '../../FormWrapperLayout/FormWrapperLayout';
import { TourDataContext } from '../../../../context/TourDataContext';
import { useNavigate } from 'react-router-dom';
import headset from '../../../../assets/icons/headset.svg';
import drag from '../../../../assets/icons/drag.svg';
import pencil from '../../../../assets/icons/pencil.svg';
import trash from '../../../../assets/icons/trash.svg';
import { toast } from 'sonner';
import Button from '../../../Button/Button';
import { validateData, schema } from '../../../../helpers/useSchema';
import { CircularProgress } from '@mui/material';

const Sections = () => {
	const navigate = useNavigate();
	const { setSingleTourInfo } = useContext(TourDataContext);
	const { formData, sections, setSections, createFullTour } = useCreateForm();
	const [loading, setLoading] = React.useState(false);


	const validateInformation = async () => {
		return validateData(schema.propertyAddress, formData)
			.then((res) => {
				if(!res.success){
					navigate('/create/propertyAddress');
					return false;
				}
				return validateData(schema.propertyInformation, formData)
					.then((res) => {
						if(!res.success) {
							navigate('/create/propertyInfo');
							return false;
						}
						return true;
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const saveAndExit = () => {
		validateInformation().then(val => {
			if (val) {
				setLoading(true);
				let prom = createFullTour();
				toast.promise(prom, {
					loading: 'Creating tour...',
					success: (res) => {
						setLoading(false);
						setSingleTourInfo(res.randomId);
						navigate('/create/nextSteps');
						return 'Tour created successfully!';
					},
					error: (err) => {
						setLoading(false);
						return err.response.data?.message;
					},
				});
			}
		});
	};
	const handleNewSection = () => {
		navigate('/create/sections/new');
	};
	const getItemStyle = (isDragging, draggableStyle) => ({
		background: isDragging ? '#DEE5EA' : '#fff',
		...draggableStyle,
	});
	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	function onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items_ = reorder(sections, result.source.index, result.destination.index);
		setSections(items_);
	}
	const deleteItem = (id) => {
		setSections(sections.filter((section) => section.id !== id));
	};
	const editItem = (id) => {
		let s = sections.filter((section) => {
			return section.id === id;
		});
		navigate('/create/sections/edit', { state: s });
	};
	return (
		<>
			<FormWrapperLayout formTitle="Add Sections" back={() => navigate('/create/gallery')} next={saveAndExit} button={sections.length>0?'Save': 'Finish Later'}>
				<div className={styles.sections}>
					<h2 className={styles.sections_header}>Let’s Create a Tour!</h2>
					<p  className={styles.sections_description} >Please refer to our <a href="https://portal.tourmehomes.com/resources/tourhome/937cdc2c-39d5-4ef7-8cd9-734dc1f7f6a0" target="_blank" rel="noreferrer">sample tour</a> for recommended sections, suggestions for transitions, and inspiration for dialogue.</p>
					{
						sections.length>0?
							<div className={styles.sections_draggableContainer}>
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{(provided, snapshot) => (
											<div className={styles.sections_draggableContainer_list} {...provided.droppableProps} ref={provided.innerRef}>
												{sections.length > 0
													? sections.map((item, index) => (
														<Draggable key={item.id} draggableId={`id_${item.id}`} index={index}>
															{(provided, snapshot) => (
																<div
																	{...provided.draggableProps}
																	ref={provided.innerRef}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided?.draggableProps?.style,
																	)}
																>
																	<div className={styles.sections_draggableArea}>
																		<img src={drag} alt="" {...provided.dragHandleProps}/>
																		<div>
																			<p>{item.title}</p>
																		</div>
																		<div className={styles.sections_draggableArea__buttons}>
																			<button
																				onClick={() => deleteItem(item.id)}
																				type="button"
																			>
																				<img src={trash} alt=""  style={{width:'24px'}}/>
																			</button>
																			<button
																				onClick={() => editItem(item.id)}
																				type="button"
																			>
																				<img src={pencil} alt="" style={{width:'24px'}}/>
																			</button>
																		</div>
																	</div>
																</div>
															)}
														</Draggable>
													))
													: ''}
												{provided.placeholder}
												<div className={styles.sections_draggableContainer_button}>
													<Button iconName="headset" style="dashed" color="secondary" onClick={handleNewSection} width='100'>Add Section</Button>
												</div>
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
							:
							<button className={styles.sections_empty_button} onClick={handleNewSection}>
								<img src={headset} alt="" />
								<p>Add Section</p>
							</button>
					}
				</div>
			</FormWrapperLayout>
			{loading && (
				<div className={styles.loading}>
					<CircularProgress />
				</div>
			)}
		</>
	);
};

export default Sections;

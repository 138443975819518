import React from 'react';
import style from './MessageBox.module.scss';
import Button from '../Button/Button';
import ModalBox from '../ModalBox/ModalBox';
import question from '../../assets/images/question.svg';



const MessageBox = ({ confirmButton, message, title, confirm, cancel, open, close, cancelButton=true }) => {
	return (
		<ModalBox isOpen={open} close={close} size='xs'>
			<div className={style.messageBox}>
				<h3 className={style.messageBox_title}>
					{title}
				</h3>
				<div className={style.messageBox_content}>
					<img src={question} alt="" />
					<p>{message}</p>
				</div>
				<Button onClick={confirm} iconName='line_arrow_right' style="text" iconPosition='end'>{confirmButton}</Button>
			</div>
		</ModalBox>
	);
};

export default MessageBox;

import { useState, useRef } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useForm, Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import LabelInput from '@mui/material/FormLabel';
import Button from '../../components/Button/Button';
import style from './CustomerSupport.module.scss';
import TextArea from '../../components/Form/TextArea/TextArea';
import { toast } from 'sonner';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import UserService from '../../service/user.service';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const normalizePhoneNumber = (value) => {
	const phoneNumber = parsePhoneNumberFromString(value, 'US');
	if (!phoneNumber) {
		return value;
	}
	return phoneNumber.formatInternational();
};

const CustomerSupport = () => {
	const formRef = useRef(null);
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const [formData, setFormData] = useState({
		name: user?.userData.name,
		email: user?.userData.email,
		phone: user?.userData.phone,
		reason: '',
		message: '',
	});
	const setFormValues = (values) => {
		setFormData((prev) => ({
			...prev,
			...values,
		}));
	};
	const {
		control,
		reset,
		register,
		formState: { isValid, errors, isDirty },
		handleSubmit,
	} = useForm({
		mode: 'all',
		defaultValues: {
			name: user?.userData.name,
			email: user?.userData.email,
			phone: user?.userData.phone,
			reason: '',
			message: '',
		},
	});
	const onSubmit = (data) => {
		const prom = UserService.sendSupportRequest(data);
		reset();
		formRef.current.reset();
		toast.promise(prom, {
			loading: 'Sending...',
			success: () => {
				navigate('/support');
				return 'Support request sent';
			},
			error: 'Error sending support request',
		});
	};
	return (
		<div className={style.customer_support}>
			<Container maxWidth="md">
				<h2 className={style.customer_support_header}>Support Request</h2>
				<form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
					<Grid container spacing={5}>
						<Grid item md={6} xs={12}>
							<FormControl sx={{ marginBottom: '1rem' }} fullWidth>
								<LabelInput>Full Name</LabelInput>
								<Controller
									name="name"
									control={control}
									rules={{ min: 0, required: 'Name is required' }}
									render={({ field }) => (
										<TextField
											error={!!errors.name}
											size="small"
											name="name"
											helperText={errors?.name?.message}
											type="text"
											onChange={(e) => {
												field.onChange(e.target.value);
												setFormValues({ ...formData, name: e.target.value });
											}}
											hiddenLabel
											placeholder=""
											sx={{ marginTop: '0.5rem' }}
											value={field.value}
										/>
									)}
								/>
							</FormControl>
							<FormControl sx={{ marginBottom: '1rem' }} fullWidth>
								<LabelInput>Email</LabelInput>
								<Controller
									name="email"
									control={control}
									rules={{ min: 0, required: 'Email is required' }}
									render={({ field }) => (
										<TextField
											error={!!errors.email}
											size="small"
											name="email"
											helperText={errors?.email?.message}
											type="email"
											onChange={(e) => {
												field.onChange(e.target.value);
												setFormValues({ ...formData, email: e.target.value });
											}}
											hiddenLabel
											placeholder=""
											sx={{ marginTop: '0.5rem' }}
											value={field.value}
										/>
									)}
								/>
							</FormControl>
							<FormControl sx={{ marginBottom: '1rem' }} fullWidth>
								<LabelInput>Phone Number</LabelInput>
								<Controller
									name="phone"
									control={control}
									rules={{ min: 0, required: 'Phone is required' }}
									render={({ field }) => (
										<TextField
											error={!!errors.phone}
											size="small"
											name="phone"
											helperText={errors?.phone?.message}
											type="tel"
											onChange={(e) => {
												field.onChange((e.target.value = normalizePhoneNumber(e.target.value)));
												setFormValues({ ...formData, phone: e.target.value });
											}}
											hiddenLabel
											placeholder=""
											sx={{ marginTop: '0.5rem' }}
											value={field.value}
										/>
									)}
								/>
							</FormControl>
							<FormControl sx={{ textAlign: 'left' }} fullWidth>
								<LabelInput>Reason for Contact</LabelInput>
								<Controller
									name="reason"
									control={control}
									rules={{ required: 'Reason is required' }}
									render={({ field }) => (
										<Select
											id="reason"
											name="reason"
											hiddenLabel
											size="small"
											fullWidth
											onChange={(e) => {
												field.onChange(e.target.value);
												setFormValues({ ...formData, reason: e.target.value });
											}}
											error={!!errors.reason}
											helperText={errors?.reason?.message}
											value={field.value}
											sx={{ marginTop: '0.5rem' }}
										>
											<MenuItem value="General">General</MenuItem>
											<MenuItem value="Technical Support">Technical Support</MenuItem>
											<MenuItem value="Billing">Billing</MenuItem>
											<MenuItem value="Other">Other</MenuItem>
										</Select>
									)}
								/>
								{errors?.reason?.message && (
									<FormHelperText error={!!errors.reason}>{errors?.reason?.message}</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextArea
								name="message"
								register={register}
								validation={{ required: 'Message is required' }}
								error={errors}
								style={{ minHeight: '308px' }}
								label="Message"
							></TextArea>
						</Grid>
					</Grid>
					<div className={style.customer_support_button}>
						<Button
							disabled={false}
							style="filled"
							color="secondary"
							type="submit"
						>Send Request</Button>
					</div>
				</form>
			</Container>
		</div>
	);
};

export default CustomerSupport;

import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import InputField from '../../../Form/InputField/InputField';
import FormWrapperLayout from '../../FormWrapperLayout/FormWrapperLayout';
import { useCreateForm } from '../../../../context/CreateTourContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from '../../../Select/Select';
import states from '../../../../helpers/usStates';
import style from '../../CreateTourV2.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../../../helpers/useSchema';

const PropertyAddressForm = () => {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const { formData, setFormValues } = useCreateForm();
	const { register, formState, handleSubmit, setValue } = useForm({
		defaultValues: {
			adStreet: formData.adStreet,
			apartment: formData.apartment,
			city: formData.city,
			state: formData.state,
			zipCode: formData.zipCode,
			mlsCode: formData.mlsCode,
		},
		resolver: yupResolver(schema.propertyAddress),
		mode: 'all',
	});

	const { errors } = formState;

	const onSubmit = (data) => {
		setFormValues({ ...data });
		navigate('/create/propertyInfo');
	};

	const goBack = () => {
		if (user?.userData?.brokerage || user?.userData?.license) {
			navigate('/create/getStarted');
		} else {
			navigate('/create/agentInfo');
		}
	};
	console.log(states);
	return (
		<FormWrapperLayout formTitle="Property Address" next={handleSubmit(onSubmit)} back={goBack}>
			<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
				<InputField
					name="mlsCode"
					placeholder="MLS #"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>

				<InputField
					name="adStreet"
					placeholder="Street"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<div className={style.form_row}>
					<InputField
						name="apartment"
						placeholder="Apt / Fl / Ste"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
					<InputField
						name="city"
						placeholder="City"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
				</div>
				<div className={style.form_row}>
					<Select name="state" register={register} error={errors}>
						<option value="" disabled>
								State
						</option>
						{states.map((state) => (
							<option key={state.value} value={state.value}>
								{state.label}
							</option>
						))}</Select>
					<InputField
						name="zipCode"
						placeholder="Zip Code"
						register = {register}
						error= {errors}
						setValue={setValue}
					/>
				</div>
			</form>
		</FormWrapperLayout>
	);
};

export default PropertyAddressForm;

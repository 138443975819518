import React, {useEffect, useState, useRef} from 'react';
import style from './LanguageSelect.module.scss';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
const LanguageSelect = ({languages, setLanguage, selected}) => {
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	const selectRef = useRef(null);
	const selectLanguage = (lang) => {
		setLanguage(lang);
		setOpen(false);
	};

	const handleClickOutside = (event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className={style.select}  ref={selectRef}>
			<button className={style.select_language} onClick={() => setOpen(!open)}>
				<h2 className={style.select_language_h2}>{selected?.local?.split('-')[1]}</h2>
			</button>
			<div className={`${style.select_dropdown} ${open && style.select_dropdown_open}`}>
				<div className={style.select_dropdown_search}>
					<SearchIcon className={style.select_dropdown_search_icon}></SearchIcon>
					<input
						type="text"
						className={style.select_dropdown_search_input}
						placeholder="Search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				<ul className={style.select_dropdown_list}>
					{languages
						.filter((lang) => lang.language.toLowerCase().includes(search.toLowerCase()))
						.map((lang) => (
							<li
								className={style.select_dropdown_list_item}
								key={lang.id}
								onClick={() => selectLanguage(lang)}
							>
								{lang.language}
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default LanguageSelect;

import style from './Flex.module.scss';
const Flex = ({ children, justify, direction, align, ...props }) => (
	<div
		className={style.flex}
		style={{ 'justifyContent': justify, 'flexDirection': direction, 'alignItems': align, ...props }}
	>
		{children}
	</div>
);

export default Flex;

import AuthService from '../../service/auth.service';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../store/actions/userAction';
import { toast } from 'sonner';
import { useEffect } from 'react';
const CheckoutSuccess = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const activate = () => {
		const prom = AuthService.activate();
		toast.promise(prom, {
			loading: 'Activating account...',
			success: (res) => {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
						navigate('/dashboard');
					})
					.catch((err) => {
						console.error(err);
					});
				return res.data.message;
			},
			error: (err) => {
				if(err.response.status === 402) {
					window.location.href = err.response.data.url;
					return 'Payment required';
				}
				return err.response.data.message;
			}
		});
	};
	return (
		<div style={{display:'flex',justifyContent:'center', alignItems:'center', flexDirection:'column', height:'90vh'}}>
			<h2>Your account has been temporarily deactivated.</h2>
			<p>You can reactivate your account any time.</p>
			<Button
				disabled={false}
				onClick={activate}
				style="filled"
				color="primary"
			>Reactivate account</Button>
		</div>
	);
};

export default CheckoutSuccess;

import resetPassImg from '../../assets/formsImages/resetPass.png';
import { AccountFormsLayout } from '../../components/AccountFormsLayout/AccountFormsLayout';
import InputField from '../../components/Form/InputField/InputField';
import ButtonLoading from '../../components/Form/LoadingButton/LoadingButton';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import UserService from '../../service/user.service';
const ChangePass = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { register, formState, handleSubmit, setValue } = useForm();
	const { errors } = formState;
	const onSubmit = (data) => {
		setLoading(true);
		let prom = UserService.updatePassword(id, data.password);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (data) => {
				setLoading(false);
				navigate('/login', { replace: true });
				return data.message;
			},
			error: (err) => {
				setLoading(false);
				return err.response.data.message;
			},
		});
	};
	return (
		<AccountFormsLayout
			formTitle="Reset your password"
			formText="Minimum length of 6 characters (it`s recommended to use 12 or more characters). At least one uppercase letter. At least one lowercase letter. At least one number"
			image={resetPassImg}
			onClick={() => navigate('/login', { replace: true })}
		>
			<form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', 'flexDirection': 'column', gap: '1rem' }}>
				<InputField
					name="password"
					type="password"
					disabled={loading}
					placeholder="New Password"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<ButtonLoading
					label="Reset Password"
					type="submit"
					loading={loading}
					disabled={loading}
				></ButtonLoading>
			</form>
		</AccountFormsLayout>
	);
};

export default ChangePass;

import style from './SectionModal.module.scss';
import ModalBox from '../ModalBox/ModalBox';
import InputField from '../Form/InputField/InputField';
import Button from '../Button/Button';
import { useForm } from 'react-hook-form';
import TextArea from '../Form/TextArea/TextArea';
import { useEffect, useState } from 'react';

const SectionModal = ({ section_id, script = '', title = '', order, open, handleClose, onSubmit }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		setValue
	} = useForm({ defaultValues: { title: title, script: script } });
	const [duration, setDuration] = useState(0);
	const [speech, setSpeech] = useState(script);
	const [submiting, setSubmiting] = useState(false);
	const submit = (data) => {
		setSubmiting(true);
		const speechLength = speech.length / (5.6 * 3);
		setDuration(speechLength);
		onSubmit(section_id, data.title, data.script, duration, order);
		handleClose();
		setSubmiting(false);
	};

	useEffect(() => {
		reset({ title: title, script: script });
	}, [open]);
	return (
		<ModalBox isOpen={open} close={handleClose}>
			<form onSubmit={handleSubmit(submit)} className={style.sectionModal}>
				<div className="">
					<h3 className={style.sectionModal_title}>Add Section</h3>
					<p className={style.sectionModal_subtitle}>
					Customize your tour and impress your clients!
					</p>
				</div>
				<div className={style.sectionModal_inputs}>
					<InputField
						disabled={submiting}
						type="text"
						register={register}
						error={errors}
						setValue={setValue}
						placeholder="Section Title"
						name="title"
						width='100'
						validation={{ required: 'Section title is required', value: title }}
					/>
					<TextArea
						disabled={submiting}
						name="script"
						register={register}
						error={errors}
						placeholder="Section Script"
						validation={{ required: 'Script required', value: script }}
						onChange={(e) => setSpeech(e.target.value)}
					></TextArea>
				</div>
				<Button iconName='save' style="text" type="submit" disabled={submiting}>Save</Button>
			</form>
		</ModalBox>
	);
};

export default SectionModal;

import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/actions/userAction';
import AuthService from '../../../service/auth.service';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../../../assets/icons/right-line-arrow.svg';
import { ReactComponent as Phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as EMail } from '../../../assets/icons/email.svg';
import { ReactComponent as Pencil } from '../../../assets/icons/pencil.svg';
import {ReactComponent as Microphone} from '../../../assets/icons/microphone.svg';
import {ReactComponent as Card} from '../../../assets/icons/card.svg';
import {ReactComponent as Profile} from '../../../assets/icons/profile.svg';
import {ReactComponent as Question} from '../../../assets/icons/question.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import Avatar from '../../../components/Avatar/Avatar';
import Button from '../../../components/Button/Button';
import style from './SettingsMenu.module.scss';

const SettingsMenu = ({ user, selectedTab, setTab, setting }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const menuOption = {
		name: 'menu',
		label: 'Settings',
		back: () => navigate('/dashboard')
	};
	const logOut = () => {
		AuthService.logout()
			.then((res) => {
				dispatch(setUser(null));
				navigate('/login');
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const tabs = [
		{
			name: 'profile',
			label: 'Profile',
			icon: <Profile />,
			back: () => navigate('/settings/menu')
		},
		{
			name: 'voice',
			label: 'Tour Voice',
			icon: <Microphone />,
			back: () => navigate('/settings/menu')
		},
		{
			name: 'subscription',
			label: 'Subscription',
			icon: <Card />,
			back: () => navigate('/settings/menu')
		},
		{
			name: 'account',
			label: 'Account Management',
			icon: <Lock />,
			back: () => navigate('/settings/menu')
		},
		{
			name: 'support',
			label: 'Customer Support',
			icon: <Question />,
			back: () => navigate('/settings/menu')
		},

	];
	const goHome = () => {
		navigate('/dashboard');
	};

	useEffect(() => {
		const setTabOption = (defaultTab) => {
			if(setting){
				let t = tabs.find((tab) => tab.name === setting);
				setTab(t?t:defaultTab);
				return navigate(`/settings/${t?t.name:defaultTab.name}`);
			}
			setTab(defaultTab);
		};
		if (window.innerWidth > 1000) {
			setTabOption(tabs[0]);
		} else {
			setTabOption(menuOption);
		}
	}, [setting]);
	return (
		<div className={style.settings_tabs_menu}>
			<h2 className={style.settings_tabs_menu_title}>
				<div className={style.settings_tabs_menu_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={goHome}></Button>
				</div>Settings
			</h2>
			<div className={style.settings_tabs_menu_header}>
				<div className={style.settings_tabs_menu_header_profile}>
					<Avatar
						image={user?.userData.userImage?.imageUrl}
						name={user?.userData.name}
						size={{width:'3.75rem', height:'3.75rem'}}
					></Avatar>
					<div className={style.settings_tabs_menu_header_profile_info}>
						<h4 className={style.settings_tabs_menu_header_profile_info_name}>{user?.userData.name}</h4>
						<p className={style.settings_tabs_menu_header_profile_info_broker}>{user?.userData.brokerage}</p>
						<button className={style.settings_tabs_menu_header_profile_info_button} onClick={() => navigate('/settings/profile')}><Pencil/></button>
					</div>
				</div>
			</div>
			<div className={style.settings_tabs_menu_options}>
				{
					tabs.map((tab) => (
						<button onClick={() => navigate(`/settings/${tab.name}`)} key={tab.name} className={`${style.settings_tabs_menu_options_item} ${style[`settings_tabs_menu_options_item_${tab.name}`]} ${tab.name === selectedTab.name && style.settings_tabs_menu_options_item_active}`}>
							{tab.icon}
							<p>{tab.label}</p>
							<ArrowRight className={style.settings_tabs_menu_options_item_arrow}/>
						</button>
					))
				}
			</div>
			<div className={style.settings_tabs_menu_logout}>
				<Button iconName="logout" style="text" onClick={logOut}>Log Out</Button>
			</div>
		</div>
	);

};

export default SettingsMenu;
import { useContext, useState, useEffect } from 'react';
import { TourDataContext } from '../../context/TourDataContext';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TourCard from '../../components/Cards/TourCard/TourCard';
import Button from '../../components/Button/Button';
import style from './Dashboard.module.scss';
import line_arrow from '../../assets/icons/right-line-arrow.svg';
import UserService from '../../service/user.service';
import MessageBox from '../../components/MessageBox/MessageBox';
import { CardCustomizedActions } from '../../components/Cards/TourCard/TourCard';
import { Box, CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import WarningBox from '../../components/WarningBox/WarningBox';
import { EmptyStateDashboard } from '../../components/EmptyStateDashboard/EmptyStateDashboard';
import sampleTour_image from '../../assets/images/sampleTour.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, Pagination } from 'swiper';

const Dashboard = () => {
	const user = useSelector((state) => state.user);
	const { tourCards, loadingCards, sampleTour, loadingTour, warning, loadToursList, singleTour } =
		useContext(TourDataContext);
	const [openMessage, setOpenMessage] = useState(false);
	const navigate = useNavigate();
	const emptyCarrusel = [...Array(4).keys()];

	const resource_cards = [
		{ bg: '#E4B760', color: '#fff', text: 'Guide' },
		{ bg: '#8C996F', color: '#fff', text: 'Insights' },
		{ bg: '#B59ABA', color: '#fff', text: 'New Features' },
		{ bg: '#E59905', color: '#fff', text: 'Guide' },
	];

	const tourcardVariant = {
		default: 'rounded',
		flat: 'squared',
		large: 'detailed',
	};
	const handleCreateTour = () => {
		if (user.plan.permissions.tours <= tourCards.length && user?.plan.permissions.tours !== -1) return setOpenMessage(true);
		navigate('/create/getStarted', { replace: true });
	};

	useEffect(() => {
		loadToursList();
	}, [user]);
	console.log(sampleTour);
	return (
		<>
			<CssBaseline />
			{loadingCards ? 
				(
					<Box sx={{height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
						<CircularProgress />
					</Box>
				):
				<div className={style.wrapper}>
					{warning.display && <WarningBox />}
					{tourCards.length  ? (
						<div className={style.headerButtonsWrapper}>
							<div className={style.firstHeadlineWrapper}>
								<h2 className={style.sectionHeadline}>
									My Tours
								</h2>
								<div className={style.header_createButton}>
									<Button onClick={() => handleCreateTour()} style="filled" color="primary" iconName="plus">
								Create a Tour
									</Button>
								</div>
							</div>
						</div>
					) : null}

					<div className={style.swiperContainer}>
						{!tourCards.length && !loadingCards ? (
							<EmptyStateDashboard />
						) : (
							<Swiper
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Pagination]}
								breakpoints={{
									375: { slidesPerView: 1, slidesPerGroup: 1 },
									650: { slidesPerView: 2, slidesPerGroup: 2, spaceBetween: 20},
									950: { slidesPerView: 3, slidesPerGroup: 3, spaceBetween: 20},
									1250: { slidesPerView: 4, slidesPerGroup: 4, spaceBetween: 20},
								}}
								className={style.swiperWrapper}
							>
								{tourCards.length > 0 &&
									tourCards?.map((child, id) => {
										return (
											<SwiperSlide className={style.swiperContainer_slide} key={id}>
												<TourCard
													key={id}
													data={child}
													callback={() =>
														navigate(`/tourhome/${child.randomId}`, { replace: true })
													}
													styleVariant={tourcardVariant.default}
												>
													<CardCustomizedActions
														rating={child.rating}
														createdDate={child.createdDate}
													/>
												</TourCard>
											</SwiperSlide>
										);
									})}
							</Swiper>
						)}
					</div>
					{
						tourCards.length > 0 && !loadingCards ? (
							<section className={style.createTourButton}>
								<Button  iconName="plus" onClick={() => handleCreateTour()} style="filled" color="primary">
								Create a Tour
								</Button>
							</section>
						) : null
					}
						
					<section className={style.sampletour}>
						<h2 className={style.sampletour_helpText}>Need help getting <span>started?</span></h2>
						<div className={style.sampletour_card}>
							<img className={style.sampletour_card_image} src={sampleTour_image} alt="" />
							<div className={style.sampletour_card_content}>
								<h2 className={style.sampletour_card_content_header}>Take a look at our <span>Sample Tour <img className={style.sampletour_card_content_header_arrow} src={line_arrow} alt="" onClick={() => navigate(`/resources/tourhome/${sampleTour?.randomId}`, { replace: true })}/></span></h2>
								<p className={style.sampletour_card_content_body_small}>Use our sample tour as your guide to create an unforgettable experience for your clients. Explore different types of sections, narratives, and transitions.</p>
								<p className={style.sampletour_card_content_body_large}>Use our sample tour as your guide to create an unforgettable experience for your clients. See a comprehensive outline of recommended sections, along with detailed content suggestions, and ideas for smooth transitions. </p>
								<div className={style.sampletour_card_content_button}>
									<Button onClick={() => navigate(`/resources/tourhome/${sampleTour?.randomId}`, { replace: true })} style="text">Explore <img src={line_arrow} alt="" /></Button>
								</div>
							</div>
						</div>
					</section>
					<MessageBox
						title="Tour Limit Reached"
						message="To add more tours please upgrade your plan."
						confirmButton="Upgrade"
						confirm={() => {
							UserService.getClientPortal()
								.then((res) => {
									window.location.href = res.url;
								})
								.catch((err) => {
									console.error(err);
								});
						}}
						cancel={() => setOpenMessage(false)}
						close={() => setOpenMessage(false)}
						open={openMessage}
					></MessageBox>
				</div>
			}
		</>
	);
};

export default Dashboard;

import { useParams } from 'react-router-dom';
import { useState } from 'react';
import UserService from '../../service/user.service';
import { toast } from 'sonner';
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import style from './EmailVerification.module.scss';
import ButtonLoading from '../../components/Form/LoadingButton/LoadingButton';

const EmailVerification = () => {
	const { token } = useParams();
	const [verified, setVerified] = useState(false);
	const [loading, setLoading] = useState(false);
	const verifyEmail = () => {
		setLoading(true);
		let prom = UserService.emailVerification(token);
		toast.promise(prom, {
			pending: 'Verifying email...',
			success: () => {
				setVerified(true);
				setLoading(false);
				return 'Email verified successfully!';
			},
			error: (err) => {
				setLoading(false);
				console.error(err);
				return err?.response?.data?.message;
			},
		});
	};
	return (
		<section className={style.emailVerification}>
			{!verified ? (
				<div className={style.emailVerification_content}>
					<h1>Verify your email.</h1>
					<ButtonLoading
						label="Confirm Email"
						loading={loading}
						type="button"
						onClick={verifyEmail}
					></ButtonLoading>
				</div>
			) : (
				<div className={style.emailVerification_content}>
					<h1>Your email has been verified!</h1>
				</div>
			)}
		</section>
	);
};

export default EmailVerification;

import React, { useEffect } from 'react';
import styles from './CreateTourV2.module.scss';
import { CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';

const CreateTourV2 = () => {
	useEffect(() => {
		const handleBeforeUnload = (event) => {
			event.preventDefault();
			event.returnValue = ''; // Para mostrar el mensaje predeterminado del navegador (opcional)
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	return (
		<>
			<CssBaseline />
			<div className={styles.stepsContainer}>
				<Outlet />
			</div>
		</>
	);
};

export default CreateTourV2;

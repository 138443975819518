import style from './ClientNavBar.module.scss';
import { useEffect, useState } from 'react';
import logo from '../../../assets/images/Full Black_Yellow.png';
import { Link } from 'react-router-dom';
import ShareButton from '../../ShareButton/ShareButton';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import { styled } from '@mui/material/styles';
import ClientService from '../../../service/client.service';
import InputBase from '@mui/material/InputBase';


const NavBar = ({gender, selectedLang, handleChange, actions=true}) => {
	const [language, setLanguage] = useState([]);
	useEffect(() => {
		if(!selectedLang) return;
		ClientService.getLanguageList(gender, selectedLang.language ,selectedLang.id)
			.then((res) => {
				setLanguage(res);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [gender, selectedLang]);
	return (
	//Make this navbar component to converto to mobile view
		<div className={style.navbar}>
			<Link className={style.navBar_logo}>
				<img src={logo} alt=""/>
			</Link>
			{actions && <div className={style.navbar_actions}>
				<LanguageSelect selected={selectedLang} languages={language} setLanguage={handleChange}></LanguageSelect>				
				<ShareButton />
			</div>}
		</div>
	);
};

export default NavBar;

import React from 'react';
import styles from './AccountFormsLayout.module.scss';
import logo from '../../assets/icons/icon.svg';
import phone_one from '../../assets/forms/phone_one.svg';
import phone_two from '../../assets/forms/phone_two.svg';

export const AccountFormsLayout = ({ formTitle, formText, btnText, btnboldText, children, image, onClick }) => (
	<>
		<div className={styles.childrenContainer}>
			<div className={styles.formWrapper}>
				<div className={styles.formWrapper__header}>
					{/* logo */}
					<img src={logo} alt="tour homes logo" className={styles.childrenContainer__logo} />
					<h1 className={styles.childrenContainer__title}>{formTitle}</h1>
					<p className={styles.childrenContainer__text}>{formText}</p>
					{children}
					{btnText && btnboldText ?<button onClick={onClick} className={styles.childrenContainer__btn}>
						{btnText}
						<span>{btnboldText}</span>
					</button>
						: null
					}
				</div>
			</div>
			<div className={styles.imageWrapper}>
				<img src={phone_one} alt="" />
				<img src={phone_two} alt="" />
			</div>
		</div>
	</>
);

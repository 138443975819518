const toCurrency = (value) => {
	if (typeof value !== 'number') {
		value = parseInt(value);
	}
	let formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
	return formatter.format(value);
};

module.exports = {
	toCurrency,
};

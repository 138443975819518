import { useState, useContext } from 'react';
import style from './SectionListItem.module.scss';
import SectionModal from '../SectionModal/SectionModal';
import SmallPlayer from '../SmallPlayer/SmallPlayer';
import IconOptions from '../IconOptions/IconOptions';
import MessageBox from '../MessageBox/MessageBox';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { AudioContext } from '../../context/AudioContext';


const SectionListItem = ({
	handleEdit,
	order,
	handleDelete,
	id,
	title,
	speech,
	description,
	dragHandle,
	audio,
	sample,
}) => {
	const [open, setOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState(false);
	const options = [
		{
			label: 'Delete',
			icon: 'fa-solid fa-trash',
			handle: () => setDialogOpen(true),
		},
		{
			label: 'Edit',
			icon: 'fa-regular fa-file-lines',
			handle: () => setOpen(true),
		},
	];
	const { currentSection } = useContext(AudioContext);

	return (
		<div className={style.listItem}>
			<i className={`${style.listItem__dragIcon} fa-solid fa-grip-vertical`} {...dragHandle}></i>
			<p className={style.listItem__title}>
				<span className={`${style.listItem__order} ${ order === currentSection+1 && style.listItem__orderActive}`}>
					{order < 10 ? `0${order}` : order}.{' '}
				</span>
				{title}
				<span className={style.listItem__description}>{description}</span>
			</p>
			<div className={style.listItem__player}>
				{audio && <SmallPlayer 
					audio={audio} 
					id={id} 
					order={order - 1}
				/>}
			</div>
			<div className={style.listItem__options}>{!sample && <IconOptions options={options} />}</div>
			<SectionModal
				order={order}
				section_id={id}
				script={speech}
				title={title}
				open={open}
				handleClose={() => setOpen(false)}
				onSubmit={handleEdit}
			></SectionModal>
			<MessageBox
				title="Delete Section"
				message="Are you sure you want to delete this section?"
				confirmButton="Delete"
				confirm={() => handleDelete(id)}
				cancel={() => setDialogOpen(false)}
				close={() => setDialogOpen(false)}
				open={dialogOpen}
			></MessageBox>
		</div>
	);
};

export default SectionListItem;

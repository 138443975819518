import React, { useState } from 'react';
import style from './InputScript.module.scss';
const InputScript = ({register,name, error, placeholder, ...rest}) => {
	const [readingTime, setReadingTime] = useState('0 mins 00 secs');

	const convertTime = (seconds) => {
		const minutes = Math.floor(seconds / 60); // Obtiene los minutos
		const secondsRemainder = Math.floor(seconds % 60); // Obtiene los segundos restantes
		// Agrega un cero a la izquierda si los segundos restantes son menores a 10
		const formattedSeconds = secondsRemainder < 10 ? `0${secondsRemainder}` : secondsRemainder;
		return `${minutes} mins ${formattedSeconds} secs`; // Devuelve el formato mm:ss
	};
	const calculateReadingTime = (event) => {
		let time = event.target.value.length / (5.6 * 3);
		setReadingTime(convertTime(time));
	};

	return (
		<div className={style.textarea}>
			<div className={style.textarea_input}>
				<textarea
					name={name}
					{...register(name)}
					{...rest}
					placeholder={placeholder}
					className={error[name] ? style.textarea_error : style.textarea_valid}
					onBlur={calculateReadingTime}
				/>
			</div>
			<p className={style.textarea_readingTime}>Estimated Time: <span>{readingTime}</span></p>
			{error[name] ? <p className={style.textarea_message_error}>{error[name].message}</p> : ''}
		</div>
	);
};

export default InputScript;

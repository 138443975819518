import axios from 'axios';
import bcrypt from 'bcryptjs';
const API_URL =
	process.env.REACT_APP_ENV !== 'dev' ? 'https://server.tourmehomes.com/api/' : 'http://localhost:3001/api/';
// const API_URL = 'https://server.tourmehomes.com/api/'
const instance = axios.create({
	withCredentials: true,
});
//TOUR
const getTourData = (tourId, voiceId) => {
	return instance
		.get(API_URL + 'tour/find', { params: { tourId: tourId, voiceId } })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const getTourList = () => {
	return instance
		.get(API_URL + 'tour/list')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const createTour = (tour) => {
	const images = tour?.tourImages;
	const data = new FormData();
	images.map((image, i) => {
		const base64Data = image.imageUrl.replace(/^data:image\/\w+;base64,/, '');
		const bytes = window.atob(base64Data);
		const rawLength = bytes.length;
		const uInt8Array = new Uint8Array(rawLength);
		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = bytes.charCodeAt(i);
		}
		data.append(`image${i}`, new Blob([uInt8Array], { type: image.type }));
		return data;
	});
	delete tour.tourImages;
	Object.entries(tour).forEach(([key, value]) => {
		data.append(key, value);
	});

	return instance
		.post(API_URL + 'tour/create', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const createFullTour = (tour, sections) => {
	const images = tour?.tourImages;
	const data = new FormData();
	images.map((image, i) => {
		const base64Data = image.replace(/^data:image\/\w+;base64,/, '');
		const bytes = window.atob(base64Data);
		const rawLength = bytes.length;
		const uInt8Array = new Uint8Array(rawLength);
		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = bytes.charCodeAt(i);
		}
		data.append(`image${i}`, new Blob([uInt8Array], { type: image.type }));
		return data;
	});

	Object.entries(tour).forEach(([key, value]) => {
		key!== 'tourImages' && data.append(key, value);
	});
	data.append('sections', JSON.stringify(sections));

	return instance
		.post(API_URL + 'tour/create/full', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const getSampleTour = () => {
	return instance
		.get(API_URL + 'tour/sample')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const changeTourState = (tourId, status) => {
	return instance
		.put(API_URL + 'tour/update/state', { tourId, status })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const updateTourProperties = async (tourId, tour) => {
	await instance
		.put(API_URL + 'tour/update/properties', { tourId, ...tour })
		.then(async (res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const updateTourImages = async (tourId, images, deleted_images) => {
	const data = new FormData();
	images.map((image, i) => {
		console.log(image);
		const base64Data = image.replace(/^data:image\/\w+;base64,/, '');
		const bytes = window.atob(base64Data);
		const rawLength = bytes.length;
		const uInt8Array = new Uint8Array(rawLength);
		for (let i = 0; i < rawLength; ++i) {
			uInt8Array[i] = bytes.charCodeAt(i);
		}
		data.append(`image${i}`, new Blob([uInt8Array], { type: image.type }));
		return data;
	});
	if (deleted_images) data.append('deletedImages', JSON.stringify(deleted_images));
	data.append('tourId', tourId);
	await instance
		.put(API_URL + 'tour/update/images', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then(async (res) => {
			return res.data;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const getActiveTours = () => {
	return instance
		.get(API_URL + 'tour/list/active')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const deleteTour = (tourId) => {
	return instance
		.delete(API_URL + `tour/delete/${tourId}`)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const getVoiceList = () => {
	return instance
		.get(API_URL + 'voice/voice/list')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const sendSupportRequest = (data) => {
	return instance
		.post(API_URL + 'support/request', data)
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
//SECTION
const getSectionList = (tourId, voiceId) => {
	return instance
		.get(API_URL + 'section/list', { params: { tourid: tourId, voiceId: voiceId } })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};
const deleteSection = (sectionId) => {
	return instance
		.delete(API_URL + `section/delete/${sectionId}`)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const createSection = (section) => {
	return instance
		.post(API_URL + 'section/create', section)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};

const updateSection = (section) => {
	return instance
		.put(API_URL + 'section/update', section)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};
const updateSectionsOrder = (sections) => {
	return instance
		.put(API_URL + 'section/list/update', { sections })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const getSectionAudio = (sectionId, voiceId) => {
	return instance
		.post(API_URL + 'section/audio', { sectionId, voiceId })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
//USER
const editProfile = (profile, image) => {
	const data = new FormData();
	if (image?.imageData) data.append('image', new Blob([image.imageData], { type: image.type }));
	else if (!image) data.append('deleteImage', true);

	Object.entries(profile).forEach(([key, value]) => {
		data.append(key, value);
	});
	console.log(data);
	return instance
		.put(API_URL + 'user/update', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const updateAgentInfo = (brokerage, license, phone) => {
	return instance
		.put(API_URL + 'user/agent/update', { brokerage, license, phone })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const changePassword = (oldPassword, password) => {
	const pass = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	const old = bcrypt.hashSync(oldPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'user/password/change', { password: pass, oldPassword: old })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
		});
};
const sendEmail = (email, message, id) => {
	return instance
		.post(API_URL + 'user/email', { email, message, id })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
const sendSMS = (phone, message, id) => {
	return instance
		.post(API_URL + 'user/sms', { phone, message, id })
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			throw err;
		});
};
//OTHER
const getTextToSpeech = (text, language) => {
	return instance
		.get(API_URL + 'voice/tts', { params: { text, language }, responseType: 'arraybuffer' })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const getClientPortal = () => {
	return instance
		.get(API_URL + 'client/portal')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const getPriceList = () => {
	return instance
		.get(API_URL + 'price/list')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const changeVoice = (voice) => {
	return instance
		.put(API_URL + 'user/voice/update', { voice:voice })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const resetPassword = (user) => {
	return instance
		.post(API_URL + 'user/password/request', { user })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const updatePassword = (identifier, password) => {
	password = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
	return instance
		.post(API_URL + 'user/password/reset', { identifier, password })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const emailVerification = (identifier) => {
	return instance
		.post(API_URL + 'user/verify/email', { identifier })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const createEmailVerification = (email, name) => {
	return instance
		.post(API_URL + 'user/verify/email/create', { email, name })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const createInvite = () => {
	return instance
		.post(API_URL + 'invite/create')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};
const deleteAgent = (id) => {
	return instance
		.post(API_URL + 'user/admin/agent/remove', { agentId: id })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const assignInvite = (code) => {
	return instance
		.post(API_URL + 'invite/assign', { code })
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const getAgents = () => {
	return instance
		.get(API_URL + 'user/subscription/agents')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const getAdmin = () => {
	return instance
		.get(API_URL + 'user/subscription/admin')
		.then((res) => {
			return res.data;
		})
		.catch((err) => {
			throw err;
		});
};
const removeSubscription = () => {
	return instance
		.put(API_URL + 'user/subscription/leave')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};
const getSubsData = () => {
	return instance
		.get(API_URL + 'user/subscription/data')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			throw err;
		});
};
const UserService = {
	getSubsData,
	createEmailVerification,
	emailVerification,
	updatePassword,
	resetPassword,
	changeVoice,
	getPriceList,
	getTourData,
	getSectionList,
	deleteSection,
	getTourList,
	createTour,
	getTextToSpeech,
	getSampleTour,
	updateSection,
	createSection,
	createFullTour,
	sendSMS,
	updateSectionsOrder,
	deleteTour,
	getVoiceList,
	editProfile,
	changeTourState,
	getActiveTours,
	updateTourProperties,
	updateTourImages,
	changePassword,
	sendEmail,
	getClientPortal,
	getSectionAudio,
	updateAgentInfo,
	sendSupportRequest,
	createInvite,
	deleteAgent,
	assignInvite,
	getAgents,
	getAdmin,
	removeSubscription
};
export default UserService;

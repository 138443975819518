import React, { useRef } from 'react';
import style from '../ShareModal.module.scss';
import Button from '../../Button/Button';
import QRCode from 'react-qr-code';

const ShareQR = ({tourId, setStep, header=true}) => {
	const qrRef = useRef(null);
	const downloadQRCode = () => {
		const svgElement = qrRef.current;
		const svgString = new XMLSerializer().serializeToString(svgElement);

		const img = new Image();
		img.src = `data:image/svg+xml;base64,${btoa(svgString)}`;

		img.onload = function () {
			const canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);

			const pngDataUrl = canvas.toDataURL('image/png');
        
			const link = document.createElement('a');
			link.href = pngDataUrl;
			link.download = 'qrcode.png';

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		};
	};
	return (
		<div className={style.shareModal}>
			{header && <h3 className={style.shareModal_title}>
				<div className={style.shareModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Get QR Code</h3>}
			<p className={style.shareModal_description}>Scanning this QR code grants anyone direct access to your tour.</p>
			<div className={style.shareModal_qrCode}>
				<QRCode
					ref={qrRef}
					value={`https://portal.tourmehomes.com/tour/${tourId}`}
					level="H"
					style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
					viewBox={'0 0 256 256'}
				/>
			</div>
			<Button style='text' iconName="download" onClick={downloadQRCode}>Download</Button>
		</div>
	);
};

export default ShareQR;
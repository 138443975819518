import Button from '../../Button/Button';
import style from '../EditTour.module.scss';
import { useForm } from 'react-hook-form';
import InputField from '../../Form/InputField/InputField';
import Select from '../../Select/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';
import * as yup from 'yup';
import bed from '../../../assets/icons/bed.svg';
import bath from '../../../assets/icons/bath.svg';

const propertyInformationSchema = yup.object().shape({
	feets: yup.string().required('This field is required'),
	yearBuilt: yup.string().required('This field is required').max(4, 'This field is required'),
	price: yup.string().required('This field is required'),
	beds: yup.number().typeError('This field is required').moreThan(0, 'This field is required').required('This field is required'),
	baths: yup.number().typeError('This field is required').moreThan(0, 'This field is required').required('This field is required'),
});

const EditDetails = ({ tour, updateTour, setStep }) => {

	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			price: tour.price,
			feets: tour.feets,
			yearBuilt: tour.yearBuilt,
			beds: tour.beds,
			baths: tour.baths,
		},
		resolver: yupResolver(propertyInformationSchema),
		mode: 'all',
	});
	const currentYear = new Date().getFullYear();
	const startYear = 1800;
	const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => currentYear - index);

	const onSubmit = (data) => {
		let prom = UserService.updateTourProperties(tour.id, {...data, price: parseInt(data.price.replace(/[$,]*/g, '')), feets:parseInt(data.feets.replace(/[$,]*/g, ''))});
		toast.promise(prom, {
			loading: 'Updating tour...',
			success: (res) => {
				updateTour();
				return 'Tour updated';
			},
			error: (err) => {
				console.error(err);

				return 'Error updating tour';
			},
		});
	};
	return (
		<div className={style.editModal}>
			<h3 className={style.editModal_title}>
				<div className={style.editModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Property Details</h3>
			<form className={style.editModal_form} onSubmit={handleSubmit(onSubmit)}>
				<InputField
					width='100'
					name="price"
					placeholder="Price"
					register = {register}
					error= {errors}
					setValue={setValue}
					inputType='price'
				/>
				<div className={style.editModal_form_row}>
					<InputField
						width='100'
						name="feets"
						placeholder="Sq Ft"
						register = {register}
						error= {errors}
						setValue={setValue}
						inputType='number'
					/>
					<Select width='100' name="yearBuilt" register={register} error={errors}>
						<option value="" disabled>
							Year Built
						</option>
						{years.map((year) => (
							<option key={year} value={year}>
								{year}
							</option>
						))}</Select>
				</div>
				<div className={style.editModal_form_row}>
					<InputField
						width='100'
						name="beds"
						placeholder="-"
						register = {register}
						error= {errors}
						setValue={setValue}
						inputType='decimal'

						prefix={<img src={bed} alt="bed" />}
					/>
					<InputField
						width='100'
						name="baths"
						placeholder="-"
						register = {register}
						error= {errors}
						setValue={setValue}
						inputType='decimal'
						prefix={<img src={bath} alt="bed" />}
					/>
				</div>
			</form>
			<Button iconName="save" style="text" onClick={handleSubmit(onSubmit)}>Save</Button>
		</div>
	);
};

export default EditDetails;
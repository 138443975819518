import styles from './TourDetails.module.scss';
import EditTour from '../../EditTour/EditTour';
import { useState, useContext, useEffect } from 'react';
import { TourDataContext } from '../../../context/TourDataContext';
import Tooltip from '@mui/material/Tooltip';
import ShareModal from '../../ShareModal/ShareModal';
import Button from '../../Button/Button';
import bed from '../../../assets/icons/bed.svg';
import bath from '../../../assets/icons/bath.svg';
import sqft from '../../../assets/icons/sqft.svg';
import pencil from '../../../assets/icons/pencil.svg';
import empty_image from '../../../assets/images/empty_image.png';
import qr from '../../../assets/icons/qr.svg';
import flyer from '../../../assets/icons/flyer.svg';
import {ReactComponent as RigthLineArrow} from '../../../assets/icons/right-line-arrow.svg';
import {ReactComponent as Copy} from '../../../assets/icons/copy.svg';
import email from '../../../assets/icons/email.svg';
import StateChip from '../../StateChip/StateChip';
import ShareQR from '../../ShareModal/ShareQR/ShareQR';
import ShareFlyer from '../../ShareModal/ShareFlyer/ShareFlyer';
import ShareEmail from '../../ShareModal/ShareEmail/ShareEmail';

const TourDetails = ({singleTour, setSingleTourInfo, stateChip=false, edit=false, share=false }) => {
	const [open, setOpen] = useState(false);
	const [openShare, setShareOpen] = useState(false);
	const [tab, setTab] = useState('link');
	const [editTab, setEditTab] = useState('menu');
	const updateTour = () => {
		setSingleTourInfo(singleTour.randomId);
	};
	const copyLink = () => {
		navigator.clipboard.writeText(`http://portal.tourmehomes.com/welcome/${singleTour.randomId}`);
	};
	const openTour = () => {
		window.open(`http://portal.tourmehomes.com/welcome/${singleTour.randomId}`, '_blank');
	};
	const openStatus = () => {
		setEditTab('Status');
		setOpen(true);
	};
	useEffect(() => {
		if(!open) setEditTab(null);
	}, [open]);
	return (
		<div className={styles.tourDetails}>
			<div className={styles.tourDetails_image}>
				<img className={styles.tourDetails_image_photo} src={singleTour?.tourImages.length>0?singleTour.tourImages[0]?.imageUrl: empty_image} alt="" />
				{
					stateChip &&
					<div className={styles.tourDetails_image_state}>
						<StateChip state={singleTour.status} onClick={openStatus}/>
					</div>
				}
				<div className={styles.tourDetails_image_share}>
					<h4 className={styles.tourDetails_image_share_title}>Share Your Tour</h4>
					<p className={styles.tourDetails_image_share_description}>Make your tour accessible to potential buyers! </p>
					<div className={styles.tourDetails_image_share_options}>
						<button onClick={() => setTab('link')} className={`${styles.tourDetails_image_share_options_item} ${tab==='link' && styles.tourDetails_image_share_options_item_active}`}>
							<img src={pencil} alt="" />
							<p>Copy Link</p>
							<RigthLineArrow className={styles.tourDetails_image_share_options_item_arrow}/>
						</button>
						<button onClick={() => setTab('qr')} className={`${styles.tourDetails_image_share_options_item} ${tab==='qr' && styles.tourDetails_image_share_options_item_active}`}>
							<img src={qr} alt="" />
							<p>Get QR Code</p>
							<RigthLineArrow className={styles.tourDetails_image_share_options_item_arrow}/>
						</button>
						<button onClick={() => setTab('flyer')} className={`${styles.tourDetails_image_share_options_item} ${tab==='flyer' && styles.tourDetails_image_share_options_item_active}`}>
							<img src={flyer} alt="" />
							<p>Create Flyer</p>
							<RigthLineArrow className={styles.tourDetails_image_share_options_item_arrow}/>
						</button>
						<button onClick={() => setTab('email')} className={`${styles.tourDetails_image_share_options_item} ${tab==='email' && styles.tourDetails_image_share_options_item_active}`}>
							<img src={email} alt="" />
							<p>Send Email</p>
							<RigthLineArrow className={styles.tourDetails_image_share_options_item_arrow}/>
						</button>
					</div>
				</div>
			</div>
			<div className={styles.tourDetails_body}>
				<div className={styles.tourDetails_body_info}>
					<Tooltip title={`${singleTour.adStreet}${singleTour.apartment !== ''? `, ${singleTour.apartment}`:''}`}><h4 className={styles.tourDetails_body_info_address}>{`${singleTour.adStreet}${singleTour.apartment !== ''? `, ${singleTour.apartment}`:''}`}</h4></Tooltip>
					<Tooltip title={`${singleTour.city}, ${singleTour.state} ${singleTour.zipCode}`}><h6 className={styles.tourDetails_body_info_address_details}>{`${singleTour.city}, ${singleTour.state} ${singleTour.zipCode}`}</h6></Tooltip>
					<div className={styles.tourDetails_body_info_row}>
						<p className={styles.tourDetails_body_info_row_mls}>MLS #: <span>{singleTour.mlsCode}</span></p>
						<p className={styles.tourDetails_body_info_row_price}>${Number(singleTour.price).toLocaleString()}</p>
					</div>
					<div className={styles.tourDetails_body_info_details}>
						<div className={styles.tourDetails_body_info_details_item}>
							<img src={bed} alt="" />
							<p>{singleTour.beds} <span>beds</span></p>
						</div>
						<div className={styles.tourDetails_body_info_details_item}>
							<img src={bath} alt="" />
							<p>{singleTour.baths} <span>baths</span></p>
						</div>
						<div className={styles.tourDetails_body_info_details_item}>
							<img src={sqft} alt="" />
							<p>{singleTour.feets} <span>sqft</span></p>
						</div>
					</div>
					{
						edit && 
						<button onClick={() => setOpen(true)} className={styles.tourDetails_body_info_edit}>
							<img src={pencil} alt="" />
						</button>
					}
				</div>
				<div className={styles.tourDetails_body_steps}>
					{
						tab === 'link' &&
						(
							<div className={styles.tourDetails_body_steps_link}>
								<p className={styles.tourDetails_body_steps_link_description}>Access your tour using this link. This is what visitors will see when touring the property.</p>
								<div className={styles.tourDetails_body_steps_link_copy}>
									<p className={styles.tourDetails_body_steps_link_copy_text}>http://portal.tourmehomes.com/view/{singleTour.randomId}</p>
									<button className={styles.tourDetails_body_steps_link_copy_button} onClick={copyLink}><Copy/></button>
								</div>
								<Button style="text" iconName="open" onClick={openTour}>Open Tour</Button>
							</div>
						)
					}
					{
						tab === 'qr' &&
						(
							<ShareQR tourId={singleTour.randomId} header={false}/>
						)
					}
					{
						tab === 'flyer' &&
						(
							<ShareFlyer header={false}/>
						)
					}
					{
						tab === 'email' &&
						(
							<ShareEmail header={false} tourId={singleTour.randomId}/>
						)
					}
				</div>
			</div>
			{share && <div className={styles.tourDetails_share}>
				<Button style="filled" color="primary" iconName='send' onClick={() => setShareOpen(true)}>Share Tour</Button>
			</div>}
			<EditTour updateTour={updateTour} tourDetails={singleTour} open={open} close={() => setOpen(false)} startTab={editTab}></EditTour>
			<ShareModal open={openShare} handleClose={() => setShareOpen(false)} tour={singleTour}></ShareModal>
		</div>
	);
};

export default TourDetails;

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/actions/userAction';
import AuthService from '../../service/auth.service';
const UpdateBilling = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		AuthService.getCurrentUser()
			.then((res) => {
				dispatch(setUser(res.data.data));
				navigate('/settings/subscription');
			})
			.catch((err) => {
				navigate('/login');
			});
	}, []);
};
export default UpdateBilling;

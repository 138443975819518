import {useState} from 'react';
import ModalBox from '../ModalBox/ModalBox';
import ShareLink from './ShareLink/ShareLink';
import style from './ShareModal.module.scss';
import flyer from '../../assets/icons/flyer.svg';
import qr from '../../assets/icons/qr.svg';
import email from '../../assets/icons/email.svg';
import link from '../../assets/icons/link.svg';
import dots_left from '../../assets/icons/dots_left.svg';
import dots_right from '../../assets/icons/dots_right.svg';
import ShareQR from './ShareQR/ShareQR';
import ShareFlyer from './ShareFlyer/ShareFlyer';
import ShareEmail from './ShareEmail/ShareEmail';

const ShareModal = ({tour, open, handleClose}) => {
	const [step, setStep] = useState('menu');
	
	const steps = [
		{
			next: () => {
				navigator.clipboard.writeText(`http://portal.tourmehomes.com/welcome/${tour.randomId}`);
				setStep('link');
			},
			label: 'Copy Link',
			icon: link
		},
		{
			next: () => setStep('qr'),
			label: 'Get QR Code',
			icon: qr
		},
		{
			next: () => setStep('flyer'),
			label: 'Create Flyer',
			icon: flyer
		},
		{
			next: () => setStep('email'),
			label: 'Send Email',
			icon: email
		}
	];
	return (
		<ModalBox isOpen={open} close={handleClose}>
			{
				step === 'menu' && (
					<div className={style.shareModal}>
						<div className="">
							<h3 className={style.shareModal_title}>Share Tour</h3>
							<p className={style.shareModal_description}>Make your tour accessible to potential buyers!</p>
						</div>
						<ul className={style.shareModal_list}>
							{
								steps.map((step, index) => (
									<li className={style.shareModal_list_item} key={index}>
										<button className={style.shareModal_list_item_button} onClick={step.next}>
											<img className={style.shareModal_list_item_button_image} src={step.icon} alt="" />
											<p className={style.shareModal_list_item_button_text}>{step.label}</p>
										</button>
									</li>
								))
							}
						</ul>
					</div>
				)
			}
			{
				step === 'link' && (
					<ShareLink setStep={setStep} handleClose={handleClose}/>
				)
			}
			{
				step === 'qr' && (
					<ShareQR tourId={tour.randomId} setStep={setStep}/>
				)
			}
			{
				step === 'flyer' && (
					<ShareFlyer setStep={setStep}/>
				)
			}
			{
				step === 'email' && (
					<ShareEmail setStep={setStep} tourId={tour.randomId}/>
				)
			}
			<div className={style.dots}>
				<img src={step === 'menu'? dots_left : dots_right} alt="" />
			</div>
		</ModalBox>
	);
};

export default ShareModal;
import React, { useState, useEffect, useRef } from 'react';
import { Avatar} from '@mui/material';
import {ReactComponent as Image} from '../../../assets/icons/image.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import style from './AvatarInput.module.scss';

const AvatarInput = ({ imageUrl, name, onImageUpload, onDeleteImage }) => {	
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const handleImageUpload = (event) => {
		let reader = new FileReader();
		const file = event.target.files[0];
		if(file){
			reader.readAsDataURL(file);
			reader.onload = () => {
				const base64Data = reader.result.split(',')[1];
				const bytes = window.atob(base64Data);
				const rawLength = bytes.length;
				const uInt8Array = new Uint8Array(rawLength);
				for (let i = 0; i < rawLength; ++i) {
					uInt8Array[i] = bytes.charCodeAt(i);
				}
	
				file.imageUrl = URL.createObjectURL(file);
				file.imageData = uInt8Array;
				onImageUpload(file);
			};
		}
	};

	const handleDeleteImage = () => {
		onDeleteImage();
		setOpen(false);
	};
	function stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	function stringAvatar(name) {
		let fullName = name?.split(' ');
		let initials = fullName?.length > 1 ? fullName[0][0] + fullName[1][0] : fullName[0][0];
		return {
			sx: {
				bgcolor: stringToColor(name),
			},
			children: initials,
		};
	}
	useEffect(() => {
		if (open) {
			const closeMenu = (e) => {
				if (!e.target.closest(`.${style.avatarInput}`)) {
					setOpen(false);
				}
			};

			document.addEventListener('click', closeMenu);
			return () => document.removeEventListener('click', closeMenu);
		}
	}, [open]);
	return (
		<div className={style.avatarInput}>
			<input ref={ref} type="file" id="image-upload" style={{ display: 'none' }} onChange={handleImageUpload} />
			<Avatar src={imageUrl?.imageUrl} {...stringAvatar(name)} sx={{ width: '5rem', height: '5rem'}} onClick={() => setOpen(!open)}></Avatar>
			{
				open && 
				<div className={style.avatarInput_actions}>
					<button className={style.avatarInput_actions_button} onClick={() => {
						ref.current.click();
						setOpen(false);
					}}><Image/><p>Change Photo</p></button>
					<button className={style.avatarInput_actions_button}><Trash className={style.avatarInput_actions_button_red} onClick={handleDeleteImage}/><p>Delete Photo</p></button>
				</div>
			}
		</div>
	);
};

export default AvatarInput;

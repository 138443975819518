import mic from '../../assets/icons/mic.svg';
import house from '../../assets/icons/house.svg';
import style from './TabButtons.module.scss';

const TabButtons = ({ tab, setTab }) => {

	return (
		<div className={style.tabbuttons}>
			<button className={`${style.tabbuttons_button} ${tab==='details' && style.tabbuttons_button_active}`} onClick={() => setTab('details')}>
				<img className={style.tabbuttons_button_icon} src={house} alt="" />
				<p className={style.tabbuttons_button_label}>Details</p>
			</button>
			<button className={`${style.tabbuttons_button} ${tab==='tour' && style.tabbuttons_button_active}`}  onClick={() => setTab('tour')}>
				<img className={style.tabbuttons_button_icon} src={mic} alt="" />
				<p className={style.tabbuttons_button_label}>Tour</p>
			</button>
		</div>
	);
};

export default TabButtons;

import React, { useEffect } from 'react';
import * as yup from 'yup';
import InputField from '../../Form/InputField/InputField';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWrapperLayout from '../FormWrapperLayout/FormWrapperLayout';
import { useCreateForm } from '../../../context/CreateTourContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../../../service/auth.service';
import { setUser } from '../../../store/actions/userAction';
import UserService from '../../../service/user.service';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import style from '../CreateTourV2.module.scss';

const AgentInfo = () => {

	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	const shape = {
		license: yup
			.number()
			.min(3, 'Please provide a valid license')
			.typeError('Please provide a valid license')
			.required('Please provide a valid license'),
		phone:yup.string().matches(/^\+[0-9]{0,3}[-\s.]?[(]?[0-9]{1,3}[)]?[-\s.]?[0-9]{3,4}[-\s.]?[0-9]{4,6}$/, 'Please enter a valid phone number').required('Please enter a valid phone number'),
		brokerage: yup
			.string()
			.matches(/^[^0-9-!¡$%^&*()_+|~=`°¨#¬{}[\]:";<>¿?,.]+$/, 'This field only admits text')
			.test('trimSpaces', 'Must enter a valid text', (value) => value?.trim().length > 0)
			.required('This field is required')
	};
	const agentInfoResolver = yup.object().shape(shape);
	const navigate = useNavigate();

	const { agentData, setAgentValues } = useCreateForm();

	const {handleSubmit, formState: { errors }, register, setValue} = useForm({
		defaultValues: {
			license: user?.userData.license,
			brokerage: user?.userData.brokerage,
			phone: user?.userData.phone
		},
		resolver: yupResolver(agentInfoResolver),
		mode:'onBlur'
	});
	const onSubmit = (data) => {
		let body = {
			phone: data.phone,
			license: data.license,
		};
		if(user?.userData.role === 'Admin') body.brokerage = data.brokerage;
		setAgentValues({...body});
		let prom = UserService.updateAgentInfo(body.brokerage, body.license, body.phone);
		toast.promise(prom, {
			loading: 'Loading...',
			success: (res) => {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
					})
					.catch((err) => {
						console.error(err);
					});
				navigate('/create/propertyAddress');
				return 'Agent info updated successfully';
			},
			error: (err) => {
				console.error(err);
				return err?.response?.data?.message || 'Something went wrong';
			},
		});
	};
	useEffect(() => {
		if (user?.userData?.brokerage && user?.userData?.license && user?.userData?.phone) {
			navigate('/create/propertyAddress');
		}
	}, []);

	return (
		<FormWrapperLayout back={() => navigate('/create/getStarted')} formTitle="Agent Information" next={handleSubmit(onSubmit)}>
			<form className={style.form} onSubmit={handleSubmit(onSubmit)}>
				<InputField
					name="phone"
					placeholder="Phone Number"
					inputType="phone"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<InputField
					name="license"
					placeholder="Real Estate License"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<InputField
					name="brokerage"
					register = {register}
					placeholder="Brokerage Name"
					disabled={user?.userData.role !== 'Admin'}
					error= {errors}
					setValue={setValue}
				/>
			</form>
		</FormWrapperLayout>
	);
};

export default AgentInfo;

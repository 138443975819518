import React, { useState } from 'react';
import style from '../EditTour.module.scss';
import Button from '../../Button/Button';
import InputImage from '../../InputImage/InputImage';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';

const EditStatus = ({ tourId,updateTour, tourImages, setStep }) => {
	const [images, setImages] = useState(tourImages);
	const onSubmit = () => {
		const deletedImages = images.filter((image) => image.deleted);
		const addedImages = images.filter((image) => !image.imageUrl);
		console.log({ addedImages, deletedImages, tourId });
		const prom = UserService.updateTourImages(tourId, addedImages, deletedImages);
		toast.promise(prom, {
			loading: 'Updating tour...',
			success: (res) => {
				updateTour();
				return 'Tour updated';
			},
			error: (err) => {
				console.error(err);
				return 'Error updating tour';
			},
		});
	};
	return (
		<div className={`${style.editModal} ${style.editModal_link}`}>
			<h3 className={style.editModal_title}>
				<div className={style.editModal_title_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
				</div>Photo Gallery</h3>
			<InputImage images={images} setImages={setImages}></InputImage>
			<Button style='text' iconName="save" onClick={onSubmit}>Save</Button>
		</div>
	);
};

export default EditStatus;
import WaveSurfer from 'wavesurfer.js';
import { useState, useEffect, useRef, useContext } from 'react';
import style from './SmallPlayer.module.scss';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AudioContext } from '../../context/AudioContext';
import { convertTime } from '../../helpers/audioFormatter';

const SmallPlayer = ({ audio, id, order }) => {
	const { activeAudio, playAudio, pauseAudio, handlePlayPauseInSmallPlayer, isPlayingAudio } = useContext(AudioContext);
	const containerRef = useRef();
	const waveSurferRef = useRef();
	const [progress, setProgress] = useState(0);
	const [duration, setDuration] = useState(0);
	const [fullTime, setTime] = useState();

	useEffect(() => {
		waveSurferRef.current?.destroy();
		const waveSurfer = WaveSurfer.create({
			container: containerRef.current,
			backend: 'MediaElement',
			responsive: true,
			barWidth: 2,
			barHeight: 1,
			cursorWidth: 0,
			barRadius: 0,
			barGap: 3,
			height: 40,
			waveColor: '#e0e0e0',
			progressColor: '#000',
			fillParent: true,
			hideScrollbar: true,
			normalize: true,
			partialRender: true,
			minPxPerSec: 30,
		});
		waveSurfer.load(audio);
		waveSurfer.on('ready', () => {
			setProgress(0);
			waveSurferRef.current = waveSurfer;
			setDuration(waveSurfer.getDuration());
		});
		waveSurfer.on('audioprocess', function () {
			if (waveSurfer.isPlaying()) {
				let currentTime = waveSurfer.getCurrentTime();
				setProgress(Math.round((currentTime / duration) * 100));
			}
		});
		waveSurfer.on('finish', () => {
			setProgress(0);
			pauseAudio();
		});

		return () => {
			waveSurfer.destroy();
		};
	}, [audio]);


	return (
		<div className={style.smallPlayer}>
			<div ref={containerRef} className={style.smallPlayer__wave} />
			<p>{convertTime(duration)}</p>
			<div className={style.smallPlayer__button}>
				<button
					onClick={() => {
						handlePlayPauseInSmallPlayer(order, id);
					}}
					type="button"
					className={isPlayingAudio.id === id && isPlayingAudio.state ? style.smallPlayer__pauseButton : style.smallPlayer__playButton}
				>
					{isPlayingAudio.id === id && isPlayingAudio.state ? (
						<i className={'fa-solid fa-pause'}></i>
					) : (
						<i className={'fa-solid fa-play'}></i>
					)}
				</button>
			</div>
		</div>
	);
};

export default SmallPlayer;

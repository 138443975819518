import style from './IconText.module.scss';
const IconText = ({
	icon,
	text,
	icon_color = '#000',
	text_color = '#000',
	font_size = '0.9rem',
	icon_size = '0.9rem',
	decoration = 'none',
	textStyle,
	callback,
	disabled,
	className,
}) => {
	return (
		<div
			className={`${style.icon_text} ${className} ${disabled ? style.disabled : 'chau'}`}
			onClick={() => {
				return disabled ? null : callback();
			}}
		>
			<i className={icon} style={{ color: icon_color, 'fontSize': icon_size }} />
			<p style={{ 'fontSize': font_size, color: text_color, 'textDecoration': decoration, ...textStyle }}>
				{text}
			</p>
		</div>
	);
};

export default IconText;

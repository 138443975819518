import style from '../ShareModal.module.scss';
import Button from '../../Button/Button';
import { useForm } from 'react-hook-form';
import InputField from '../../Form/InputField/InputField';
import TextArea from '../../Form/TextArea/TextArea';
import UserService from '../../../service/user.service';
import { toast } from 'sonner';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const emailResolver = yup.object().shape({
	message: yup.string().required('Please enter your message.'),
	email: yup.string().required('Please enter a valid email address.').email('Please enter a valid email address.'),
});

const ShareEmail = ({setStep, header=true, tourId}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue
	} = useForm({resolver:yupResolver(emailResolver), mode:'onBlur'});
	const submit = (data) => {
		let prom = UserService.sendEmail(data.email, data.message, tourId);
		toast.promise(prom, {
			loading: 'Sending invite...',
			success: (data) => {
				return 'Invite Sent!';
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	return (
		<div className={style.shareModal}>
			<div>
				{header && <h3 className={style.shareModal_title}>
					<div className={style.shareModal_title_back}>
						<Button size='sm' style="text" iconName='left_line_arrow' onClick={() => setStep('menu')}></Button>
					</div>Send Email</h3>}
				<p className={style.shareModal_description}>Email agents and potential buyers to give them direct access to your tour.</p>
			</div>
			<form onSubmit={handleSubmit(submit)} className={style.shareModal_form}>
				<InputField
					type="text"
					register={register}
					error={errors}
					placeholder="Email Address"
					name="email"
					width='100'
					setValue={setValue}
				/>
				<TextArea
					name="message"
					register={register}
					error={errors}
					setValue={setValue}
					placeholder="Hey! Learn everything about this property with TourMe. Listen to this immersive audio tour while visiting the house. Link"
					size='sm'
				></TextArea>
			</form>
			<Button style='text' iconName="send" onClick={handleSubmit(submit)}>Send</Button>
		</div>
	);
};

export default ShareEmail;
import style from './InputField.module.scss';
import { useEffect, useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';



const InputField = ({ setValue, width, prefix, name, register = () => {}, validation, error = {}, label, inputType = 'text', ...rest }) => {
	const [show, setShow] = useState(false);

	const normalizePhoneNumber = (value) => {
		if(!value) return value;
		const phoneNumber = parsePhoneNumberFromString(value, 'US');
		if (!phoneNumber) {
			return value;
		}
		return phoneNumber.formatInternational();
	};
	const formatInputValue = (value) => {
		if (inputType === 'phone') {
			return normalizePhoneNumber(value);
		} else if (inputType === 'price') {
			const numberValue = parseInt(value.replace(/[$,]*/g, ''));
			return isNaN(numberValue) ? value : numberValue.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits:0});
		} else if (inputType === 'number') {
			let val = value.toString().replace(/[^0-9]/g, '');
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		} else {
			return value;
		}
	};
	const handleType = () => {
		if (inputType === 'phone' || inputType === 'price') {
			return 'text';
		} else if (inputType === 'password') {
			return show ? 'text' : 'password';
		}
	};
	useEffect(() => {
		const input = document.querySelector(`input[name=${name}]`);
		input.type = handleType();
		input.value = formatInputValue(input.value);
	}, [show, name, inputType]);

	return (
		<div className={`${style.input_text} ${width && style[`input_text_w-${width}`]}`}>
			<label htmlFor={name}>{label}</label>
			<div className={`${style.input_text_input} ${error[name] ? style.input_text_input_error : style.input_text_input_valid}`}>
				{prefix ? <div className={style.input_text_input_prefix}>{prefix}</div> : ''}
				<input
					name={name}
					{...register(name, validation)}
					{...rest}
					onChange={(e) => setValue(name, formatInputValue(e.target.value))}
					onBlur={(e) => setValue(name, formatInputValue(e.target.value))}
					onInput={(e) => (e.target.value = formatInputValue(e.target.value))}
				/>
				{inputType === 'password' ? (
					<div className={style.input_text_icon}>
						<i
							className={show ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'}
							onClick={() => setShow(!show)}
						></i>
					</div>
				) : (
					''
				)}
			</div>
			{error[name] ? <p className={style.input_text_message_error}>{error[name].message}</p> : ''}
		</div>
	);
};

export default InputField;
import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import {Modal, Box, FormControl, TextField} from '@mui/material';
import LabelInput from '@mui/material/FormLabel';
import ActionButtons from '../../Settings/Components/ActionButtons';
import style from './PriceCard.module.scss';
import AuthService from '../../../service/auth.service';
import { useSelector } from 'react-redux';
import checkIcon from '../../../assets/icons/check.svg';
import checkCard from '../../../assets/icons/checkCard.svg';
import { setUser } from '../../../store/actions/userAction';

const PriceCard = ({ title, price, period, features, plan, active, handleClick, multi_agent, description='Kick-star your journey for free and discover the benefits of audio tours.' }) => {
	const user = useSelector((state) => state.user);
	const [users, setUsers] = useState(multi_agent? 5 : 1);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const handleClose = () => setOpen(false);
	const checkoutPlan = () => {
		console.log('checkoutPlan', plan, user.userData.id, user.userData.email, users, multi_agent);
		AuthService.checkout(plan, user.userData.id, user.userData.email, users, multi_agent)
			.then((response) => {
				if (response.status === 200) {
					dispatch(setUser(null));
					window.location.href = response.data.url;
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<>
			<div className={`${style.card} ${active && style.active}`} onClick={handleClick}>
				<img className={style.card__check} src={checkCard} alt="" />
				<h3 className={style.card__title}>
					{title} <span>Plan</span>
				</h3>
				<p className={style.card__price}>
				${price}
					{price !== 'Free' && <span className={style.card__period}>{period}</span>}
				</p>
				<p className={style.card__description}>
					{description}
				</p>
				<ul className={style.card__features}>
					{features?.map((feature, index) => (
						<li key={index}>
							<img src={checkIcon} alt="" />
							<p>
								{feature.content}
							</p>
						</li>
					))}
				</ul>
				<button className={style.card__button} onClick={() => multi_agent? setOpen(true) : checkoutPlan()}>
				Get Started
				</button>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
			>
				<Box className={style.quantity_modal}>
					<form onSubmit={ (e) => {
						e.preventDefault();
						checkoutPlan();
					}}>
						<h3>Activate License</h3>
						<FormControl fullWidth>
							<LabelInput>Agents:</LabelInput>
							<TextField
								error={errorMessage}
								size="small"
								name="invite"
								InputProps={{ inputProps: { min: 5 } }}
								helperText={errorMessage}
								type="number"
								onChange={(e) => {
									setUsers(e.target.value );
								}}
								hiddenLabel
								placeholder="5"
								sx={{ marginTop: '0.5rem' }}
								value={users}
							/>
						</FormControl>
						<ActionButtons
							handleSave={checkoutPlan}
							marginTop={20}
							handleCancel={handleClose}
							actionText="Get Started"
						></ActionButtons>
					</form>
				</Box>
			</Modal>
		</>

	);
};

export default PriceCard;

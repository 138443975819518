import style from './TextArea.module.scss';
const TextArea = ({ name, register, validation, error, label, size='md', ...rest }) => {
	return (
		<div className={style.textarea}>
			<label htmlFor={name}>{label}</label>
			<div className={style.textarea_input}>
				<textarea
					name={name}
					{...register(name, validation)}
					{...rest}
					className={` ${style.textarea_input_textarea} ${style[`textarea_input_${size}`]} ${error[name] ? style.textarea_input_error : style.textarea_input_valid}`}
				/>
			</div>
			{error[name] ? <p className={style.textarea_message_error}>{error[name].message}</p> : ''}
		</div>
	);
};

export default TextArea;

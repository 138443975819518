import React, { useEffect, useState } from 'react';
import style from './EditSection.module.scss';
import InputField from '../../../Form/InputField/InputField';
import { useCreateForm } from '../../../../context/CreateTourContext';
import { useForm } from 'react-hook-form';
import FormWrapperLayout from '../../FormWrapperLayout/FormWrapperLayout';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import InputScript from '../../../InputScript/InputScript';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../../../helpers/useSchema';


const EditSection = () => {
	const { formData, sections, setSections } = useCreateForm();
	const [duration, setDuration] = useState(0);
	const { handleSubmit, formState, setValue, register } = useForm({
		resolver: yupResolver(schema.sections),
		mode: 'onBlur',
	});
	const { errors } = formState;
	const navigate = useNavigate();
	const selectedSectionToEdit = window?.history?.state?.usr;



	const onSubmit = (data) => {
		if (selectedSectionToEdit) {
			let edited = {
				title: data.title,
				script: data.script,
				duration: duration,
				id: selectedSectionToEdit[0]?.id,
			};
			sections.pop();
			setSections([...sections, edited]);
			navigate('/create/sections');
		} else {
			setSections((prevState) => [...prevState, {...data, duration, id:sections.length}]);
			navigate('/create/sections');
		}
	};

	useEffect(() => {
		if (selectedSectionToEdit) {
			let tempSections = sections;
			let checkSection = tempSections.find((section) => section.id === selectedSectionToEdit[0].id);
			console.log({checkSection});
			setValue('title', checkSection.title);
			setValue('script', checkSection.script);
		}
	}, [selectedSectionToEdit, sections, setValue]);
	return (
		<FormWrapperLayout formTitle={selectedSectionToEdit ? 'Edit Section' : 'New Section'} back={() => navigate('/create/sections')} next={handleSubmit(onSubmit)} button="Save">
			<form onSubmit={handleSubmit(onSubmit)} className={style.section_form}>
				<InputField
					name="title"
					placeholder="Section Title"
					register = {register}
					error= {errors}
					setValue={setValue}
				/>
				<InputScript duration={duration} setDuration={setDuration} register={register} name="script" placeholder="Section script..." error={errors}></InputScript>
			</form>
		</FormWrapperLayout>
	);
};

export default EditSection;

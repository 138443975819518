import style from './IconOptions.module.scss';
import { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, Typography } from '@mui/material';

const IconOptions = ({ options, ...props }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<div className={style.iconButton} onClick={handleClick}>
				<i className={`fa-solid fa-ellipsis-vertical ${style.iconButton_icon}`} style={{ ...props }}></i>
			</div>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{options.map((option, index) => (
					<MenuItem
						key={index}
						onClick={() => {
							option.handle();
							handleClose();
						}}
					>
						<ListItemIcon>
							<i className={option.icon} style={{ color: '#000' }}></i>
						</ListItemIcon>
						<Typography variant="inherit" noWrap color="#000">
							{option.label}
						</Typography>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default IconOptions;

import { RWebShare } from 'react-web-share';
import IconButton from '@mui/material/IconButton';
import UserService from '../../service/user.service';
import { TourDataContext } from '../../context/TourDataContext';
import { useContext } from 'react';
const ShareButton = () => {
	const { clientTour } = useContext(TourDataContext);
	const handleShare = () => {
		UserService.countShare(clientTour.id);
	};
	return (
		<RWebShare
			data={{
				text: 'Open this link and meet your future home with me',
				url: window.location.href,
				title: 'I invite you to see this house!',
			}}
			onClick={() => handleShare()}
		>
			<IconButton>
				<i className="fa-solid fa-arrow-up-from-bracket"></i>
			</IconButton>
		</RWebShare>
	);
};

export default ShareButton;

import React from 'react';
import style from './Select.module.scss';
const Select = ({name, register, error, children, width,...rest }) => {
	return (
		<div className={`${style.select} ${width && style[`select_w-${width}`]}`}>
			<select name={name} {...register(name)} {...rest} className={`${style.select_field} ${error[name] ? style.select_field_error : style.select_field_valid}`}>
				{children}
			</select>
			{error[name] ? <p className={style.select_message_error}>{error[name].message}</p> : ''}
		</div>
	);
};

export default Select;

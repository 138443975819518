import React, { useRef, useContext } from 'react';
import logo from '../../assets/flayer/logo.svg';
import phone_camera from '../../assets/flayer/phone-camera.svg';
import phone_2 from '../../assets/flayer/phone-2.svg';
import phone_3 from '../../assets/flayer/phone-3.svg';
import style from './Flayer.module.scss';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { TourDataContext } from '../../context/TourDataContext';

const Flayer = ({flyerRef}) => {
	const user = useSelector((state) => state.user);
	const { singleTour } = useContext(TourDataContext);

	return (
		<div className={style.flayer} ref={flyerRef}>
			<div className={style.flayer_header}>
				<img src={logo} className={style.flayer_header_logo} />
			</div>
			<div className={style.flayer_dot}></div>
			<div className={style.flayer_title_section}>
				<h1 className={style.flayer_title_section_title}>Welcome in!</h1>
				<h3 className={style.flayer_title_section_subtitle}>scan the code to start tour</h3>
			</div>
			<div className={style.flayer_code_section}>
				<div className={style.flayer_code_section_agent_info}>
					<h5 className={style.flayer_code_section_agent_info_label}>Got questions?</h5>
					<div className={style.flayer_code_section_agent_info_line}></div>
					<h4 className={style.flayer_code_section_agent_info_agent_name}>{user?.userData?.name}</h4>
					<p className={style.flayer_code_section_agent_info_agent_contact}>{user?.userData?.phone}</p>
					<p className={style.flayer_code_section_agent_info_agent_contact}>{user?.userData?.email}</p>
				</div>
				<div className={style.flayer_code_section_qr_code}>
					<QRCode
						value={`https://portal.tourmehomes.com/tour/${singleTour?.randomeid}`}
						size={175}
						bgColor="#000000"
						fgColor="#fff"
						level="L"
					/>
				</div>
			</div>
			<div className={style.flayer_instructions_section}>
				<div className={style.flayer_instructions_section_instruction}>
					<p className={style.flayer_instructions_section_instruction_explain}>
						Open the camera
						<br />
						app on your phone
					</p>
					<span className={style.flayer_instructions_section_instruction_step}>1</span>
					<img src={phone_camera} className={style.flayer_instructions_section_instruction_step_image} />
				</div>
				<div className={style.flayer_instructions_section_instruction}>
					<p className={style.flayer_instructions_section_instruction_explain}>
						Point to the
						<br />
						QR code
					</p>
					<span className={style.flayer_instructions_section_instruction_step}>2</span>
					<img src={phone_2} className={style.flayer_instructions_section_instruction_step_image} />
				</div>
				<div className={style.flayer_instructions_section_instruction}>
					<p className={style.flayer_instructions_section_instruction_explain}>
						Enjoy custom
						<br />
						guided home tour
					</p>
					<span className={style.flayer_instructions_section_instruction_step}>3</span>
					<img src={phone_3} className={style.flayer_instructions_section_instruction_step_image} />
				</div>
			</div>
		</div>
	);
};

export default Flayer;

import React, { useContext } from 'react';
import info from '../../assets/images/info.svg';
import style from './WarningBox.module.scss';
import { TourDataContext } from '../../context/TourDataContext';

const WarningBox = () => {
	const { warning, removeWarning } = useContext(TourDataContext);
	return (
		<div className={style.warning__box}>
			<img src={info} alt="info" />
			<p>
				{warning.message}
				<span onClick={() => warning.callback()}>{warning.button}</span>
			</p>
			<i
				className="fa-solid fa-xmark"
				onClick={() => {
					removeWarning();
				}}
			></i>
		</div>
	);
};

export default WarningBox;

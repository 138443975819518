import React, { useEffect, useContext, useState } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import { setUser } from '../../store/actions/userAction';
import { AudioContext } from '../../context/AudioContext';
import { TourDataContext } from '../../context/TourDataContext';
import UserService from '../../service/user.service';
import MessageBox from '../../components/MessageBox/MessageBox';
import AuthService from '../../service/auth.service';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './MainLayour.scoped.scss';

const MainLayout = ({ children }) => {
	const {setTourCards, setLoadingCards} = useContext(TourDataContext);
	const [openMessage, setOpenMessage] = useState(false);
	const { cleanAudio } = useContext(AudioContext);
	const user = useSelector((state) => state.user);
	const [settings, setSettings] = useState(true);
	const navigate = useNavigate();
	const pathname = useLocation().pathname;
	const hideNav = () => {
		if (
			pathname.toLowerCase().includes('/signin') ||
			pathname.toLowerCase().includes('/login') ||
			pathname.toLowerCase().includes('/reset') ||
			pathname.toLowerCase().includes('/verify') ||
			pathname.toLowerCase().includes('/tour/') ||
			pathname.toLowerCase().includes('/welcome/')
		) {
			return true;
		}
		return false;
	};
	const hideFooter = () => {
		if (
			pathname.toLowerCase().includes('/signin') ||
			pathname.toLowerCase().includes('/login') ||
			pathname.toLowerCase().includes('/reset') ||
			pathname.toLowerCase().includes('/verify') ||
			pathname.toLowerCase().includes('/tour/') ||
			pathname.toLowerCase().includes('/inactive')
		) {
			return true;
		}
		return false;
	};
	const authRequired = () => {
		if (
			pathname.toLowerCase().includes('/settings/') ||
			pathname.toLowerCase().includes('/dashboard') ||
			pathname.toLowerCase().includes('/tourhome/') ||
			pathname.toLowerCase().includes('/checkout') ||
			pathname.toLowerCase().includes('/create') ||
			pathname.toLowerCase().includes('/support') ||
			pathname.toLowerCase().includes('/resources/tourhome') ||
			pathname.toLowerCase().includes('/inactive')
		) {
			return true;
		}
		return false;
	};
	const showSettings = () => {
		if (pathname.toLowerCase().includes('checkout')) {
			return setSettings(false);
		}
		setSettings(true);
	};
	const dispatch = useDispatch();
	useEffect(() => {
		showSettings();
		setLoadingCards(true);
		setTourCards([]);
		if (authRequired()) {
			if (!user) {
				AuthService.getCurrentUser()
					.then((res) => {
						dispatch(setUser(res.data.data));
						if (!res.data.data.auth && !pathname.toLowerCase().includes('/checkout')) {
							return navigate('/checkout');
						}
						else if (res.data.data.status !== 'active') {
							setOpenMessage(true);
						}
					})
					.catch((err) => {
						navigate('/login');
					});
			} else if (!user.auth && !pathname.toLowerCase().includes('/checkout')) {
				navigate('/checkout');
			} else if (user.auth && pathname.toLowerCase().includes('/checkout')) {
				navigate('/dashboard');
			} else if (user.status !== 'active' && user.auth) {
				navigate('/inactive');
			} else if (user.status === 'active' && pathname.toLowerCase().includes('/inactive')) {
				navigate('/dashboard');
			}
		}
		if (!pathname.toLowerCase().includes('tourhome')) cleanAudio();
		window.scrollTo(0, 1);
	}, [pathname, user]);

	return (
		<div className={'mainLayout '}>
			{!hideNav() && <NavBar settings={settings}/>}
			<div className={`page-content ${hideNav() && 'hide-header'}`}>
				<div className="wrapper">
					{children}
				</div>
				{!hideFooter() && <Footer />}
			</div>
		</div>
	);
};

export default MainLayout;

import style from './Settings.module.scss';
import { useState, useEffect } from 'react';
import SubscriptionAdmin from '../../components/Settings/Subscription/SubscriptionAdmin';
import CustomerSupport from '../../components/Settings/CustomerSupport/CustomerSupport';
import Button from '../../components/Button/Button';
import PrivacySettings from '../../components/Settings/PrivacySettings/PrivacySettings';
import EditProfile from '../../components/Settings/EditProfile/EditProfile';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import VoiceSettings from '../../components/Settings/Voice/VoiceSettings';
import SettingsMenu from '../../components/Settings/SettingsMenu/SettingsMenu';

const Settings = () => {
	const navigate = useNavigate();
	const [tab, setTab] = useState({ name: 'menu', label: 'Settings', back: () => navigate('/dashboard') });
	const user = useSelector((state) => state.user);
	const { setting } = useParams();

	const ActiveTab = {
		account: <PrivacySettings/>,
		profile: <EditProfile/>,
		voice: <VoiceSettings/>,
		subscription: <SubscriptionAdmin user={user}/>,
		support: <CustomerSupport/>
	};
	// const verifyEmail = () => {
	// 	let prom = UserService.createEmailVerification(user.userData.email, user.userData.name);
	// 	toast.promise(prom, {
	// 		loading: 'Sending email...',
	// 		success: (data) => {
	// 			return 'Email sent successfully';
	// 		},
	// 		error: (err) => {
	// 			console.error(err);
	// 			return err.response.data.message;
	// 		},
	// 	});
	// };
	
	return (
		<div className={style.settings}>
			<h3 className={style.settings_header}>
				<div className={style.settings_header_back}>
					<Button size='sm' style="text" iconName='left_line_arrow' onClick={tab.back}></Button>
				</div>{tab.label}
			</h3>
			<div className={`${style.settings_menu} ${tab.name === 'menu' && style.settings_menu_open}`}>
				<SettingsMenu user={user} selectedTab={tab} setTab={setTab} setting={setting}></SettingsMenu>
			</div>
			<div className={style.settings_tabs}>
				<div className={style.settings_tabs_item}>
					{
						ActiveTab[tab.name]
					}
				</div>
			</div>
		</div>
	);
};

export default Settings;

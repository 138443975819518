import style from './SectionList.module.scss';
import { useState, useContext, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SectionListItem from '../SectionListItem/SectionListItem';
import IconText from '../Form/IconText/IconText';
import Skeleton from '@mui/material/Skeleton';
import UserService from '../../service/user.service';
import MessageBox from '../MessageBox/MessageBox';
import { useSelector } from 'react-redux';
import SectionModal from '../SectionModal/SectionModal';
import { AudioContext } from '../../context/AudioContext';
import { TourDataContext } from '../../context/TourDataContext';
import headphone from '../../assets/icons/headset.svg';
import { convertTime } from '../../helpers/audioFormatter';
const SectionList = () => {

	const user = useSelector((state) => state.user);
	const { audioSections, isLoading, handleAddSection,
		handleEditSection,
		handleDelete,
		handleOrder } = useContext(AudioContext);
	const { sampleTour } = useContext(TourDataContext);
	const [totalDuration, setTotalDuration] = useState(0);

	const [open, setOpen] = useState(false);
	const [openMessage, setOpenMessage] = useState(false);

	// a little function to help us with reordering the result
	const reorder = (list, startIndex, endIndex) => {
		const [removed] = list.splice(startIndex, 1); // 1
		list.splice(endIndex, 0, removed); // 2
		list.forEach((obj, i) => {
			obj.order = i + 1;
		});
		return list;
	};
	const getsectionstyle = (isDragging, draggableStyle) => ({
		background: isDragging ? '#DEE5EA' : '#fff',
		...draggableStyle,
	});
	const openCreate = () => {
		if (user.plan.permissions.sections <= audioSections.length && user.plan.permissions.sections !== -1) return setOpenMessage(true);
		setOpen(true);
	};
	function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		const sections_ = reorder(audioSections, result.source.index, result.destination.index);
		handleOrder(sections_);
	}
	useEffect(() => {
		setTotalDuration(0);
		audioSections.forEach(async (audio) => {
			if(audio.Audios[0])
			{
				const audioElement = new Audio(audio.Audios[0]?.src);
				audioElement.addEventListener('loadedmetadata', function(){
					let duration = Math.trunc(audioElement.duration);
					setTotalDuration((total) => total + duration);
				},false);
			}
		});
	}, [audioSections]);
	return (
		<div className={style.sectionList}>
			
			{isLoading ? (
				<div className={style.skeleton}>
					<Skeleton animation="wave" variant="rounded" width="100%" height="70px" />
					<Skeleton animation="wave" variant="rounded" width="100%" height="70px" />
					<Skeleton animation="wave" variant="rounded" width="100%" height="70px" />
					<Skeleton animation="wave" variant="rounded" width="100%" height="70px" />
					<Skeleton animation="wave" variant="rounded" width="100%" height="70px" />
				</div>
			) : (
				<>
					<div className={style.sectionList_header}>
						<img src={headphone} alt="" />
						<p className={style.sectionList_header_title}>Total Tour Length: <span>{convertTime(totalDuration)}</span></p>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className={style.sectionList_list}
								>
									{audioSections.map((item, index) => {
										return (
											<Draggable
												key={`section-${item.id}`}
												draggableId={`section-${item.id}`}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														className={style.sectionList_list_item}
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={getsectionstyle(
															snapshot.isDragging,
															provided.draggableProps.style,
														)}
													>
														<SectionListItem
															speech={item.speech}
															handleEdit={handleEditSection}
															handleDelete={handleDelete}
															order={index + 1}
															title={item.title}
															id={item.id}
															description={item.description}
															dragHandle={provided.dragHandleProps}
															audio={item.Audios[0]?.src}
														//sample={sampleTour}
														/>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className={style.sectionList_add_container}>
						<IconText
							callback={openCreate}
							icon="fa-regular fa-square-plus"
							text="Add Section"
							icon_color="#E59905"
							color="#0B1C25"
							icon_size="1.3rem"
							font_size="1rem"
							decoration="underline"
							textStyle={{ 'marginLeft': '1rem' }}
						// disabled={sampleTour}
						></IconText>
					</div>
				</>
			)}
			<SectionModal
				order={audioSections.length + 1}
				open={open}
				handleClose={() => setOpen(false)}
				onSubmit={handleAddSection}
			/>
			<MessageBox
				title="Sections limit reached!"
				message="To add more sections please upgrade your plan."
				confirmButton="Upgrade"
				confirm={() => {
					UserService.getClientPortal()
						.then((res) => {
							window.location.href = res.url;
						})
						.catch((err) => {
							console.error(err);
						});
				}}
				cancel={() => setOpenMessage(false)}
				close={() => setOpenMessage(false)}
				open={openMessage}
			></MessageBox>
		</div>
	);
};

export default SectionList;

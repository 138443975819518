import style from './Footer.module.scss';
import Flex from '../Flex/Flex';
import IconButton from '@mui/material/IconButton';
const Footer = () => {
	return (
		<div className={style.footer}>
			<p className={style.footer_terms}>
				© 2023 TourMe{' '}
				<a
					href="https://www.tourmehomes.com/policies/terms-of-service"
					target="_blank"
					className={`${style.footer_button} ${style.footer_terms_item}`}
					rel="noreferrer"
				>
					· Terms
				</a>{' '}
				<a
					href="https://www.tourmehomes.com/policies/privacy-policy"
					target="_blank"
					className={`${style.footer_button} ${style.footer_terms_item}`}
					rel="noreferrer"
				>
					· Privacy
				</a>
			</p>
			<Flex direction="row" align="center" justify="space-between">
				<a href="https://www.youtube.com/@TourMeHomes" target="_blank" rel="noreferrer">
					<IconButton className={style.footer_button}>
						<i className="fa-brands fa-youtube"></i>
					</IconButton>
				</a>
				<a href="https://instagram.com/tourmehomes?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
					<IconButton className={style.footer_button}>
						<i className="fa-brands fa-instagram"></i>
					</IconButton>
				</a>
				<a href="https://m.facebook.com/tourmehomes" target="_blank" rel="noreferrer">
					<IconButton className={style.footer_button}>
						<i className="fa-brands fa-facebook"></i>
					</IconButton>
				</a>
				<a href="https://www.tiktok.com/@tourmehomes" target="_blank" rel="noreferrer">
					<IconButton className={style.footer_button}>
						<i className="fa-brands fa-tiktok"></i>
					</IconButton>
				</a>
			</Flex>
		</div>
	);
};

export default Footer;

import style from './RateModal.module.scss';
import ModalBox from '../../ModalBox/ModalBox';
import { useState } from 'react';
import Button from '../../Button/Button';
import rateModalQuestionMark from '../../../assets/images/rateModal.png';

const RateModal = ({ onClose, onRate, open }) => {
	const ratingItems = [
		{
			icon: '😔',
			label: 'Very Dissatisfied',
		},
		{
			icon: '🙁',
			label: 'Dissatisfied',
		},
		{
			icon: '😐',
			label: 'Neutral',
		},
		{
			icon: '🙂',
			label: 'Satisfied',
		},
		{
			icon: '😃',
			label: 'Very Satisfied',
		},
	];

	const [value, setValue] = useState(3);

	return (
		<ModalBox isOpen={open} close={onClose} size="sx">
			<div className={style.rateModal}>
				<img src={rateModalQuestionMark} alt="question mark icon" />
				<h2 className={style.rateModal__title}>Rate Your Experience</h2>
				<p className={style.rateModal__description}>Please rate your experience with our audio tour!</p>
				<div className={style.rateModal__rating}>
					{ratingItems.map((item, index) => {
						return (
							<button
								className={`${value === index + 1 ? style.rateModal__rating_button_active : ''} ${
									style.rateModal__rating_button
								}`}
								onClick={() => setValue(index + 1)}
								key={`button-${index}`}
							>
								{item.icon}
							</button>
						);
					})}
				</div>
				<Button
					onClick={() => {
						onRate(value);
						onClose();
					}}
					style="filled"
					color="secondary"
				>Submit</Button>
			</div>
		</ModalBox>
	);
};

export default RateModal;

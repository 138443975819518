import React, { useEffect, useState } from 'react';
import UserService from '../../../service/user.service';
import MessageBox from '../../MessageBox/MessageBox';
import Button from '../../Button/Button';
import copy from '../../../assets/icons/copy.svg';
import { toast } from 'sonner';
import style from './Subscription.module.scss';
import { Skeleton } from '@mui/material';
import OptionsButton from '../../OptionsButton/OptionsButton';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';

const SubscriptionAdmin = ({user}) => {
	const navigate = useNavigate();
	const [invite, setInvite] = useState(false);
	const [activeAgent, setActiveAgent] = useState(-1);
	const [agents, setAgents] = useState([]);
	const [subscription, setSubscription] = useState(null); // [plan, start, end, renew
	const [message, setMessage] = useState({open:false});

	const openClientPortal = () => {
		UserService.getClientPortal()
			.then((res) => {
				window.location.href = res.url;
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const formatLimit = (limit) => limit === -1 ? 'Unlimited' : limit;
	const createInvite = () => {
		if(user?.plan.permissions.agents <= agents.length && user?.plan.permissions.agents !== -1)
			return setInvite('licenses limit reached');
		
		let prom = UserService.createInvite();
		toast.promise(prom, {
			loading: 'Creating invite...',
			success: (data) => {
				setInvite(data.data.invitation.code);
				return `Invite created successfully ${data.data.invitation.code}`;
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message;
			},
		});
	};
	const deleteAgent = (id) => {
		const prom = UserService.deleteAgent(id);
		toast.promise(prom, {
			loading: 'Deleting agent...',
			success: (data) => {
				getAgents();
				setMessage({open:false});
				return data.message;
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message ?? 'Something went wrong';
			},
		});
	};	
	const copyInvite = () => {
		navigator.clipboard.writeText(invite);
		toast.success('Invite copied to clipboard');
	};
	const getAgents = () => {
		UserService.getAgents()
			.then((res) => {
				setAgents(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const getSubsData = () => {
		UserService.getSubsData()
			.then((res) => {
				setSubscription(res.data.data);
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const leaveSubscription = () => {
		let prom = UserService.removeSubscription();
		toast.promise(prom, {
			loading: 'Removing subscription',
			success: (data) => {
				navigate('/dashboard', { replace: true });
				return 'You leave the subscription';
			},
			error: (err) => {
				console.error(err);
				return err.response.data.message ?? 'Something went wrong';
			},
		});
	};
	useEffect(() => {
		getAgents();
		getSubsData();
		if(user?.plan.multi_agent)
			createInvite();
	}, []);
	return (
		<div className={`${style.subscription} ${user?.plan.multi_agent ? style.subscription_grid : style.subscription_column}`}>
			{
				!subscription ? 
					<Skeleton variant="rectangular" width="100%" height="162px" animation="wave" /> :
					<div className={style.subscription_box}>
						<div className={style.subscription_box_content}>
							<div className="">
								<h4 className={style.subscription_box_content_title}>{user?.plan.name} Plan</h4>
								<p>{subscription?.period?.start} - {subscription?.period?.end}</p>
								<p>Renews on {subscription?.period?.renewal}</p>
							</div>
							<div className="">
								<h4 className={style.subscription_box_content_price}>{subscription?.status === 'trialing' ? 'Free Trial': `$${subscription?.price*subscription?.units}`}</h4>
								{subscription?.status !== 'trialing' && <p>Per {subscription.billing_frequency}</p>}</div>
						</div>
					</div>
			}
			<div className={style.subscription_plan}>
				<h3 className={style.subscription_subtitle}>Plan Details</h3>
				{ user?.plan.multi_agent &&
					<div className={style.subscription_plan_item}>
						<p className={style.subscription_plan_item_label}>Licenses</p><p className={style.subscription_plan_item_value}>{formatLimit(user.plan.permissions.agents)} Licenses <span>({agents.length} used)</span></p>
					</div>
				}
				<div className={style.subscription_plan_item}>
					<p className={style.subscription_plan_item_label}>Total Tours</p><p className={style.subscription_plan_item_value}>{formatLimit(user?.plan.permissions.tours)}</p>
				</div>
				<div className={style.subscription_plan_item}>
					<p className={style.subscription_plan_item_label}>Active Tours</p><p className={style.subscription_plan_item_value}>{formatLimit(user?.plan.permissions.actives)}</p>
				</div>
				<div className={style.subscription_plan_item}>
					<p className={style.subscription_plan_item_label}>Sections per Tour</p><p className={style.subscription_plan_item_value}>{formatLimit(user?.plan.permissions.sections)}</p>
				</div>
				
			</div>
			{
				user?.plan.multi_agent && 
				<div className={style.subscription_multiagent}>
					<h3 className={style.subscription_multiagent_title}>User Management</h3>
					<div className={style.subscription_users}>
						{user?.userData.role === 'Admin' &&
						<div className={style.subscription_users_invite_box}>
							<p>Invite Code: <span>{invite}</span></p>
							<img src={copy} alt="" onClick={copyInvite}/>
						</div>}
						{
							agents.map((item, index) => {
								return (
									<div key={index} className={style.subscription_users_agent}>
										<h4>{item.name}</h4>
										{user?.userData.role === 'Admin' && <OptionsButton options={
											[
												// {
												// 	label: 'Make Admin',
												// 	icon: <Setting/>,
												// 	action: () => {}
												// },
												{
													label: 'Delete',
													icon: <Trash/>,
													action: () => {
														setMessage({
															title: 'Delete Agent',
															message: 'Are you sure you want to delete this agent?',
															button: 'Delete',
															action: () => deleteAgent(item.id),
															open: true
														});
													}
												}
											]}/>}
									</div>
								);
							})
						}
					</div>
				</div>
			}
			<div className={style.subscription_action}>
				{
					user?.userData.role === 'Admin'?
						<Button iconName='card' style='text' onClick={openClientPortal}>Manage Billing</Button>
						:
						<Button iconName='card' style='text' onClick={() => setMessage({
							title:'Leave Subscription',
							message: 'Are you sure you want to leave the subscription?',
							button: 'Continue',
							action: leaveSubscription,
							open:true
						})}>Leave Subscription</Button>
				}
			</div>
			<MessageBox
				title={message.title}
				message={message.message}
				confirmButton={message.button}
				confirm={message.action}
				cancel={() => setMessage({open:false})}
				close={() => setMessage({open:false})}
				open={message.open}
			></MessageBox>
		</div>
	);
};

export default SubscriptionAdmin;